import PhoneInput from 'react-phone-number-input/input'

import './PhoneNumber.css'

interface PhoneNumberProps {
  style?: any
  value: string
  onChange: (newValue: any) => void
}

function PhoneNumber({ style, value = '', onChange }: PhoneNumberProps) {
  return (
    <PhoneInput
      aria-label="Mobile number"
      className="phoneNumber__input"
      style={style}
      value={value}
      onChange={onChange}
    />
  )
}

export default PhoneNumber
