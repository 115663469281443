import React, { useEffect } from 'react'

import { getComingResourceOwnerUuidFromAdminUI } from 'util/url'

interface AmplifySetupWrapperProps {
  children: React.ReactNode
}

function CheckForAdminUiJump({ children }: AmplifySetupWrapperProps) {
  useEffect(() => {
    const resourceOwnerUuidFromAdminUi = getComingResourceOwnerUuidFromAdminUI(window.location.hash)
    const currentResourceOwnerUuid = localStorage.getItem('resourceOwnerUuid')

    const anotherResourceOwnerSelected =
      resourceOwnerUuidFromAdminUi && currentResourceOwnerUuid !== JSON.stringify(resourceOwnerUuidFromAdminUi)
    if (currentResourceOwnerUuid === 'null' || anotherResourceOwnerSelected) {
      localStorage.setItem('resourceOwnerUuid', JSON.stringify(resourceOwnerUuidFromAdminUi))
    }
  }, [])

  return <>{children}</>
}

export default CheckForAdminUiJump
