import axios from 'axios'

export function getRevenueCalculatedPeriod(resourceOwnerId: number) {
  return axios.get(`/resourceOwners/${resourceOwnerId}/revenue/period`)
}

export function getRevenueTimeSeries(resourceOwnerId: number, serviceId: number, startTime: string, endTime: string) {
  const query = `serviceId=${serviceId}&startTime=${startTime}&endTime=${endTime}`
  const url = `/resourceOwners/${resourceOwnerId}/revenue/hourly?${query}`

  return axios.get(url)
}
