import { Form, Space } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import TimeStartPicker from './TimeStartPicker'
import TimeEndPicker from './TimeEndPicker'

import styles from './TimeOfDaySelect.module.css'

function TimeOfDaySelect({
  isAllDayChecked,
  isInclusive = false,
  ptuRange,
  timeEndAt,
  timeStartAt,
  onTimeEndAtChange,
  onTimeStartAtChange,
}) {
  const intl = useIntl()

  return (
    <Form.Item label={intl.formatMessage({ id: 'app.resource-owner.restrictions.time-of-day' })}>
      <Space>
        <div className={styles.container}>
          <label>
            <FormattedMessage id="app.resource-owner.from" />
            {': '}
          </label>
          <TimeStartPicker
            disabled={isAllDayChecked}
            ptuRange={ptuRange}
            value={timeStartAt}
            onChange={onTimeStartAtChange}
          />
        </div>
        <div className={styles.container}>
          <label>
            <FormattedMessage id="app.resource-owner.to" />
            {': '}
          </label>
          <TimeEndPicker
            disabled={isAllDayChecked}
            isInclusive={isInclusive}
            ptuRange={ptuRange}
            value={timeEndAt}
            onChange={onTimeEndAtChange}
          />
        </div>
      </Space>
    </Form.Item>
  )
}

export default TimeOfDaySelect
