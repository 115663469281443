import { Theme } from '../theme'
import WhiteLogoImage from 'assets/hellemans/logo-white.png'
import ColoredLogoImage from 'assets/hellemans/logo-colored.png'
import BackgroundImage from 'assets/hellemans/hellamans-background-green.png'
import Favicon from 'assets/hellemans/favicon.png'
import DashboardLogo from 'assets/hellemans/logo-flexpower-color.png'

const FONT_FAMILY = "'Inter', sans-serif"
const FONT_FAMILY_URL = 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap'

const HellemansConsultingTheme: Theme = {
  name: 'Hellemans',
  colorPalette: {
    primaryColor: '#41A62A',
    secondaryColor: '#00529E',
    successColor: '#38A169',
    warningColor: '#DD6B20',
    errorColor: '#E53E3E',
    backgroundColor: '#FFFFFF',
  },
  fontFamily: {
    primaryFontFamilyName: FONT_FAMILY,
    primaryFontFamilyUrl: FONT_FAMILY_URL,
    accentFontFamilyName: FONT_FAMILY,
    accentFontFamilyUrl: FONT_FAMILY_URL,
  },
  logo: {
    logoAltText: 'Hellemans Logo',
    whiteLogoPath: WhiteLogoImage,
    coloredLogoPath: ColoredLogoImage,
    faviconPath: Favicon,
  },
  uiConfig: {
    hasWhitelabeledFooter: true,
  },
  dashboardConfig: {
    productName: 'FlexPower',
    dashboardLogoPath: DashboardLogo,
    textColor: '#41A62A',
    background: `url('${BackgroundImage}') 0% 100% / 50% no-repeat`,
    backgroundOverlayColor: '#ffffff',
  },
}

export { HellemansConsultingTheme }
