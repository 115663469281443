import { Button, Col, Divider, Modal, Popconfirm, Row } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import FormattedDateFromUTCToTimezone from 'components/FormattedDateFromUTCToTimezone'
import ModalRelayWires from 'components/ProductsServices/ModalRelayWires'
import TimeOfDayDisplay from 'components/TimeOfDayDisplay'
import FormattedStatus from 'components/util/FormattedRestrictionStatus'

import styles from './ModalDetails.module.css'

function ModalDetails({ restriction, sections, timeZone, onCloseClick, onEditClick, onDeleteClick }) {
  const { activeAt, endAt, relayWires, startAt, status, timeEndAt, timeStartAt } = restriction
  const canBeEdited = status !== 'expired'
  const canBeDeleted = status === 'waiting'

  const restrictionRelayWires = useMemo(
    () =>
      orderBy(
        relayWires.map(({ relayWire }) => relayWire),
        'name',
        'asc',
      ),
    [relayWires],
  )

  return (
    <Modal
      visible
      closable={false}
      width={900}
      footer={[
        canBeDeleted ? (
          <div key="delete-button" className={styles.deleteContainer}>
            <Popconfirm
              title={
                <FormattedMessage id="app.resource-owner.products-services.modal.delete-restriction-confirmation" />
              }
              okText={<FormattedMessage id="app.resource-owner.products-services.modal.delete-restriction-yes" />}
              cancelText={<FormattedMessage id="app.cancel" />}
              okButtonProps={{ danger: true }}
              onConfirm={onDeleteClick}
            >
              <Button danger icon={<DeleteOutlined />}>
                <FormattedMessage id="app.delete" />
              </Button>
            </Popconfirm>
          </div>
        ) : null,
        canBeEdited ? (
          <Button key="edit-button" type="primary" onClick={onEditClick}>
            <FormattedMessage id="app.edit" />
          </Button>
        ) : null,
        <Button key="close-button" onClick={onCloseClick}>
          <FormattedMessage id="app.close" />
        </Button>,
      ]}
    >
      <Row justify="space-between">
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.restrictions.start-date" />
          </strong>
          <br />
          <FormattedDateFromUTCToTimezone date={startAt} timezone={timeZone} />
        </Col>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.restrictions.end-date" />
          </strong>
          <br />
          <FormattedDateFromUTCToTimezone date={endAt} timezone={timeZone} />
        </Col>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.restrictions.time-of-day" />
          </strong>
          <br />
          <TimeOfDayDisplay endAt={timeEndAt} startAt={timeStartAt} />
        </Col>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.status" />
          </strong>
          <br />
          <FormattedStatus status={status} activeAt={activeAt} />
        </Col>
      </Row>

      {restriction.relayWires && restriction.relayWires.length > 0 && (
        <Divider orientation="left">
          <FormattedMessage id="app.resource-owner.modal.affected-resources" />
        </Divider>
      )}

      {restrictionRelayWires && restrictionRelayWires.length > 0 && (
        <ModalRelayWires isEdit={false} sections={sections} wires={restrictionRelayWires} />
      )}
    </Modal>
  )
}

export default ModalDetails
