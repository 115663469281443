import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { getEnvironment } from 'api/general'
export interface Environment {
  name: string | null
  countryCode: string | null
  theme: string | null
  cognitoUserPoolId: string | null
  cognitoWebClientId: string | null
  getApiBaseUrl: () => string
}

const initialState = {
  countryCode: null,
  name: null,
  theme: null,
  cognitoUserPoolId: null,
  cognitoWebClientId: null,
  getApiBaseUrl: () => '',
}

const EnvironmentContext = createContext<Environment>(initialState)

export function EnvironmentProvider(props: { children: ReactNode }) {
  const [countryCode, setCountryCode] = useState(null)
  const [name, setName] = useState(null)
  const [cognitoUserPoolId, setCognitoUserPoolId] = useState(null)
  const [cognitoWebClientId, setCognitoWebClientId] = useState(null)
  const [theme, setTheme] = useState(null)

  useEffect(() => {
    async function loadEnvironment() {
      try {
        const { data } = await getEnvironment()
        setCountryCode(data.countryCode ? data.countryCode.toLowerCase() : null)
        setName(data.name ? data.name.toLowerCase() : null)
        setCognitoUserPoolId(data.cognitoUserPoolId)
        setCognitoWebClientId(data.cognitoWebClientId)
        setTheme(data.theme ? data.theme.toLowerCase() : null)
      } catch (error: any) {
        console.log('Error loading environment information', error)
      }
    }

    loadEnvironment()
  }, [])

  function getApiBaseUrl() {
    return name === 'production' ? 'https://api.sympower.net' : `https://api.${name}.sympower.net`
  }

  return (
    <EnvironmentContext.Provider
      value={{ countryCode, name, cognitoUserPoolId, cognitoWebClientId, theme, getApiBaseUrl }}
      {...props}
    />
  )
}

export const useEnvironment = () => useContext(EnvironmentContext)
