import { message } from 'antd'
import { Auth, Hub } from 'aws-amplify'
import moment from 'moment'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import useLocalStorage from 'hooks/useLocalStorage'
import { useUserQuery } from 'hooks/useUserQuery'
import { User } from 'types/user'
import { logCognitoExceptionInSentry, logMessageInSentry } from 'util/error-message'

type State = {
  isLogged: boolean
  isStarting: boolean
  loggedInUser: User | null
  logout: () => void
}

const initialState = {
  isLogged: false,
  isStarting: true,
  loggedInUser: null,
  logout: () => {},
}

const AuthContext = createContext<State>(initialState)

type AuthProviderProps = {
  initialAuthState?: {
    isLogged?: boolean
  }
  children: any
  props?: any
}

// Error messages
const ERROR_MESSAGE_WRONG_CREDENTIALS = 'Incorrect username or password.'
const ERROR_MESSAGE_USER_NOT_ACTIVE = 'Your account is no longer active. Contact your admin for support.'
const ERROR_MESSAGE_USER_DOES_NOT_EXIST = 'User does not exist.'
const ERROR_MESSAGE_RESTRICTED_ACCESS = "You don't have the right permissions to access this part of the platform."
const ERROR_MESSAGE_UNEXPECTED = 'Something went wrong, please contact your [admin / support] to help you out.'

function AuthProvider({ initialAuthState = {}, ...props }: AuthProviderProps) {
  const [isLogged, setIsLogged] = useState(initialAuthState.isLogged || false)
  const [isStarting, setIsStarting] = useState(true)
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null)
  const [userId, setUserId] = useState<string | null>(null)
  const [resourceOwnerUuidFromAdminUi] = useLocalStorage('resourceOwnerUuid')
  const { user, error } = useUserQuery({ id: userId as string }, { enabled: userId !== null })

  const signInWithAmplify = useCallback(async (data: any) => {
    const { payload } = data.signInUserSession.idToken

    setUserId(payload.sub)
  }, [])

  useEffect(() => {
    if (!user) {
      return
    }

    const { isActive, numeralLanguage, role } = user

    if (!isActive) {
      logMessageInSentry('User is not active', { extra: { user } })
      signOutWithAmplifyAndShowError(ERROR_MESSAGE_USER_NOT_ACTIVE)

      return
    }

    if (!resourceOwnerUuidFromAdminUi && role !== 'resource_owners') {
      logMessageInSentry('User does not have the role: resource_owners', { extra: { user } })
      signOutWithAmplifyAndShowError(ERROR_MESSAGE_RESTRICTED_ACCESS)
      return
    }

    if (resourceOwnerUuidFromAdminUi && role !== 'administrators') {
      logMessageInSentry(
        'User is trying to access the greenhouse-ui as administrator but does not have the administrators role',
        { extra: { user } },
      )
      signOutWithAmplifyAndShowError(ERROR_MESSAGE_RESTRICTED_ACCESS)
      return
    }

    setLoggedInUser(user)
    setIsLogged(true)
    setUserLocale(numeralLanguage)
    setIsStarting(false)
  }, [resourceOwnerUuidFromAdminUi, user])

  useEffect(() => {
    if (error) {
      resetAuthState()
    }
  }, [error])

  const signOutWithAmplifyAndShowError = (errorMessage: string) => {
    Auth.signOut()
    localStorage.removeItem('resourceOwnerUuid')
    message.error(errorMessage)
  }

  useEffect(() => {
    const listener = (userData: any) => {
      const { data, event } = userData.payload

      if (event === 'signIn') {
        signInWithAmplify(data)
      } else if (event === 'signIn_failure') {
        const errorMsg = data.message ? data.message : null

        if (!errorMsg) return

        if (errorMsg === ERROR_MESSAGE_WRONG_CREDENTIALS || errorMsg === ERROR_MESSAGE_USER_DOES_NOT_EXIST) {
          message.error(ERROR_MESSAGE_WRONG_CREDENTIALS)
        } else {
          message.error(ERROR_MESSAGE_UNEXPECTED)
          logCognitoExceptionInSentry(userData)
        }
      }
    }

    Hub.listen('auth', listener)

    return () => {
      Hub.remove('auth', listener)
    }
  }, [signInWithAmplify])

  function setUserLocale(locale = 'en') {
    moment.locale(locale)
  }

  useEffect(() => {
    const setupUserIfAlreadyLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        await signInWithAmplify(user)
      } catch (err) {
        resetAuthState()
      }
    }

    if (!isStarting) return

    setupUserIfAlreadyLoggedIn()
  }, [isStarting]) // eslint-disable-line react-hooks/exhaustive-deps

  const logout = useCallback(() => {
    resetAuthState()

    Auth.signOut()
    localStorage.removeItem('resourceOwnerUuid')
  }, [])

  function resetAuthState() {
    setLoggedInUser(null)
    setUserId(null)
    setIsLogged(false)
    setIsStarting(false)
  }

  return <AuthContext.Provider value={{ isLogged, isStarting, loggedInUser, logout }} {...props} />
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
