import { FormattedMessage } from 'react-intl'

import FormattedDateFromUTCToTimezone from 'components/FormattedDateFromUTCToTimezone'
import Resources from 'components/ProductsServices/Resources'
import TimeOfDayDisplay from 'components/TimeOfDayDisplay'
import ExceptionDateField from './ExceptionDateField'
import RecurrenceField from './RecurrenceField'
import StatusButton from './StatusButton'
import FormattedPrice from 'components/util/FormattedPrice'
import FormattedStatus from 'components/util/FormattedRestrictionStatus'
import NoData from 'components/util/NoData'

function Table({ resourceOwnerId, serviceRules, timeZone, onRowClick, onUpdate }) {
  return (
    <div className="table-container-fluid" style={{ marginTop: '20px' }}>
      {serviceRules.length === 0 ? (
        <NoData />
      ) : (
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.start-date" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.end-date" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.time-of-day" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.recurrence" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.products-services.afrr.modal.limit.price" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.resources" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.exception-dates" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.status" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {serviceRules.map((serviceRule) => (
              <tr key={serviceRule.id} onClick={() => onRowClick(serviceRule.id)}>
                <td>
                  <FormattedDateFromUTCToTimezone date={serviceRule.startAt} timezone={timeZone} />
                </td>
                <td>
                  <FormattedDateFromUTCToTimezone date={serviceRule.endAt} timezone={timeZone} />
                </td>
                <td>
                  <TimeOfDayDisplay endAt={serviceRule.timeEndAt} startAt={serviceRule.timeStartAt} />
                </td>
                <td>
                  <RecurrenceField recurrence={serviceRule.recurrence} />
                </td>
                <td>
                  <FormattedMessage id="app.resource-owner.products-services.modal.help.text.euro.sign" />
                  <FormattedPrice price={serviceRule.limitValue} />
                </td>
                <td>
                  <Resources wires={serviceRule.analogWires} wireType="analogWire" />
                </td>
                <td>
                  <ExceptionDateField exceptionDates={serviceRule.exceptionDates} />
                </td>
                <td>
                  <FormattedStatus activeAt={serviceRule.activeAt} status={serviceRule.status} />
                </td>
                <td>
                  <StatusButton resourceOwnerId={resourceOwnerId} serviceRule={serviceRule} onUpdate={onUpdate} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default Table
