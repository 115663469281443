import React from 'react'

import { AuthProvider } from 'contexts/auth-context'
import { ResourceOwnerProvider } from 'contexts/resource-owner-context'

interface TopLevelProvidersProps {
  children: React.ReactNode
}

function TopLevelProviders({ children }: TopLevelProvidersProps) {
  return (
    <AuthProvider>
      <ResourceOwnerProvider>{children}</ResourceOwnerProvider>
    </AuthProvider>
  )
}

export default TopLevelProviders
