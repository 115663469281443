import { DATE_FORMAT } from 'constants/date'
import { convertFromUTCToMomentDateUsingTimezone } from 'util/time-utils'

type FormattedDateFromUTCToTimezoneProps = {
  date: string | null
  format?: string
  timezone: string
}

function FormattedDateFromUTCToTimezone({ date, format = DATE_FORMAT, timezone }: FormattedDateFromUTCToTimezoneProps) {
  if (!date) return <span aria-label="Formatted date from UTC to timezone">--</span>

  return (
    <span aria-label="Formatted date from UTC to timezone">
      {convertFromUTCToMomentDateUsingTimezone(date, timezone).format(format)}
    </span>
  )
}

export default FormattedDateFromUTCToTimezone
