/* eslint-disable max-len */
import defaultMessages from './en'

const messages = {
  ...defaultMessages,
  'app.login.logout': 'Uitloggen',

  'app.edit': 'BEWERKEN',
  'app.save': 'OPSLAAN',
  'app.cancel': 'ANNULEREN',
  'app.close': 'SLUITEN',
  'app.ok': 'OK',
  'app.delete': 'VERWIJDEREN',

  'app.resource-owner.no-data-to-display': 'Er is geen data om weer te geven',
  'app.resource-owner.timezone': 'Tijdzone',
  'app.resource-owner.navbar.dashboard': 'Home',
  'app.resource-owner.navbar.activationlog': 'Activatie logboek',
  'app.resource-owner.navbar.products-services': 'Producten',
  'app.resource-owner.navbar.revenues': 'Opbrengsten',
  'app.resource-owner.navbar.restrictions': 'Beperkingen',
  'app.resource-owner.navbar.settings': 'Instellingen',

  'app.resource-owner.products-services.afrr.assets': 'Assets',
  'app.resource-owner.products-services.afrr.rules.select-resources': 'SELECTEER ASSETS',
  'app.resource-owner.products-services.afrr.rules.selected-resources': 'GESELECTEERDE ASSETS',
  'app.resource-owner.products-services.afrr.modal.limit.price': 'Prijs (€/MW)',

  'app.resource-owner.products-services.modal.limit.price': 'Prijs',
  'app.resource-owner.products-services.modal.limit.consumption': 'Verbruik',
  'app.resource-owner.products-services.modal.limit.frequency': 'Frequentie',
  'app.resource-owner.products-services.navbar.mfrrda-up': 'mFRRda up',
  'app.resource-owner.products-services.navbar.mfrrda-down': 'mFRRda down',
  'app.resource-owner.products-services.contract-date': 'Contract datum',
  'app.resource-owner.products-services.new-rule': 'NIEUWE BESCHIKBAARHEID',
  'app.resource-owner.products-services.manage-scheduled-shut-offs': 'GEPLANDE SHUT-OFFs BEHEREN',
  'app.resource-owner.products-services.filter-rule': 'Filter beschikbaarheden:',
  'app.resource-owner.products-services.modal.show-advanced-options': 'Geavanceerde opties weergeven',
  'app.resource-owner.products-services.modal.hide-advanced-options': 'Geavanceerde opties verbergen',
  'app.resource-owner.products-services.modal.max.turn.offs.per.day': 'Max uitschakelingen per dag',
  'app.resource-owner.products-services.modal.max.turn.offs.per.week': 'Max uitschakelingen per week',
  'app.resource-owner.products-services.modal.off.time.max.length': 'Uitschakeltijd max lengte',
  'app.resource-owner.products-services.modal.on.time.min.length': 'Inschakeltijd min lengte',
  'app.resource-owner.products-services.modal.help.text.times': 'keer',
  'app.resource-owner.products-services.modal.help.text.hours': 'uren',
  'app.resource-owner.products-services.modal.scheduled-shut-offs': 'GEPLANDE SHUT-OFFs',
  'app.resource-owner.products-services.modal.delete-rule-confirmation':
    'Weet je zeker dat je deze beschikbaarheid wilt verwijderen?',
  'app.resource-owner.products-services.modal.delete-rule-yes': 'JA, VERWIJDER BESCHIKBAARHEID',
  'app.resource-owner.products-services.modal.delete-restriction-confirmation':
    'Weet je zeker dat je deze onbeschikbaarheid wilt verwijderen?',
  'app.resource-owner.products-services.modal.delete-restriction-yes': 'Ja, verwijder onbeschikbaarheid',
  'app.resource-owner.products-services.limit-price': 'Prijslimiet',
  'app.resource-owner.products-services.actual-price': 'Transactieprijs',
  'app.resource-owner.products-services.limit-consumption': 'Verbruikslimiet',
  'app.resource-owner.products-services.actual-consumption': 'Werkelijk verbruik',
  'app.resource-owner.products-services.enabled': 'Ingeschakeld',
  'app.resource-owner.products-services.cancelled': 'Geannuleerd',

  'app.resource-owner.dashboard.current-power-consumption': 'Huidig verbruik',
  'app.resource-owner.dashboard.graph.power-consumption': 'Elektriciteitsverbruik',
  'app.resource-owner.dashboard.graph.time-period': 'Periode',
  'app.resource-owner.dashboard.graph.max-consumption': 'Maximaal verbruik',
  'app.resource-owner.dashboard.graph.average-consumption': 'Gemiddeld verbruik',
  'app.resource-owner.dashboard.graph.min-consumption': 'Minimaal verbruik',
  'app.resource-owner.dashboard.phase': 'Fase',
  'app.resource-owner.dashboard.welcome-to': 'Welkom bij',

  'app.resource-owner.modal.affected-resources': 'GESELECTEERDE ASSETS',
  'app.resource-owner.modal.group-name': 'Naam',
  'app.resource-owner.modal.section': 'Sectie',
  'app.resource-owner.modal.lamp-position.top': 'Top',
  'app.resource-owner.modal.lamp-position.between': 'Tussen',
  'app.resource-owner.modal.lamp-position.all': 'Alle',
  'app.resource-owner.modal.lamp-position.unknown': 'Onbekend',
  'app.resource-owner.modal.power': 'Vermogen',
  'app.resource-owner.modal.device-type.led0': 'LED (0 min)',
  'app.resource-owner.modal.device-type.hps2': 'HPS (2 min)',
  'app.resource-owner.modal.device-type.hps3': 'HPS (3 min)',
  'app.resource-owner.modal.device-type.hps5': 'HPS (5 min)',
  'app.resource-owner.modal.device-type.hps10': 'HPS (10 min)',
  'app.resource-owner.modal.device-type.hps15': 'HPS (15 min)',
  'app.resource-owner.modal.device-type.hps20': 'HPS (20 min)',
  'app.resource-owner.modal.device-type.hps30': 'HPS (30 min)',
  'app.resource-owner.modal.device-type.hps60': 'HPS (60 min)',
  'app.resource-owner.modal.device-type.chamber0': 'Kamer (0 min)',
  'app.resource-owner.modal.device-type.chamber3': 'Kamer (3 min)',
  'app.resource-owner.modal.device-type.channel0': 'Kanaal (0 min)',
  'app.resource-owner.modal.device-type.channel3': 'Kanaal(3 min)',
  'app.resource-owner.modal.device-type.other0': 'Overig (0 min)',
  'app.resource-owner.modal.device-type.other3': 'Overig (3 min)',

  'app.resource-owner.more-results': 'nog {count}',

  'app.resource-owner.activation-event-log.event-started': 'Begin afroep',
  'app.resource-owner.activation-event-log.event-ended': 'Einde afroep',
  'app.resource-owner.activation-event-log.event-duration': 'Duur',
  'app.resource-owner.activation-event-log.service': 'Product',
  'app.resource-owner.activation-event-log.modal.activated-at': 'Uitgezet om',
  'app.resource-owner.activation-event-log.modal.deactivated-at': 'Aangezet om',
  'app.resource-owner.activation-event-log.modal.activation-duration': 'Tijdsduur',

  'app.resource-owner.restrictions.activate': 'INSCHAKELEN',
  'app.resource-owner.restrictions.deactivate': 'UITSCHAKELEN',
  'app.resource-owner.restrictions.start-date': 'Startdatum',
  'app.resource-owner.restrictions.end-date': 'Einddatum',
  'app.resource-owner.restrictions.time-of-day': 'Tijdstip',
  'app.resource-owner.restrictions.resources': 'Assets',
  'app.resource-owner.restrictions.resources-no-name': '{count, plural, one{# asset} other{# assets}}',
  'app.resource-owner.restrictions.updated-on': 'Gewijzigd op',
  'app.resource-owner.from': 'Vanaf',
  'app.resource-owner.to': 'Tot',
  'app.resource-owner.status': 'Status',
  'app.resource-owner.recurrence': 'Herhaling',
  'app.resource-owner.restrictions.status.active': 'Ingeschakeld',
  'app.resource-owner.restrictions.status.deactivated': 'Uitgeschakeld',
  'app.resource-owner.restrictions.status.expired': 'Verlopen',
  'app.resource-owner.restrictions.status.waiting': 'Wachten',
  'app.resource-owner.restrictions.status.waiting-will-become-active': 'Wachten, wordt actief op {activeAtDateTime}',
  'app.resource-owner.restrictions.new-restriction': 'NIEUWE ONBESCHIKBAARHEID',
  'app.resource-owner.restrictions.lamp-type': 'Type',
  'app.resource-owner.restrictions.lamp-placement': 'Plaatsing',
  'app.resource-owner.restrictions.choose-affected-lamp-groups': 'SELECTEER ASSETS',
  'app.resource-owner.restrictions.end-date-before-start-date-error': 'Einddatum kan niet voor startdatum zijn',
  'app.resource-owner.restrictions.end-time-before-start-time-error': 'Eindtijd kan niet voor starttijd zijn',
  'app.resource-owner.restrictions.end-time-equals-start-time-error': 'Eindtijd kan niet gelijk zijn aan starttijd',
  'app.resource-owner.restrictions.availability-at-least-one-hour-error':
    'Assets moeten minimaal 1 uur beschikbaar zijn',
  'app.resource-owner.restrictions.select-group': 'Apparaten selecteren',
  'app.resource-owner.restrictions.recurrence': 'Herhaling',
  'app.resource-owner.restrictions.recurrence.every-day': 'Iedere dag',
  'app.resource-owner.restrictions.exception-dates': 'Uitzonderingsdatums',
  'app.resource-owner.exception-dates': 'Uitzonderingsdatums',
  'app.resource-owner.add-exception-date': 'Voeg uitzonderingsdatum toe',
  'app.resource-owner.no-exception-dates-yet':
    'Nog geen uitzonderingsdatums. Alle datums zijn onderdeel van de geselecteerde periode.',
  'app.resource-owner.restrictions.select-resource': 'Selecteer asset',
  'app.resource-owner.restrictions.reset-filter': 'VERWIJDER FILTER',
  'app.resource-owner.restrictions.frequency.does_not_repeat': 'Herhaalt niet',
  'app.resource-owner.restrictions.frequency.repeat_daily': 'Herhaal dagelijks',
  'app.resource-owner.restrictions.end-time': 'Eindtijd',
  'app.resource-owner.restrictions.start-time': 'Starttijd',
  'app.resource-owner.restrictions.all-day': 'Hele dag',
  'app.resource-owner.restrictions.date-and-time': 'Datum en tijd',

  'app.resource-owner.settings.missing-bank-details':
    'Het rekeningnummer ontbreekt. Graag het rekeningnummer invullen voor het ontvangen van betalingen',
  'app.resource-owner.settings.e-mail-address': 'E-mailadres voor meldingen',
  'app.resource-owner.settings.mobile-number': 'Mobiel nummer voor meldingen',
  'app.resource-owner.settings.mobile-number.example': 'bijv. +31 6 1234 5678',
  'app.resource-owner.settings.preferred-language': 'Taal',
  'app.resource-owner.settings.user-information': 'KLANTGEGEVENS EN INSTELLINGEN',
  'app.resource-owner.settings.iban': 'Rekeningnummer (IBAN)',
  'app.resource-owner.settings.iban-holder': 'Naam Rekeninghouder (bedrijfsnaam)',
  'app.resource-owner.settings.notification-preferences': 'Meldingen',
  'app.resource-owner.settings.notification-preferences.sms': 'SMS',
  'app.resource-owner.settings.notification-preferences.email': 'E-mail',
  'app.resource-owner.notification-preferences.no-email-address':
    'Er moet een e-mailadres worden ingesteld om e-mailmeldingen te activeren',
  'app.resource-owner.settings.notification-preferences.no-mobile-number':
    'Er moet een mobiel telefoonnummer worden ingesteld om sms-meldingen te activeren',

  'app.resource-owner.revenues.export-as-csv': 'Exporteren als .csv',
  'app.resource-owner.revenues.column.period': 'Periode',
  'app.resource-owner.revenues.column.capacity': 'Gecontracteerde capaciteit (MWh)',
  'app.resource-owner.revenues.column.sold-capacity': 'Verkochte capaciteit (MWh)',
  'app.resource-owner.revenues.column.contract-price': 'Prijs',
  'app.resource-owner.revenues.column.revenue': 'Opbrengsten',
  'app.resource-owner.revenue.month.1': 'januari',
  'app.resource-owner.revenue.month.2': 'februari',
  'app.resource-owner.revenue.month.3': 'maart',
  'app.resource-owner.revenue.month.4': 'april',
  'app.resource-owner.revenue.month.5': 'mei',
  'app.resource-owner.revenue.month.6': 'juni',
  'app.resource-owner.revenue.month.7': 'juli',
  'app.resource-owner.revenue.month.8': 'augustus',
  'app.resource-owner.revenue.month.9': 'september',
  'app.resource-owner.revenue.month.10': 'oktober',
  'app.resource-owner.revenue.month.11': 'november',
  'app.resource-owner.revenue.month.12': 'december',
  // eslint-disable-next-line quotes
  'app.resource-owner.revenues.tooltip': `Deze opbrengsten zijn een indicatie en exclusief de vergoeding per afroep. Aan het einde \
van de maand krijgt u een mail met daadwerkelijke opbrengsten inclusief de vergoeding afhankelijk van het afgeroepen \
noodvermogen in deze periode.`,
  'app.resource-owner.revenue.no-value': 'Er zijn nog geen waardes berekend voor deze service.',

  'app.notification.error.login': 'Fout bij inloggen',
  'app.notification.error.update-token': 'Fout bij het updaten van het token',
  'app.notification.error.session-error': 'Fout in sessie',
  'app.notification.error.get-power-consumption': 'Fout bij het laden van de energie verbruiksgegevens',
  'app.notification.error.get-restrictions': 'Fout bij het ophalen van de onbeschikbaarheden',
  'app.notification.error.get-plans': 'Fout bij het ophalen van geplande uitschakelingen',
  'app.notification.success.save-restriction': 'Onbeschikbaarheid succesvol opgeslagen',
  'app.notification.error.save-restriction': 'Fout bij het opslaan van de onbeschikbaarheid',
  'app.notification.error.get-sections': 'Fout bij het lezen van groepen',
  'app.notification.success.user-save': 'Gebruiker succesvol opgeslagen',
  'app.notification.error.user-save': 'Fout bij het opslaan van de gebruiker',
  'app.notification.error.get-resource-owner': 'Fout bij het verkrijgen van informatie eigenaar',
  'app.notification.error.get-lamp-resource-owners': 'Fout bij het ophalen van groepen',
  'app.notification.error.get-activation-events': 'Fout bij het ophalen van de activeringsinformatie',
  'app.notification.error.activate-restriction': 'Fout bij het activeren van de onbeschikbaarheid',
  'app.notification.success.activate-restriction': 'Onbeschikbaarheid succesvol geactiveerd',
  'app.notification.error.deactivate-restriction': 'Fout bij het opheffen van de onbeschikbaarheid',
  'app.notification.success.deactivate-restriction': 'Onbeschikbaarheid succesvol opgeheven',
  'app.notification.error.save-restriction-relaywires': 'Fout bij het opslaan van de onbeschikbaarheid relais',
  'app.notification.error.delete-restriction-relaywires': 'Fout bij het verwijderen van onbeschikbaarheid relais',
  'app.notification.success.delete-restriction': 'Onbeschikbaarheid succesvol verwijderd',
  'app.notification.error.delete-restriction': 'Fout bij het verwijderen van de onbeschikbaarheid',
  'app.notification.error.get-power-consumption-graph': 'Fout bij het plotten van de energie verbruiksgrafiek',
  'app.notification.error.loading-revenues': 'Fout bij het laden van de inkomsten',
  'app.notification.error.loading-revenue-period': 'Fout bij het laden van inkomsten periode',
  'app.notification.error.get-services': 'Fout bij het ophalen van services',
  'app.notification.error.get-rules': 'Fout bij het ophalen van de beschikbaarheid',
  'app.notification.success.save-rule': 'Beschikbaarheid succesvol opgeslagen',
  'app.notification.error.activate-rule': 'Fout bij activering van de beschikbaarheid',
  'app.notification.error.deactivate-rule': 'Fout bij het deactiveren van de beschikbaarheid',
  'app.notification.success.activate-rule': 'Beschikbaarheid succesvol geactiveerd',
  'app.notification.success.deactivate-rule': 'Beschikbaarheid succesvol opgeheven',
  'app.notification.error.save-rule': 'Fout bij het opslaan van de beschikbaarheid',
  'app.notification.error.delete-rule-resource': 'Fout bij het verwijderen van een asset uit een beschikbaarheid',
  'app.notification.error.rule.empty-price-limit': 'De prijs mag niet leeg zijn',
  'app.notification.error.rule.empty-consumption-limit': 'Het verbruik mag niet leeg zijn',
  'app.notification.error.rule.empty-frequency-limit': 'De frequentie mag niet leeg zijn',
  'app.notification.error.add.resource':
    'Een asset heeft al een beschikbaarheid voor dezelfde tijdsperiode: {addedInfo}',
  'app.notification.success.delete-rule': 'Beschikbaarheid succesvol verwijderd',
  'app.notification.error.delete-rule': 'Fout bij het verwijderen van de beschikbaarheid',
  'app.notification.error.save-rule-relaywires': 'Fout bij het opslaan van de beschikbaarheid relais',
  'app.notification.success.enable-plan': 'Beschikbaarheid succesvol geactiveerd',
  'app.notification.error.enable-plan': 'Fout bij activering van de beschikbaarheid',
  'app.notification.error.cancel-plan': 'Fout bij het deactiveren van de beschikbaarheid',
  'app.notification.success.cancel-plan': 'Beschikbaarheid succesvol gedeactiveerd',
  'app.notification.error.old-password-invalid': 'Het oude wachtwoord dat je hebt ingevoerd is onjuist',

  'app.information-banner.flexportal-is-here': 'Onze nieuwe FlexPortal is hier 🎉',
  'app.information-banner.flexportal-is-here-description':
    ', en biedt een snellere, meer intuïtieve ervaring. In september word je automatisch doorgestuurd, maar je kunt de nieuwe functies nu al verkennen.',
  'app.information-banner.try-new-flexportal': 'Probeer de nieuwe FlexPortal',

  'app.validation.email.wrong-format': 'Ongeldige invoer voor e-mail adres',

  'server.error.token-expired': 'Sessie verlopen',
  'server.error.invalid-token': 'Ongeldige sessie token',
  'server.error.unexpected-auth-error': 'Onverwachte fout in sessie',
  'server.error.incorrect-username-password': 'Onjuiste gebruikersnaam of wachtwoord',
  'server.error.user-deactivated': 'Gebruiker is gedeactiveerd',
  'server.error.fill-all-password-fields': 'Niet alle wachtwoord-velden zijn ingevuld',
  'server.error.passwords-do-not-match': 'Wachtwoorden komen niet overeen',
  'server.error.old-password-invalid': 'Oude wachtwoord is onjuist',
  'server.error.password-min-length': 'Wachtwoord moet minstens uit 6 tekens bestaan',
  'server.error.access-denied': 'Toegang geweigerd',
  'server.error.validation-failed': 'Valideren mislukt',
  'server.error.incorrect-username-or-password': 'Gebruikersnaam en/of wachtwoord onjuist',
}

export default messages
