import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CheckOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, message, Modal, Select } from 'antd'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { getNotificationPreferences, saveNotificationPreferences } from 'api/notification-preferences'
import { logExceptionInSentry } from 'util/error-message'
import { useResourceOwner } from 'contexts/resource-owner-context'
import PhoneNumber from './PhoneNumber'

const languages = {
  en: 'English',
  fi: 'Suomi',
  sv: 'Svenska',
  nl: 'Nederlands',
  el: 'Ελληνικά',
}

function isBlank(string) {
  return !string || string.trim().length === 0
}

function toNotificationPreference(resourceOwnerId, channel) {
  return {
    resourceOwnerId: resourceOwnerId,
    channel: channel,
  }
}

const supportedNotificationChannels = [
  {
    label: <FormattedMessage id="app.resource-owner.settings.notification-preferences.sms" />,
    value: 'sms',
  },
  {
    label: <FormattedMessage id="app.resource-owner.settings.notification-preferences.email" />,
    value: 'email',
  },
]
const notificationChannelNamesMap = {
  sms: 'sms',
  email: 'email',
}

const { Option } = Select

function Settings({ onHide }) {
  const [form] = Form.useForm()
  const intl = useIntl()

  const { resourceOwner, saveResourceOwner } = useResourceOwner()
  const [notificationPreferences, setNotificationPreferences] = useState([])

  const updateResourceOwner = useCallback(
    async (resourceOwnerToSave) => {
      try {
        await saveResourceOwner(resourceOwnerToSave)
        message.success(intl.formatMessage({ id: 'app.notification.success.user-save' }))

        // When the uiLanguage value is different, the resource-owner-context will already take care of the hide.
        if (resourceOwner.uiLanguage === resourceOwnerToSave.uiLanguage) {
          onHide()
        }
      } catch (e) {
        message.error(intl.formatMessage({ id: 'app.notification.error.user-save' }))
        throw e
      }
    },
    [intl, resourceOwner.uiLanguage, saveResourceOwner, onHide],
  )

  function areNotificationPreferencesValid(channels, newResourceOwnerData) {
    let allValid = true
    channels.forEach((channel) => {
      if (channel.toLowerCase() === 'email' && isBlank(newResourceOwnerData.email)) {
        message.error(
          intl.formatMessage({ id: 'app.resource-owner.settings.notification-preferences.no-email-address' }),
        )
        allValid = false
      }
      if (channel.toLowerCase() === 'sms' && isBlank(newResourceOwnerData.phone)) {
        message.error(
          intl.formatMessage({ id: 'app.resource-owner.settings.notification-preferences.no-mobile-number' }),
        )
        allValid = false
      }
    })

    return allValid
  }

  const updateNotificationPreferences = useCallback(
    async (channels) => {
      try {
        const preferences = channels.map((channel) => toNotificationPreference(resourceOwner.id, channel.toLowerCase()))
        await saveNotificationPreferences(resourceOwner.id, preferences)
      } catch (e) {
        message.error(intl.formatMessage({ id: 'app.notification.error.user-save' }))
        throw e
      }
    },
    [intl, resourceOwner.id],
  )

  async function handleSave() {
    try {
      const values = await form.validateFields()

      const newResourceOwnerData = {
        ...resourceOwner,
        ...values,
        numeralLanguage: values.uiLanguage,
        phone: values.phone ? values.phone.replace('+', '') : '',
      }

      if (areNotificationPreferencesValid(notificationPreferences, newResourceOwnerData)) {
        try {
          await updateNotificationPreferences(notificationPreferences)
          await updateResourceOwner(newResourceOwnerData)
        } catch (error) {
          logExceptionInSentry(error)
        }
      }
    } catch {
      // Doesn't need to show any error message, as the validation is done already for each field
    }
  }

  const loadNotificationPreferences = useCallback(async () => {
    try {
      const preferences = await getNotificationPreferences(resourceOwner.id)
      setNotificationPreferences(preferences.map((preference) => notificationChannelNamesMap[preference.channel]))
    } catch (error) {
      logExceptionInSentry(error)
    }
  }, [resourceOwner.id])

  useEffect(() => {
    loadNotificationPreferences()
  }, [loadNotificationPreferences])

  function onCheckboxChange(currentlySelectedChannels) {
    setNotificationPreferences(currentlySelectedChannels)
  }

  function settingsForm() {
    return (
      <>
        <Form.Item
          label={intl.formatMessage({ id: 'app.resource-owner.settings.e-mail-address' })}
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              required: false,
              type: 'email',
              message: intl.formatMessage({ id: 'app.validation.email.wrong-format' }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'app.resource-owner.settings.mobile-number' })}
          name="phone"
          rules={[
            () => ({
              validator(_, value) {
                if (!value || isPossiblePhoneNumber(value)) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(intl.formatMessage({ id: 'app.resource-owner.settings.mobile-number.example' })),
                )
              },
            }),
          ]}
          validateTrigger="onBlur"
        >
          <PhoneNumber />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'app.resource-owner.settings.preferred-language' })}
          name="uiLanguage"
        >
          <Select>
            <Option value="en">{languages.en}</Option>
            <Option value="fi">{languages.fi}</Option>
            <Option value="sv">{languages.sv}</Option>
            <Option value="nl">{languages.nl}</Option>
            <Option value="el">{languages.el}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'app.resource-owner.settings.iban' })}
          name="iban"
          rules={[
            {
              message: '',
              required: resourceOwner.isIbanNeeded,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'app.resource-owner.settings.iban-holder' })}
          name="ibanHolder"
          rules={[
            {
              message: '',
              required: resourceOwner.isIbanNeeded,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'app.resource-owner.settings.notification-preferences' })}
          style={{ marginBottom: 0 }}
        >
          <Checkbox.Group
            options={supportedNotificationChannels}
            value={notificationPreferences}
            onChange={onCheckboxChange}
          />
        </Form.Item>
      </>
    )
  }

  return (
    <Modal
      footer={
        <Button icon={<CheckOutlined />} type="primary" onClick={handleSave}>
          <FormattedMessage id="app.save" />
        </Button>
      }
      title={intl.formatMessage({ id: 'app.resource-owner.settings.user-information' })}
      visible
      onCancel={onHide}
    >
      <div>
        <Form
          form={form}
          initialValues={{
            email: resourceOwner.email,
            iban: resourceOwner.iban,
            ibanHolder: resourceOwner.ibanHolder,
            numeralLanguage: resourceOwner.numeralLanguage,
            phone: resourceOwner.phone ? `+${resourceOwner.phone}` : resourceOwner.phone,
            uiLanguage: resourceOwner.uiLanguage,
          }}
          layout="vertical"
        >
          {settingsForm()}
        </Form>
      </div>
    </Modal>
  )
}

export default Settings
