import React from 'react'
import { FormattedNumber } from 'react-intl'

const FormattedNumberPrefixSuffix = ({ divClassName, maximumFractionDigits, prefix, value, suffix }) => {
  const inputClassName = divClassName

  return (
    <div className={inputClassName}>
      <span>
        {prefix}
        <FormattedNumber value={value} maximumFractionDigits={maximumFractionDigits} />
        <span style={{ marginLeft: '3px' }}>{suffix}</span>
      </span>
    </div>
  )
}

export default FormattedNumberPrefixSuffix
