import axios from 'axios'

type NotificationPreferenceBase = {
  resourceId: number
  channel: string
}

export function getNotificationPreferences(resourceOwnerId: number) {
  return axios.get(`/resourceOwners/${resourceOwnerId}/notification-preferences`)
}

export function saveNotificationPreferences(
  resourceOwnerId: number,
  notificationPreferences: NotificationPreferenceBase[],
) {
  return axios.put(`/resourceOwners/${resourceOwnerId}/notification-preferences`, notificationPreferences)
}
