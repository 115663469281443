import moment from 'moment'

// We do not want to support Greek language, but we do want to support Greek date format.
moment.defineLocale('el', {
  parentLocale: 'en',
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd, D MMMM YYYY HH:mm',
  },
})
