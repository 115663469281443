import { InfoCircleOutlined } from '@ant-design/icons'
import { Space, Tooltip } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { useEnvironment } from 'contexts/environment-context'

const REVENUE_VALUE = 'app.resource-owner.revenues.column.revenue'

function PivotHeader({ fields }) {
  const { countryCode } = useEnvironment()
  const intl = useIntl()

  if (countryCode === 'nl') {
    return (
      <div className="revenue-pivot-header-wrapper">
        {fields.map((value) => {
          if (value !== REVENUE_VALUE) {
            return (
              <div key={value} className="revenue-pivot-header">
                <span className="revenue-pivot-header-label">
                  <FormattedMessage id={value} />
                </span>
              </div>
            )
          }

          return (
            <div key={value} className="revenue-pivot-header">
              <Space>
                <span className="revenue-pivot-header-label">
                  <FormattedMessage id={value} />
                </span>
                <Tooltip title={intl.formatMessage({ id: 'app.resource-owner.revenues.tooltip' })}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="revenue-pivot-header-wrapper">
      {fields.map((value) => (
        <div key={value} className="revenue-pivot-header">
          <span className="revenue-pivot-header-label">
            <FormattedMessage id={value} />
          </span>
        </div>
      ))}
    </div>
  )
}

export default PivotHeader
