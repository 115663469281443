import { Form, Space } from 'antd'
import { useIntl } from 'react-intl'

import AllDayCheckbox from 'components/ProductsServices/Restrictions/AllDayCheckbox'
import CustomDatePicker from 'components/CustomDatePicker'
import TimeStartPicker from 'components/TimeStartPicker'
import TimeEndPicker from 'components/TimeEndPicker'

type DoesNotRepeatFormProps = {
  endAt: string
  isAllDayChecked: boolean
  startAt: string
  timeEndAt: string
  timeStartAt: string
  timeZone: string
  onChangeEndAt: (value: string) => void
  onChangeIsAllDayChecked: (value: boolean) => void
  onChangeStartAt: (value: string) => void
  onChangeTimeEndAt: (value: string) => void
  onChangeTimeStartAt: (value: string) => void
}

const PTU_RANGE = 60

function DoesNotRepeatForm({
  endAt,
  isAllDayChecked,
  startAt,
  timeEndAt,
  timeStartAt,
  timeZone,
  onChangeEndAt,
  onChangeIsAllDayChecked,
  onChangeStartAt,
  onChangeTimeEndAt,
  onChangeTimeStartAt,
}: DoesNotRepeatFormProps) {
  const intl = useIntl()

  return (
    <div>
      <Space size="large">
        <CustomDatePicker
          label="app.resource-owner.restrictions.start-date"
          timeZone={timeZone}
          value={startAt}
          onChange={onChangeStartAt}
        />
        <Form.Item label={intl.formatMessage({ id: 'app.resource-owner.restrictions.start-time' })}>
          <TimeStartPicker
            disabled={isAllDayChecked}
            ptuRange={PTU_RANGE}
            value={timeStartAt}
            onChange={onChangeTimeStartAt}
          />
        </Form.Item>
        <CustomDatePicker
          label="app.resource-owner.restrictions.end-date"
          timeZone={timeZone}
          value={endAt}
          onChange={onChangeEndAt}
        />
        <Form.Item label={intl.formatMessage({ id: 'app.resource-owner.restrictions.end-time' })}>
          <TimeEndPicker
            disabled={isAllDayChecked}
            isInclusive={false}
            ptuRange={PTU_RANGE}
            value={timeEndAt}
            onChange={onChangeTimeEndAt}
          />
        </Form.Item>
        <AllDayCheckbox value={isAllDayChecked} onChange={onChangeIsAllDayChecked} />
      </Space>
    </div>
  )
}

export default DoesNotRepeatForm
