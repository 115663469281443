import axios from 'axios'

export function activate(resourceOwnerId, serviceId, serviceRuleId) {
  return axios.post(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${serviceRuleId}/activate`)
}

export function deactivate(resourceOwnerId, serviceId, serviceRuleId) {
  return axios.post(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${serviceRuleId}/deactivate`)
}

export function save(resourceOwnerId, serviceId, serviceRule) {
  if (serviceRule.id != null) {
    return axios.put(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${serviceRule.id}`, serviceRule)
  }
  return axios.post(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/`, serviceRule)
}

export function addServiceRuleRelayWire(resourceOwnerId, serviceId, serviceRuleId, relayWire) {
  return axios.post(
    `/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${serviceRuleId}/relayWires`,
    relayWire,
  )
}

export function deleteServiceRuleRelayWire(resourceOwnerId, serviceId, serviceRuleId, relayWireId) {
  return axios.delete(
    `/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${serviceRuleId}/relayWires/${relayWireId}`,
  )
}

export function addServiceRuleAnalogWire(resourceOwnerId, serviceId, serviceRuleId, analogWire) {
  return axios.post(
    `/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${serviceRuleId}/analogWires`,
    analogWire,
  )
}

export function deleteServiceRuleAnalogWire(resourceOwnerId, serviceId, serviceRuleId, analogWireId) {
  return axios.delete(
    `/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${serviceRuleId}/analogWires/${analogWireId}`,
  )
}

export function getServiceRules(resourceOwnerId, serviceId) {
  return axios.get(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules`)
}

export function deleteServiceRule(resourceOwnerId, serviceId, ruleId) {
  return axios.delete(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/rules/${ruleId}`)
}

export function getPlans(resourceOwnerId, serviceId) {
  return axios.get(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/plans`)
}

export function enablePlan(resourceOwnerId, serviceId, planId) {
  return axios.post(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/plans/${planId}/activate`, null)
}

export function cancelPlan(resourceOwnerId, serviceId, planId) {
  return axios.post(`/resourceOwners/${resourceOwnerId}/services/${serviceId}/plans/${planId}/deactivate`, null)
}
