import { useQuery } from '@tanstack/react-query'
import { createContext, useContext } from 'react'

import { GET_FEATURE_TOGGLES_API_ID, getFeatureToggles } from 'api/feature-toggle'
import { useAuth } from 'contexts/auth-context'
import { useEnvironment } from './environment-context'

const initialState = {
  isEnabled: (feature: string) => false,
}

const FeatureToggleContext = createContext(initialState)

function FeatureToggleProvider(props: any) {
  const { isLogged } = useAuth()
  const { name, getApiBaseUrl } = useEnvironment()

  const apiBaseUrl = getApiBaseUrl()
  const getFeatureTogglesWithProvidedApiBaseUrl = () => getFeatureToggles(name, apiBaseUrl)

  const { data } = useQuery([GET_FEATURE_TOGGLES_API_ID], getFeatureTogglesWithProvidedApiBaseUrl, {
    enabled: isLogged,
    // It gets the feature toggles from the API only once the app is loaded
    staleTime: Infinity,
  })

  function isEnabled(featureToCheck: string): boolean {
    if (!data) return false

    const found = data.toggles.find((feature) => feature.name === featureToCheck)

    return found ? found.enabled : false
  }

  return (
    <FeatureToggleContext.Provider
      value={{
        isEnabled,
      }}
      {...props}
    />
  )
}

const useFeatureToggle = () => {
  const { isEnabled } = useContext(FeatureToggleContext)
  return { isEnabled }
}

/**
 * Example of usage
 *
 import { useFeatureToggle } from "contexts/feature-toggle-context"
 function MyComponent() {
  const { isEnabled } = useFeatureToggle()
  return (
    <div>
      {isEnabled('FEATURE_TOGGLE_NAME') ? (
        <p>Test toggle for frontend is enabled</p>
      ) : (
        <p>Test toggle for frontend is disabled</p>
      )}
    </div>
  )
 }
 */

export { FeatureToggleProvider, useFeatureToggle }
