import { Space } from 'antd'

import { useFeatureToggle } from 'contexts/feature-toggle-context'

import AllDayCheckbox from 'components/ProductsServices/Restrictions/AllDayCheckbox'
import CustomDatePicker from 'components/CustomDatePicker'
import TimeOfDaySelect from 'components/TimeOfDaySelect'

type RepeatDailyFormProps = {
  endAt: string
  isAllDayChecked: boolean
  startAt: string
  timeEndAt: string
  timeStartAt: string
  timeZone: string
  onChangeEndAt: (value: string) => void
  onChangeIsAllDayChecked: (value: boolean) => void
  onChangeStartAt: (value: string) => void
  onChangeTimeEndAt: (value: string) => void
  onChangeTimeStartAt: (value: string) => void
}

const PTU_RANGE = 60

function RepeatDailyForm({
  endAt,
  isAllDayChecked,
  startAt,
  timeEndAt,
  timeStartAt,
  timeZone,
  onChangeEndAt,
  onChangeIsAllDayChecked,
  onChangeStartAt,
  onChangeTimeEndAt,
  onChangeTimeStartAt,
}: RepeatDailyFormProps) {
  const { isEnabled } = useFeatureToggle()

  return (
    <Space size="large">
      <CustomDatePicker
        label="app.resource-owner.restrictions.start-date"
        timeZone={timeZone}
        value={startAt}
        onChange={onChangeStartAt}
      />
      <CustomDatePicker
        label="app.resource-owner.restrictions.end-date"
        timeZone={timeZone}
        value={endAt}
        onChange={onChangeEndAt}
      />
      <TimeOfDaySelect
        isAllDayChecked={isAllDayChecked}
        ptuRange={PTU_RANGE}
        timeStartAt={timeStartAt}
        timeEndAt={timeEndAt}
        onTimeStartAtChange={onChangeTimeStartAt}
        onTimeEndAtChange={onChangeTimeEndAt}
      />
      {isEnabled('PARTNER_UI_RESTRICTIONS_CHANGE_FREQUENCY_ENABLED') && (
        <AllDayCheckbox value={isAllDayChecked} onChange={onChangeIsAllDayChecked} />
      )}
    </Space>
  )
}

export default RepeatDailyForm
