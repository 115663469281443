import { Button, DatePicker, message, Select, Space } from 'antd'
import { orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiRegulators from 'api/regulators'
import * as apiSections from 'api/sections'
import * as apiServiceRules from 'api/service-rules'
import { DATE_FORMAT } from 'constants/date'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { logExceptionInSentry } from 'util/error-message'

import ModalDetails from 'components/ProductsServices/ServiceRules/ModalDetails'
import ModalEditor from 'components/ProductsServices/ServiceRules/ModalEditor'
import ModalScheduledShutOffs from 'components/ProductsServices/ServiceRules/ModalScheduledTurnOffs'
import Table from 'components/ProductsServices/ServiceRules/Table'
import Loading from 'components/util/Loading'

import styles from './ServiceRules.module.css'

const { Option } = Select

function getResources(serviceRules) {
  const resources = []

  serviceRules.forEach((serviceRule) => {
    if (serviceRule.relayWires && serviceRule.relayWires !== null) {
      serviceRule.relayWires.forEach((serviceRuleWire) => {
        if (serviceRuleWire.relayWire.name) {
          resources.push(serviceRuleWire.relayWire.name)
        }
      })
    }
  })

  return resources.sort()
}

const limitsInfo = {
  'max-price': {
    label: 'app.resource-owner.products-services.modal.limit.price',
    measurement: 'app.resource-owner.products-services.modal.help.text.euro.sign',
  },
  'ffr-up': {
    label: 'app.resource-owner.products-services.modal.limit.frequency',
    measurement: 'app.resource-owner.products-services.modal.help.text.hz',
  },
}

function ServiceRules({ service }) {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const getDefaultResourceFilterValue = useCallback(() => {
    return String(intl.formatMessage({ id: 'app.resource-owner.restrictions.select-resource' }))
  }, [intl])

  const [isLoading, setIsLoading] = useState(true)
  const [filterDate, setFilterDate] = useState('')
  const [filterResource, setFilterResource] = useState(getDefaultResourceFilterValue())
  const [plans, setPlans] = useState([])
  const [regulators, setRegulators] = useState([])
  const [sections, setSections] = useState([])
  const [serviceRules, setServiceRules] = useState([])
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showEditorModal, setShowEditorModal] = useState(false)
  const [showScheduledShutOffsModal, setShowScheduledShutOffsModal] = useState(false)
  const [modalServiceRule, setModalServiceRule] = useState(null)

  const loadPlans = useCallback(async () => {
    try {
      const data = await apiServiceRules.getPlans(resourceOwner.id, service.id)

      setPlans(data)
      setIsLoading(false)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.get-plans' }))
      logExceptionInSentry(error)
    }
  }, [intl, resourceOwner.id, service.id])

  const loadSections = useCallback(async () => {
    try {
      const data = await apiSections.getSections(resourceOwner.id)

      setSections(data)
      loadPlans()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.get-sections' }))
      logExceptionInSentry(error)
    }
  }, [intl, loadPlans, resourceOwner.id])

  const loadRegulators = useCallback(async () => {
    try {
      const data = await apiRegulators.getRegulators(resourceOwner.id)

      setRegulators(data)
      loadSections()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.get-regulators' }))
      logExceptionInSentry(error)
    }
  }, [intl, loadSections, resourceOwner.id])

  const loadServiceRules = useCallback(
    async (isInitialLoad) => {
      setIsLoading(true)
      try {
        const data = await apiServiceRules.getServiceRules(resourceOwner.id, service.id)

        setServiceRules(orderBy(data, ['createdAt', 'startAt'], ['desc', 'desc']))

        // The initial load needs to fetch also regulators and sections.
        if (isInitialLoad) {
          loadRegulators()
        } else {
          setIsLoading(false)
        }
      } catch (error) {
        message.error(intl.formatMessage({ id: 'app.notification.error.get-rules' }))
        logExceptionInSentry(error)
      }
    },
    [intl, loadRegulators, resourceOwner.id, service.id],
  )

  useEffect(() => {
    loadServiceRules(true)
  }, [loadServiceRules])

  const filteredServiceRules = useMemo(() => {
    const filteredByDate = filterDate
      ? serviceRules.filter((serviceRule) => {
          let startAtLocal = moment.utc(serviceRule.startAt).toDate()
          startAtLocal = moment(startAtLocal).format()
          return moment(filterDate).isSame(startAtLocal, 'day') || moment(filterDate).isSame(serviceRule.endAt, 'day')
        })
      : [...serviceRules]

    return filterResource !== getDefaultResourceFilterValue()
      ? filteredByDate.filter((serviceRule) => {
          if (!serviceRule.relayWires) return false

          return serviceRule.relayWires.find((serviceRuleWire) => {
            return serviceRuleWire.relayWire.name && serviceRuleWire.relayWire.name === filterResource
          })
        })
      : [...filteredByDate]
  }, [filterDate, filterResource, getDefaultResourceFilterValue, serviceRules])

  function handleAddNewButtonClick() {
    setModalServiceRule(null)
    setShowEditorModal(true)
  }

  function handleResetFilterClick() {
    setFilterDate('')
    setFilterResource(getDefaultResourceFilterValue())
  }

  function handleEditButtonClick() {
    setShowDetailsModal(false)
    setShowEditorModal(true)
  }

  function handleHideModals({ needsRefresh = false }) {
    setShowEditorModal(false)
    setShowDetailsModal(false)
    setShowScheduledShutOffsModal(false)

    if (needsRefresh) {
      loadServiceRules(false)
    }
  }

  function handleManageScheduledShutOffsButtonClick() {
    setShowDetailsModal(false)
    setShowEditorModal(false)
    setShowScheduledShutOffsModal(true)
  }

  function handleRowClick(id) {
    const foundServiceRule = serviceRules.find((serviceRule) => serviceRule.id === id)
    setModalServiceRule(foundServiceRule)
    setShowDetailsModal(true)
  }

  const limitValues = useMemo(() => limitsInfo[service.type], [service.type])

  if (isLoading) {
    return <Loading />
  }

  const resourceOptions = uniqBy([getDefaultResourceFilterValue(), ...getResources(serviceRules)])

  return (
    <div>
      <header className={styles.header}>
        <Space>
          <Button type="primary" onClick={handleAddNewButtonClick}>
            <FormattedMessage id="app.resource-owner.products-services.new-rule" />
          </Button>
          <Button disabled={plans.length === 0} onClick={handleManageScheduledShutOffsButtonClick}>
            <FormattedMessage id="app.resource-owner.products-services.manage-scheduled-shut-offs" />
          </Button>
        </Space>
        <div className={styles.filter}>
          <div>
            <DatePicker
              format={(current) => moment(current).format(DATE_FORMAT)}
              style={{ width: '100%' }}
              value={filterDate}
              onChange={setFilterDate}
            />
          </div>
          <div>
            <Select style={{ width: '100%' }} value={filterResource} onChange={setFilterResource}>
              {resourceOptions.map((resource) => (
                <Option key={resource} value={resource}>
                  {resource}
                </Option>
              ))}
            </Select>
          </div>
          <Button block type="default" onClick={handleResetFilterClick}>
            <FormattedMessage id="app.resource-owner.restrictions.reset-filter" />
          </Button>
        </div>
      </header>
      <Table
        limitValues={limitValues}
        resourceOwnerId={resourceOwner.id}
        serviceRules={filteredServiceRules}
        timeZone={resourceOwner.timeZone}
        onRowClick={handleRowClick}
        onUpdate={() => loadServiceRules(false)}
      />
      {showDetailsModal && (
        <ModalDetails
          limitValues={limitValues}
          rule={modalServiceRule}
          sections={sections}
          timeZone={resourceOwner.timeZone}
          onCloseClick={handleHideModals}
          onEditClick={handleEditButtonClick}
        />
      )}
      {showEditorModal && (
        <ModalEditor
          limitValues={limitValues}
          regulators={regulators}
          resourceOwnerId={resourceOwner.id}
          rule={modalServiceRule}
          sections={sections}
          service={service}
          timeZone={resourceOwner.timeZone}
          onHide={() => handleHideModals({ needsRefresh: true })}
        />
      )}
      {showScheduledShutOffsModal && (
        <ModalScheduledShutOffs
          plans={plans}
          resourceOwnerId={resourceOwner.id}
          serviceId={service.id}
          timeZone={resourceOwner.timeZone}
          onHide={() => handleHideModals({ needsRefresh: false })}
          onUpdate={loadPlans}
        />
      )}
    </div>
  )
}

export default ServiceRules
