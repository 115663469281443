export function extractServices(services) {
  return services
    .filter((service) => !service.isDisabled)
    .map((item) => {
      const { service } = item
      service.contractPrice = item.contractPrice
      return service
    })
}

export function getUsedRelayWiresForServices(regulators, serviceId) {
  const wires = []
  regulators.forEach((regulator) => {
    regulator.controlBoxes.forEach((controlBox) => {
      controlBox.relayWires.forEach((wire) => {
        if (wire.isUsed && wire.serviceId === serviceId) {
          wires.push(wire)
        }
      })
    })
  })
  return wires
}

export function getUsedAnalogWiresForService(analogControlPoints, serviceId) {
  const wires = []
  analogControlPoints.forEach((controlPoint) => {
    controlPoint.controlBoxes.forEach((controlBox) => {
      controlBox.analogWires.forEach((wire) => {
        if (wire.isUsed && wire.serviceId === serviceId) {
          wires.push(wire)
        }
      })
    })
  })
  return wires
}
