import { Checkbox } from 'antd'
import { FormattedMessage } from 'react-intl'

import SelectAllWiresCheckbox from 'components/ProductsServices/SelectAllWiresCheckbox'
import FormattedConsumption from 'components/util/FormattedConsumption'

const types = {
  gh_lamp_led0: 'app.resource-owner.modal.device-type.led0',
  gh_lamp_hps2: 'app.resource-owner.modal.device-type.hps2',
  gh_lamp_hps3: 'app.resource-owner.modal.device-type.hps3',
  gh_lamp_hps5: 'app.resource-owner.modal.device-type.hps5',
  gh_lamp_hps10: 'app.resource-owner.modal.device-type.hps10',
  gh_lamp_hps15: 'app.resource-owner.modal.device-type.hps15',
  gh_lamp_hps20: 'app.resource-owner.modal.device-type.hps20',
  gh_lamp_hps30: 'app.resource-owner.modal.device-type.hps30',
  gh_lamp_hps60: 'app.resource-owner.modal.device-type.hps60',
  sm_chamber0: 'app.resource-owner.modal.device-type.chamber0',
  sm_chamber3: 'app.resource-owner.modal.device-type.chamber3',
  sm_channel0: 'app.resource-owner.modal.device-type.channel0',
  sm_channel3: 'app.resource-owner.modal.device-type.channel3',
  sm_other0: 'app.resource-owner.modal.device-type.other0',
  sm_other3: 'app.resource-owner.modal.device-type.other3',
}

function getLampType(type, minOffPeriod) {
  const val = type + minOffPeriod / 60
  if (types[val] != null) {
    return <FormattedMessage id={types[val]} />
  }
  return ''
}

function getWireTotalPowerConsumption(wire) {
  if (!wire.powerMeters) {
    return 0
  }

  return wire.powerMeters.reduce((sum, powerMeter) => sum + powerMeter.verifiedCapacity, 0)
}

function RestrictionRelayWires({ isEdit, selectedRelayWires = [], sections, wires, onChange }) {
  function getSectionName(sectionId) {
    if (sectionId != null && sectionId !== '') {
      const selectedSection = sections.find((section) => section.id === Number(sectionId))
      return selectedSection ? selectedSection.name : ''
    }

    return sectionId
  }

  function handleSelectAllChanged() {
    const hasAllWiresSelected = selectedRelayWires.length === wires.length

    onChange(
      hasAllWiresSelected
        ? []
        : wires.map((wire) => ({
            relayWireId: wire.id,
            relayWire: wire,
          })),
    )
  }

  function handleChange(wire, event) {
    const newSelectedWires = event.target.checked ? addWireToSelected(wire) : removeWireFromSelected(wire)
    onChange(newSelectedWires)
  }

  function addWireToSelected(wire) {
    return [
      ...selectedRelayWires,
      {
        relayWireId: wire.id,
        relayWire: wire,
      },
    ]
  }

  function removeWireFromSelected(wire) {
    return selectedRelayWires.filter((selectedRelayWire) => selectedRelayWire.relayWire.id !== wire.id)
  }

  return (
    <table className="table table-striped modal-edit-wires">
      <thead>
        <tr>
          <th>
            <SelectAllWiresCheckbox
              isEdit={isEdit}
              selectedWires={selectedRelayWires}
              wires={wires}
              onChange={handleSelectAllChanged}
            />
          </th>
          <th>
            <FormattedMessage id="app.resource-owner.modal.group-name" />
          </th>
          <th>
            <FormattedMessage id="app.resource-owner.modal.section" />
          </th>
          <th>
            <FormattedMessage id="app.resource-owner.restrictions.lamp-type" />
          </th>
          <th>
            <FormattedMessage id="app.resource-owner.restrictions.lamp-placement" />
          </th>
          <th>
            <FormattedMessage id="app.resource-owner.modal.power" />
          </th>
        </tr>
      </thead>
      <tbody>
        {wires.map((wire) => (
          <tr key={wire.id}>
            <td>
              {isEdit && (
                <Checkbox
                  checked={selectedRelayWires.find((selectedWire) => selectedWire.relayWire.id === wire.id)}
                  onChange={(e) => handleChange(wire, e)}
                />
              )}
            </td>
            <td>{wire.name}</td>
            <td>{getSectionName(wire.sectionId)}</td>
            <td>{getLampType(wire.type, wire.minOffPeriod)}</td>
            <td>
              <LampPosition position={wire.placement} />
            </td>
            <td>
              <FormattedConsumption consumption={getWireTotalPowerConsumption(wire)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const LampPosition = ({ position }) => {
  if (position) {
    return <FormattedMessage id={`app.resource-owner.modal.lamp-position.${position}`} />
  }

  return <FormattedMessage id="app.resource-owner.modal.lamp-position.unknown" />
}

export default RestrictionRelayWires
