import momentTimezone from 'moment-timezone'
import React from 'react'

import { TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'

class formatHour extends React.Component {
  constructor(timeZone) {
    super()

    this.timeZone = timeZone
  }

  // Timestamp is epoch milliseconds (UTC)
  convertEpochTimestamp(timestamp) {
    return momentTimezone.tz(timestamp, this.timeZone).startOf('hour').valueOf()
  }

  convertToUserDateTime(timestamp) {
    const hour = momentTimezone.tz(timestamp, this.timeZone).format(TIME_WITHOUT_SECONDS_FORMAT)
    return <span>{hour}</span>
  }
}

export default formatHour
