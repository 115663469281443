import { ConfigProvider } from 'antd'

import { SympowerTheme } from './config/sympower'
import { HellemansConsultingTheme } from './config/hellemans'
import { Theme } from './theme'

let currentTheme: Theme
const availableThemes: Theme[] = [SympowerTheme, HellemansConsultingTheme]
const root = document.documentElement

function addTheme(theme: Theme) {
  availableThemes.push(theme)
}

function setCurrentTheme(themeName: string | null) {
  let theme = availableThemes.find((t) => t.name.toLowerCase() === themeName?.toLowerCase())
  if (theme) {
    load(theme)
    currentTheme = theme
  }
}

function load(theme: Theme) {
  //Colors
  setThemeColors(theme)

  //Document Head
  setDocumentHead(theme)

  //Font Family
  setFontFamily(theme)
}

function setThemeColors(theme: Theme) {
  //Color Palette
  root.style.setProperty('--primary-color', theme.colorPalette.primaryColor)
  root.style.setProperty('--secondary-color', theme.colorPalette.secondaryColor)
  root.style.setProperty('--success-color', theme.colorPalette.successColor)
  root.style.setProperty('--warning-color', theme.colorPalette.warningColor)
  root.style.setProperty('--error-color', theme.colorPalette.errorColor)
  root.style.setProperty('--background-color', theme.colorPalette.backgroundColor)

  //Dashboard
  root.style.setProperty('--dashboard-overlay-color', theme.dashboardConfig.backgroundOverlayColor)
  root.style.setProperty('--dashboard-text-color', theme.dashboardConfig.textColor)
  root.style.setProperty('--dashboard-background', theme.dashboardConfig.background)

  ConfigProvider.config({
    theme: {
      primaryColor: theme.colorPalette.primaryColor,
      infoColor: theme.colorPalette.primaryColor,
      successColor: theme.colorPalette.successColor,
      warningColor: theme.colorPalette.warningColor,
      errorColor: theme.colorPalette.errorColor,
    },
  })
}

function setFontFamily(theme: Theme) {
  function loadFontLink(url: string) {
    let fontLink = document.createElement('link')
    fontLink.href = url
    fontLink.rel = 'stylesheet'
    fontLink.type = 'text/css'
    document.head.appendChild(fontLink)
  }

  loadFontLink(theme.fontFamily.primaryFontFamilyUrl)
  loadFontLink(theme.fontFamily.accentFontFamilyUrl)
  root.style.setProperty('--primary-typography', theme.fontFamily.primaryFontFamilyName)
  root.style.setProperty('--accent-typography', theme.fontFamily.accentFontFamilyName)
}

function setDocumentHead(theme: Theme) {
  function setFavicon() {
    let faviconPath = theme.logo.faviconPath
    let favicon = document.querySelector('[rel=icon]')

    if (favicon != null) {
      favicon.setAttribute('href', faviconPath)
    } else {
      let faviconLink = document.createElement('link')
      faviconLink.href = faviconPath
      faviconLink.rel = 'icon'
      document.head.appendChild(faviconLink)
    }
  }

  setFavicon()
  document.title = theme.name + ' - Resource Owner'
}

export { currentTheme, setCurrentTheme, addTheme }
