/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { DATE_TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'

const FormattedRestrictionStatus = ({ activeAt, status }) => {
  if (!status) {
    return null
  }
  if (status !== 'waiting') {
    return <FormattedMessage id={`app.resource-owner.restrictions.status.${status}`} />
  }
  if (!activeAt) {
    return <FormattedMessage id="app.resource-owner.restrictions.status.waiting" />
  }

  return (
    <span>
      <FormattedMessage
        id="app.resource-owner.restrictions.status.waiting-will-become-active"
        values={{ activeAtDateTime: moment(activeAt).format(DATE_TIME_WITHOUT_SECONDS_FORMAT) }}
      />
    </span>
  )
}

export default FormattedRestrictionStatus
