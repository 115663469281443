import { Tag } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'

import styles from './Tag.module.css'

const { CheckableTag } = Tag

function EveryDayTag({ selectedDays, handleEveryDayChange, readOnly }) {
  if (!readOnly) {
    return (
      <CheckableTag
        aria-label="Every day tag"
        key="everyday"
        checked={selectedDays.length === 0}
        className={styles.tag}
        onChange={(checked) => handleEveryDayChange(checked)}
      >
        <FormattedMessage id="app.resource-owner.restrictions.recurrence.every-day" />
      </CheckableTag>
    )
  }

  if (selectedDays.length === 0) {
    return (
      <Tag key="everyday" className={styles.tag} icon={<CheckCircleOutlined />} aria-label="Every day tag">
        <FormattedMessage id="app.resource-owner.restrictions.recurrence.every-day" />
      </Tag>
    )
  }

  return (
    <Tag key="everyday" className={styles.unselectedTag} aria-label="Every day tag">
      <FormattedMessage id="app.resource-owner.restrictions.recurrence.every-day" />
    </Tag>
  )
}

export default EveryDayTag
