import { useEffect } from 'react'
import { Route } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { useEnvironment } from 'contexts/environment-context'

function TrackedRoute(props: any) {
  const { name } = useEnvironment()

  useEffect(() => {
    if (name && name === 'production') {
      const page = `/partner${props.location.pathname}`
      ReactGA.send({ hitType: 'pageview', page })
    }
  }, [name, props.location.pathname])

  return <Route {...props} />
}

export default TrackedRoute
