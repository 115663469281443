import { Authenticator } from '@aws-amplify/ui-react'
import { currentTheme } from 'theme/theme-loader'

import styles from './login.module.css'

function Login() {
  const authenticatorComponents = {
    Header() {
      return (
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={currentTheme.logo.coloredLogoPath} alt={currentTheme.logoAltText} />
        </div>
      )
    },
  }

  return <Authenticator hideSignUp components={authenticatorComponents} />
}

export default Login
