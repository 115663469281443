import axios from 'axios'

export function getRestrictions(resourceOwnerId) {
  return axios.get(`/resourceOwners/${resourceOwnerId}/restrictions`)
}

export function deleteRestriction(resourceOwnerId, restrictionId) {
  return axios.delete(`/resourceOwners/${resourceOwnerId}/restrictions/${restrictionId}`)
}

export function activate(resourceOwnerId, restrictionId) {
  return axios.post(`/resourceOwners/${resourceOwnerId}/restrictions/${restrictionId}/activate`)
}

export function deactivate(resourceOwnerId, restrictionId) {
  return axios.post(`/resourceOwners/${resourceOwnerId}/restrictions/${restrictionId}/deactivate`)
}

export function save(resourceOwnerId, restriction) {
  if (restriction.id != null) {
    return axios.put(`/resourceOwners/${resourceOwnerId}/restrictions/${restriction.id}`, restriction)
  }
  return axios.post(`/resourceOwners/${resourceOwnerId}/restrictions`, restriction)
}

export function saveRestrictionRelayWire(resourceOwnerId, restrictionId, relayWireId) {
  return axios.post(`/resourceOwners/${resourceOwnerId}/restrictions/${restrictionId}/relayWires/${relayWireId}`)
}

export function deleteRestrictionRelayWire(resourceOwnerId, restrictionId, relayWireId) {
  return axios.delete(`/resourceOwners/${resourceOwnerId}/restrictions/${restrictionId}/relayWires/${relayWireId}`)
}
