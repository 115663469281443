const backendPath = process.env.REACT_APP_BACKEND_PATH
const backendPort = process.env.REACT_APP_BACKEND_PORT

export function getBackendUrl(protocol = window.location.protocol) {
  const url = new URL(protocol + window.location.hostname)
  url.pathname = backendPath
  if (backendPort) {
    url.port = backendPort
  }
  return url.href
}

const config = {
  getWebServiceUrl() {
    return getBackendUrl()
  },
}

export default config
