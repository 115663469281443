import { _1MW } from 'constants/units'

export function convertToDecimalPrice(price) {
  if (price === 0) return 0
  return parseFloat((price / 100).toFixed(4))
}

export function convertToMegawatts(watts) {
  if (watts === 0) return 0
  return parseFloat((watts / _1MW).toFixed(3))
}
