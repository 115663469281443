/* eslint-disable max-len */
import defaultMessages from './en'

const messages = {
  ...defaultMessages,
  'app.login.logout': 'Logga ut',

  'app.edit': 'REDIGERA',
  'app.save': 'SPARA',
  'app.cancel': 'TILLBAKA',
  'app.close': 'STÄNG',
  'app.ok': 'OK',
  'app.delete': 'RADERA',

  'app.resource-owner.no-data-to-display': 'Det finns ingen information att visa.',

  'app.resource-owner.navbar.dashboard': 'Hem',
  'app.resource-owner.navbar.activationlog': 'Aktiveringslogg',
  'app.resource-owner.navbar.restrictions': 'Begränsningar &',
  'app.resource-owner.navbar.products-services': 'Produkter & tjänster',
  'app.resource-owner.navbar.revenues': 'Inkomster',
  'app.resource-owner.navbar.settings': 'Inställningar',
  'app.resource-owner.products-services.navbar.fcrd': 'Störningsreserv',
  'app.resource-owner.products-services.navbar.max-price': 'Spot-pris begränsning',
  'app.resource-owner.products-services.navbar.fcrn': 'Normaldriftsreserv',
  'app.resource-owner.products-services.contract-date': 'Kontraktdatum',
  'app.resource-owner.products-services.shut-offs': 'Shut offs',

  'app.resource-owner.dashboard.current-power-consumption': 'Elförbrukning',
  'app.resource-owner.dashboard.graph.power-consumption': 'Elförbrukning',
  'app.resource-owner.dashboard.graph.time-period': 'Tidsperiod',
  'app.resource-owner.dashboard.graph.max-consumption': 'Största förbrukningen',
  'app.resource-owner.dashboard.graph.average-consumption': 'Genomsnittlig förbrukning',
  'app.resource-owner.dashboard.graph.min-consumption': 'Lägsta förbrukningen',
  'app.resource-owner.dashboard.phase': 'Fas',

  'app.resource-owner.modal.affected-resources': 'PÅVERKADE RESURSER',
  'app.resource-owner.modal.group-name': 'Namn',
  'app.resource-owner.modal.section': 'Sektion',
  'app.resource-owner.modal.lamp-position.top': 'Tak',
  'app.resource-owner.modal.lamp-position.between': 'Mellan',
  'app.resource-owner.modal.lamp-position.all': 'Alla',
  'app.resource-owner.modal.lamp-position.unknown': 'Okänd',
  'app.resource-owner.modal.power': 'Effekt',
  'app.resource-owner.modal.device-type.led0': 'LED (0 min)',
  'app.resource-owner.modal.device-type.hps2': 'HPS (2 min)',
  'app.resource-owner.modal.device-type.hps3': 'HPS (3 min)',
  'app.resource-owner.modal.device-type.hps5': 'HPS (5 min)',
  'app.resource-owner.modal.device-type.hps10': 'HPS (10 min)',
  'app.resource-owner.modal.device-type.hps15': 'HPS (15 min)',
  'app.resource-owner.modal.device-type.hps20': 'HPS (20 min)',
  'app.resource-owner.modal.device-type.hps30': 'HPS (30 min)',
  'app.resource-owner.modal.device-type.hps60': 'HPS (60 min)',
  'app.resource-owner.modal.device-type.chamber0': 'Kammare (0 min)',
  'app.resource-owner.modal.device-type.chamber3': 'Kammare (3 min)',
  'app.resource-owner.modal.device-type.channel0': 'Kanal (0 min)',
  'app.resource-owner.modal.device-type.channel3': 'Kanal (3 min)',
  'app.resource-owner.modal.device-type.other0': 'Annat (0 min)',
  'app.resource-owner.modal.device-type.other3': 'Annat (3 min)',

  'app.resource-owner.more-results': '{count} till',

  'app.resource-owner.activation-event-log.event-started': 'Händelsen började',
  'app.resource-owner.activation-event-log.event-ended': 'Händelsen slutade',
  'app.resource-owner.activation-event-log.event-duration': 'Längd',
  'app.resource-owner.activation-event-log.service': 'Produkt',
  'app.resource-owner.activation-event-log.modal.activated-at': 'Började',
  'app.resource-owner.activation-event-log.modal.deactivated-at': 'Slutade',
  'app.resource-owner.activation-event-log.modal.activation-duration': 'Längd',

  'app.resource-owner.restrictions.activate': 'STARTA',
  'app.resource-owner.restrictions.deactivate': 'STOPPA',
  'app.resource-owner.restrictions.start-date': 'Start datum',
  'app.resource-owner.restrictions.end-date': 'Slut datum',
  'app.resource-owner.restrictions.time-of-day': 'Tidpunkt på dygnet',
  'app.resource-owner.restrictions.resources': 'Grupperna',
  'app.resource-owner.restrictions.resources-no-name': '{count, plural, one{# grupp} other{# grupper}}',
  'app.resource-owner.restrictions.updated-on': 'Uppdaterad',
  'app.resource-owner.from': 'Från',
  'app.resource-owner.to': 'Till',
  'app.resource-owner.status': 'Status',
  'app.resource-owner.restrictions.status.active': 'Startad',
  'app.resource-owner.restrictions.status.deactivated': 'Stoppad',
  'app.resource-owner.restrictions.status.expired': 'Utgången',
  'app.resource-owner.restrictions.status.waiting': 'Väntar',
  'app.resource-owner.restrictions.status.waiting-will-become-active': 'Väntar, startas {activeAtDateTime}',
  'app.resource-owner.restrictions.new-restriction': 'NY BEGRÄNSNING',
  'app.resource-owner.restrictions.lamp-type': 'Typ',
  'app.resource-owner.restrictions.lamp-placement': 'Placering',
  'app.resource-owner.restrictions.choose-affected-lamp-groups': 'VÄLJ PÅVERKADE GRUPPER',
  'app.resource-owner.restrictions.end-date-before-start-date-error': 'Slutdatum kan inte vara före startdatum!',
  'app.resource-owner.restrictions.end-time-before-start-time-error': 'Starttidpunkt kan inte vara före sluttidpunkt!',
  'app.resource-owner.restrictions.select-group': 'Välj grupp',
  'app.resource-owner.restrictions.reset-filter': 'ÅTERSTÄLL FILTER',
  'app.resource-owner.restrictions.frequency.does_not_repeat': 'Upprepas inte',
  'app.resource-owner.restrictions.frequency.repeat_daily': 'Upprepa dagligen',
  'app.resource-owner.restrictions.end-time': 'Sluttid',
  'app.resource-owner.restrictions.start-time': 'Starttid',
  'app.resource-owner.restrictions.all-day': 'Hela dagen',
  'app.resource-owner.restrictions.date-and-time': 'Datum och tid',

  'app.resource-owner.products-services.new-rule': 'NY REGEL',
  'app.resource-owner.products-services.manage-scheduled-shut-offs': 'UPPKOMMANDE SLÄCKNINGAR',
  'app.resource-owner.products-services.filter-rule': 'Sortera regler:',
  'app.resource-owner.products-services.modal.show-advanced-options': 'Visa avancerade alternativ',
  'app.resource-owner.products-services.modal.hide-advanced-options': 'Dölj avancerade alternativ',
  'app.resource-owner.products-services.modal.limit.price': 'Pris',
  'app.resource-owner.products-services.modal.limit.consumption': 'Konsumtion',
  'app.resource-owner.products-services.modal.help.text.euro.sign': '€',
  'app.resource-owner.products-services.modal.help.text.mw': 'MW',
  'app.resource-owner.products-services.modal.off.time.max.length': 'Längsta släcktiden före tändning',
  'app.resource-owner.products-services.modal.on.time.min.length': 'On-time min length',
  'app.resource-owner.products-services.modal.max.turn.offs.per.day': 'Max turn-offs per day',
  'app.resource-owner.products-services.modal.max.turn.offs.per.week': 'Max turn-offs per week',
  'app.resource-owner.products-services.modal.help.text.hours': 'timmar',
  'app.resource-owner.products-services.modal.help.text.times': 'gånger',
  'app.resource-owner.products-services.modal.scheduled-shut-offs': 'UPPKOMMANDE SLÄCKNINGAR',
  'app.resource-owner.products-services.modal.delete-restriction-confirmation':
    'Är du säkert på att du vill radera denna begränsning?',
  'app.resource-owner.products-services.modal.delete-restriction-yes': 'Ja, radera begränsningen',
  'app.resource-owner.products-services.limit-price': 'Regelns prisgräns',
  'app.resource-owner.products-services.actual-price': 'Realiserade priset',
  'app.resource-owner.products-services.enabled': 'Aktiv',
  'app.resource-owner.products-services.cancelled': 'Deaktiverad',

  'app.resource-owner.settings.missing-bank-details':
    'Ni har inte uppgett ert kontonummer som vi behöver för att kunna betala er. Var god och fyll in ert kontonummer.',
  'app.resource-owner.settings.e-mail-address': 'E-postadress för notifieringar',
  'app.resource-owner.settings.mobile-number': 'Mobiltelefonnummer för notifieringar',
  'app.resource-owner.settings.mobile-number.example': 'e.g. +358 45 123 4567',
  'app.resource-owner.settings.preferred-language': 'Språk',
  'app.resource-owner.settings.user-information': 'ANVÄNDARINFORMATION OCH INSTÄLLNINGAR',
  'app.resource-owner.settings.iban': 'Bankkontonummer (IBAN)',
  'app.resource-owner.settings.iban-holder': 'Innehavare av kontot (företagets namn)',
  'app.resource-owner.settings.notification-preferences': 'Notifieringar',
  'app.resource-owner.settings.notification-preferences.sms': 'SMS',
  'app.resource-owner.settings.notification-preferences.email': 'E-post',
  'app.resource-owner.notification-preferences.no-email-address':
    'En e-postadress måste anges för att kunna aktivera e-postnotifiering',
  'app.resource-owner.notification-preferences.no-mobile-number':
    'Ett telefonnumer måste anges för att kunna aktivera SMS-notifiering',

  'app.resource-owner.revenues.export-as-csv': 'Spara som CSV-fil',
  'app.resource-owner.revenues.column.period': 'Tidsperiod',
  'app.resource-owner.revenues.column.capacity': 'Konsumtion (MWh)',
  'app.resource-owner.revenues.column.sold-capacity': 'Såld konsumtion (MWh)',
  'app.resource-owner.revenues.column.contract-price': 'Kontraktspris',
  'app.resource-owner.revenues.column.revenue': 'Inkomst',
  'app.resource-owner.revenue.month.1': 'Januari',
  'app.resource-owner.revenue.month.2': 'Februari',
  'app.resource-owner.revenue.month.3': 'Mars',
  'app.resource-owner.revenue.month.4': 'April',
  'app.resource-owner.revenue.month.5': 'Maj',
  'app.resource-owner.revenue.month.6': 'Juni',
  'app.resource-owner.revenue.month.7': 'Juli',
  'app.resource-owner.revenue.month.8': 'Augusti',
  'app.resource-owner.revenue.month.9': 'September',
  'app.resource-owner.revenue.month.10': 'Oktober',
  'app.resource-owner.revenue.month.11': 'November',
  'app.resource-owner.revenue.month.12': 'December',

  'app.notification.error.login': 'Fel vid inloggningen',
  'app.notification.error.update-token': 'Fel vid uppdatering av identifierare',
  'app.notification.error.session-error': 'Fel i sessionen',
  'app.notification.error.get-power-consumption': 'Fel vid sökning av förbrukningsinformation',
  'app.notification.error.get-restrictions': 'Fel vid sökning av begränsningar',
  'app.notification.error.get-plans': 'Fel vid uppsökning av uppkommande släckningar',
  'app.notification.success.save-restriction': 'Sparning av begränsningen lyckades',
  'app.notification.error.save-restriction': 'Fel vid sparning av begränsning',
  'app.notification.error.get-sections': 'Fel vid sökning av sektioner',
  'app.notification.success.user-save': 'Sparning av användaren lyckades',
  'app.notification.error.user-save': 'Fel vid sparning av användare',
  'app.notification.error.get-resource-owner': 'Fel vid sökning av användare',
  'app.notification.error.get-lamp-resource-owners': 'Fel vid sökning av grupper',
  'app.notification.error.get-activation-events': 'Fel vid sökning av aktiveringsloggar',

  'app.notification.error.activate-restriction': 'Fel vid aktivering av begränsningen',
  'app.notification.success.activate-restriction': 'Aktiveringen av begränsningen lyckades',
  'app.notification.error.deactivate-restriction': 'Fel vid deaktivering av begränsningen',
  'app.notification.success.deactivate-restriction': 'Deaktivering av begränsningen lyckades',
  'app.notification.error.save-restriction-relaywires': 'Fel vid sparning av begränsningens reläer',
  'app.notification.error.delete-restriction-relaywires': 'Fel vid radering av begränsningens reläer',
  'app.notification.success.delete-restriction': 'Raderandet av begränsningen lyckades',
  'app.notification.error.delete-restriction': 'Ett fel inträffade vid raderandet av begränsningen',
  'app.notification.error.get-power-consumption-graph': 'Fel vid hämtning av konsumtionsgraf',
  'app.notification.error.loading-revenues': 'Fel vid inhämtning av inkomster',
  'app.notification.error.get-services': 'Fel vid hämtning av tjänster',
  'app.notification.error.get-rules': 'Fel vid hämtning av regler',
  'app.notification.error.save-rule': 'Fel vid sparning av regel',
  'app.notification.success.save-rule': 'Sparningen av regeln lyckades',
  'app.notification.error.activate-rule': 'Fel vid aktivering av regeln',
  'app.notification.success.activate-rule': 'Aktiveringen av regeln lyckades',
  'app.notification.error.deactivate-rule': 'Fel vi deaktiveringen av regeln',
  'app.notification.success.deactivate-rule': 'Deaktiveringen av regeln lyckades',
  'app.notification.error.save-rule-relaywires': 'Fel vid sparning av regelns reläer',
  'app.notification.error.delete-rule-resource': 'Fel vid radering av regelns reläer',
  'app.notification.error.rule.empty-price-limit': 'Priset kan inte vara tomt',
  'app.notification.error.rule.empty-consumption-limit': 'Konsumtionen skall inte vara tom',
  'app.notification.error.enable-plan': 'Fel vid aktivering av regeln',
  'app.notification.success.enable-plan': 'Aktivering av regeln lyckades',
  'app.notification.error.cancel-plan': 'Fel vid deaktivering av regeln',
  'app.notification.success.cancel-plan': 'Deaktiveringen av regeln lyckades',
  'app.notification.error.add.resource': 'Reläet har redan en regel för denna tidsperiod: {addedInfo}',
  'app.notification.error.old-password-invalid': 'Det gamla lösenordet du anget är inkorrekt',

  'app.information-banner.flexportal-is-here': 'Vår nya FlexPortal är här 🎉',
  'app.information-banner.flexportal-is-here-description':
    ', och erbjuder en snabbare, mer intuitiv upplevelse. I september kommer du automatiskt att omdirigeras, men du kan börja utforska de nya funktionerna nu.',
  'app.information-banner.try-new-flexportal': 'Prova nya FlexPortal',

  'app.validation.email.wrong-format': 'Ogiltigt format för e-post',

  'server.error.token-expired': 'Sessionen är inte längre tillgänglig',
  'server.error.invalid-token': 'Fel sessionsidentifierare',
  'server.error.unexpected-auth-error': 'Oförväntat fel med sessionen',
  'server.error.incorrect-username-password': 'Fel användarnamn eller lösenord',
  'server.error.user-deactivated': 'Användaren har deaktiverats',
  'server.error.fill-all-password-fields': 'Alla lösenordsfält har inte blivit fyllda',
  'server.error.passwords-do-not-match': 'Lösenorden matchar inte',
  'server.error.old-password-invalid': 'Gamla lösenordet är fel',
  'server.error.password-min-length': 'Lösenordet måste innehålla i alla fall 6 tecken',
  'server.error.access-denied': 'Tillträde beviljas ej',
  'server.error.validation-failed': 'Validering misslyckades',
  'server.error.incorrect-username-or-password': 'Användarnamn och/eller lösenord är inkorrekt',
}

export default messages
