import axios from 'axios'

import { ResourceOwner } from 'contexts/resource-owner-context'

export function getCurrentResourceOwner(): Promise<ResourceOwner> {
  return axios.get('/resourceOwners/current')
}

export function getCurrentResourceOwnerWithUuid(resourceOwnerUuid: string): Promise<ResourceOwner> {
  return axios.get(`/resourceOwners/current/${resourceOwnerUuid}`)
}

export function putResourceOwner(resourceOwner: any) {
  return axios.put(`/resourceOwners/${resourceOwner.id}`, resourceOwner)
}

export function getServices(resourceOwnerId: number) {
  return axios.get(`/resourceOwners/${resourceOwnerId}/services`)
}
