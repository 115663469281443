import { Spin } from 'antd'

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
  },
}

const FullPageSpinner = () => (
  <div style={styles.container}>
    <Spin />
  </div>
)

export default FullPageSpinner
