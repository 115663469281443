import { Select } from 'antd'
import moment from 'moment'

import { TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'
import { getTimes } from 'util/time-utils'

const HOUR_FORMAT = 'HH:mm'

type TimeStartPickerProps = {
  disabled: boolean
  ptuRange: number
  value: string
  onChange: (newValue: string) => void
}

function TimeStartPicker({ disabled, ptuRange, value, onChange }: TimeStartPickerProps) {
  return (
    <Select aria-label="Start time select" disabled={disabled} value={value} onChange={onChange}>
      {getTimes({ isStartTime: true, ptuRange }).map((time) => (
        <Select.Option key={time} value={time}>
          {moment(time, HOUR_FORMAT).format(TIME_WITHOUT_SECONDS_FORMAT)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default TimeStartPicker
