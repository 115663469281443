import { FormattedMessage, FormattedNumber } from 'react-intl'

const PRICE_PRECISION = 10000
const CONSUMPTION_PRECISION = 1000000

interface PlanValueProps {
  serviceType: string
  value: number
}

export default function PlanValue({ serviceType, value }: PlanValueProps) {
  const precision = serviceType === 'max-price' ? PRICE_PRECISION : CONSUMPTION_PRECISION
  const message =
    serviceType === 'max-price'
      ? 'app.resource-owner.products-services.modal.help.text.mw'
      : 'app.resource-owner.products-services.modal.help.text.euro.sign'
  const calculatedValue = value / precision

  return (
    <p>
      <FormattedNumber value={calculatedValue} maximumFractionDigits={2} />
      <FormattedMessage id={message} />
    </p>
  )
}
