import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD'

export function getSemesterForDate(date) {
  const dateMonth = parseInt(moment(date).format('MM'), 10)
  const dateYear = moment(date).format('YYYY')

  // First half of the year.
  if (dateMonth < 7) return `${dateYear}-1`

  // Second half of the year.
  return `${dateYear}-2`
}

export function getAllSemestersBetweenDates(startDate, endDate) {
  const semesters = []
  let date = startDate

  while (date < moment(endDate).format(DATE_FORMAT)) {
    semesters.push(getSemesterForDate(date))
    date = moment(date).add(6, 'month').format(DATE_FORMAT)
    if (moment(date).isSameOrAfter(endDate)) {
      semesters.push(getSemesterForDate(endDate))
    }
  }
  return [...new Set(semesters)]
}

export function getSemesterStartEndDate(value) {
  const [year, semester] = value.split('-')

  if (parseInt(semester, 10) === 1) {
    return {
      startDate: moment.utc(`${year}-01-01 00:00:00`).toISOString(),
      endDate: moment.utc(`${year}-07-01 00:00:00`).toISOString(),
    }
  }

  return {
    startDate: moment.utc(`${year}-07-01 00:00:00`).toISOString(),
    endDate: moment.utc(`${parseInt(year, 10) + 1}-01-01 00:00:00`).toISOString(),
  }
}
