import { message } from 'antd'
import { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import moment from 'moment'

import { useAuth } from 'contexts/auth-context'
import * as apiResourceOwner from 'api/resource-owner'
import { isApiError, logExceptionInSentry, logMessageInSentry } from 'util/error-message'

export type ResourceOwner = {
  id: number
  contractPrice: number
  iban: string
  ibanHolder: string
  isFinancialVisible: boolean
  isIbanNeeded: boolean
  numeralLanguage: string
  uiLanguage: string
  username: string
}

type MaybeResourceOwner = ResourceOwner | null

export interface ResourceOwnerContextInterface {
  resourceOwner: MaybeResourceOwner
  setResourceOwner: (ro: MaybeResourceOwner) => void
  loadCurrentResourceOwner: () => Promise<void>
  loadCurrentResourceOwnerWithUuid: (resourceOwnerUuid: string) => Promise<void>
  saveResourceOwner: (r: Partial<ResourceOwner>) => Promise<void>
}

const ResourceOwnerContext = createContext<ResourceOwnerContextInterface>({
  resourceOwner: null,
  setResourceOwner: () => {},
  loadCurrentResourceOwner: () => Promise.resolve(),
  loadCurrentResourceOwnerWithUuid: () => Promise.resolve(),
  saveResourceOwner: () => Promise.resolve(),
})

interface ResourceOwnerProviderProps {
  initialResourceOwner?: ResourceOwner
  children: ReactNode
}

function setUserLocale(locale = 'en') {
  moment.locale(locale)
}

export function ResourceOwnerProvider({ initialResourceOwner, ...props }: ResourceOwnerProviderProps) {
  const { isLogged, loggedInUser, logout } = useAuth()
  const [resourceOwner, setResourceOwner] = useState<MaybeResourceOwner>(initialResourceOwner || null)

  useEffect(() => {
    if (!isLogged) {
      setResourceOwner(null)
    }
  }, [isLogged])

  async function loadCurrentResourceOwner() {
    try {
      // conversion to unknown is needed because we are modifying
      // the axios response and this is messing with the types
      const data = await apiResourceOwner.getCurrentResourceOwner()

      setResourceOwner(data)
      setUserLocale(data.numeralLanguage)
    } catch (error: any) {
      if (
        isApiError(error) &&
        error.response.data.errorCode === 'no-allowed-resource-owner-ids-found-for-given-resource-owner'
      ) {
        message.error('The user is marked as restricted but currently there is no resource owner selected')
        logMessageInSentry(
          `User ${loggedInUser?.username} is marked as restricted, but no resource owners are selected`,
          {
            extra: { user: loggedInUser },
          },
        )
      } else {
        message.error('Error getting current resource owner')
        logExceptionInSentry(error)
      }
      logout()
    }
  }

  async function loadCurrentResourceOwnerWithUuid(resourceOwnerUuid: string) {
    try {
      const data = await apiResourceOwner.getCurrentResourceOwnerWithUuid(resourceOwnerUuid)

      setResourceOwner(data)
      setUserLocale(data.numeralLanguage)
    } catch (error: any) {
      logExceptionInSentry(error)
    }
  }

  async function saveResourceOwner(resourceOwner: Partial<ResourceOwner>) {
    try {
      // conversion to unknown is needed because we are modifying
      // the axios response and this is messing with the types
      const data = (await apiResourceOwner.putResourceOwner(resourceOwner)) as unknown as ResourceOwner

      setResourceOwner(data)
      setUserLocale(data.numeralLanguage)
    } catch (error: any) {
      logExceptionInSentry(error)
      throw error
    }
  }

  return (
    <ResourceOwnerContext.Provider
      value={{
        resourceOwner,
        setResourceOwner,
        loadCurrentResourceOwner,
        loadCurrentResourceOwnerWithUuid,
        saveResourceOwner,
      }}
      {...props}
    />
  )
}

export const useResourceOwner = () => useContext(ResourceOwnerContext)
