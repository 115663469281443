/* eslint-disable max-len */
import defaultMessages from './en'

const messages = {
  ...defaultMessages,
  'app.login.logout': 'Kirjaudu ulos',

  'app.edit': 'MUOKKAA',
  'app.save': 'TALLENNA',
  'app.cancel': 'PERUUTA',
  'app.close': 'SULJE',
  'app.ok': 'OK',
  'app.delete': 'POISTA',

  'app.resource-owner.no-data-to-display': 'Ei ole näytettäviä tietoja.',

  'app.resource-owner.navbar.dashboard': 'Alkuun',
  'app.resource-owner.navbar.activationlog': 'Aktivointihistoria',
  'app.resource-owner.navbar.restrictions': 'Rajoitukset',
  'app.resource-owner.navbar.products-services': 'Tuotteet ja palvelut',
  'app.resource-owner.navbar.revenues': 'Tulot',
  'app.resource-owner.navbar.settings': 'Asetukset',
  'app.resource-owner.products-services.navbar.fcrd': 'Häiriöreservi',
  'app.resource-owner.products-services.navbar.max-price': 'Spot-hinta raja',
  'app.resource-owner.products-services.navbar.fcrn': 'Käyttöreservi',
  'app.resource-owner.products-services.contract-date': 'Sopimuspäivämäärä',
  'app.resource-owner.products-services.shut-offs': 'Shut offs',

  'app.resource-owner.dashboard.current-power-consumption': 'Tämän hetkinen sähkökulutus',
  'app.resource-owner.dashboard.graph.power-consumption': 'Sähkönkulutus',
  'app.resource-owner.dashboard.graph.time-period': 'Ajanjakso',
  'app.resource-owner.dashboard.graph.max-consumption': 'Korkein kulutus',
  'app.resource-owner.dashboard.graph.average-consumption': 'Keskimääräinen kulutus',
  'app.resource-owner.dashboard.graph.min-consumption': 'Pienin kulutus',
  'app.resource-owner.dashboard.phase': 'Vaihe',

  'app.resource-owner.modal.affected-resources': 'VAIKUTUSTA RESURSSEIHIN',
  'app.resource-owner.modal.group-name': 'Nimi',
  'app.resource-owner.modal.section': 'Alue',
  'app.resource-owner.modal.lamp-position.top': 'Katto',
  'app.resource-owner.modal.lamp-position.between': 'Väli',
  'app.resource-owner.modal.lamp-position.all': 'Kaikki',
  'app.resource-owner.modal.lamp-position.unknown': 'Ei tiedossa',
  'app.resource-owner.modal.power': 'Teho',
  'app.resource-owner.modal.device-type.led0': 'LED (0 min)',
  'app.resource-owner.modal.device-type.hps2': 'HPS (2 min)',
  'app.resource-owner.modal.device-type.hps3': 'HPS (3 min)',
  'app.resource-owner.modal.device-type.hps5': 'HPS (5 min)',
  'app.resource-owner.modal.device-type.hps10': 'HPS (10 min)',
  'app.resource-owner.modal.device-type.hps15': 'HPS (15 min)',
  'app.resource-owner.modal.device-type.hps20': 'HPS (20 min)',
  'app.resource-owner.modal.device-type.hps30': 'HPS (30 min)',
  'app.resource-owner.modal.device-type.hps60': 'HPS (60 min)',
  'app.resource-owner.modal.device-type.chamber0': 'Kamari (0 min)',
  'app.resource-owner.modal.device-type.chamber3': 'Kamari (3 min)',
  'app.resource-owner.modal.device-type.channel0': 'Kanava (0 min)',
  'app.resource-owner.modal.device-type.channel3': 'Kanava (3 min)',
  'app.resource-owner.modal.device-type.other0': 'Muu (0 min)',
  'app.resource-owner.modal.device-type.other3': 'Muu (3 min)',

  'app.resource-owner.more-results': '{count} lisää',

  'app.resource-owner.activation-event-log.event-started': 'Tapahtuma alkoi',
  'app.resource-owner.activation-event-log.event-ended': 'Tapahtuma loppui',
  'app.resource-owner.activation-event-log.event-duration': 'Kesto',
  'app.resource-owner.activation-event-log.service': 'Tuote',
  'app.resource-owner.activation-event-log.modal.activated-at': 'Alkoi',
  'app.resource-owner.activation-event-log.modal.deactivated-at': 'Loppui',
  'app.resource-owner.activation-event-log.modal.activation-duration': 'Kesto',

  'app.resource-owner.restrictions.activate': 'OTA KÄYTTÖÖN',
  'app.resource-owner.restrictions.deactivate': 'POISTA KÄYTÖSTÄ',
  'app.resource-owner.restrictions.start-date': 'Aloitus päivämäärä',
  'app.resource-owner.restrictions.end-date': 'Lopetus päivämäärä',
  'app.resource-owner.restrictions.time-of-day': 'Vuorokaudenaika',
  'app.resource-owner.restrictions.resources': 'Ryhmät',
  'app.resource-owner.restrictions.resources-no-name': '{count, plural, one{# ryhmää} other{# ryhmää}}',
  'app.resource-owner.restrictions.updated-on': 'Päivitetty',
  'app.resource-owner.from': 'Alkaen',
  'app.resource-owner.to': 'Saakka',
  'app.resource-owner.status': 'Tila',
  'app.resource-owner.restrictions.status.active': 'Käytössä',
  'app.resource-owner.restrictions.status.deactivated': 'Ei käytössä',
  'app.resource-owner.restrictions.status.expired': 'Vanhentunut',
  'app.resource-owner.restrictions.status.waiting': 'Odottaa',
  'app.resource-owner.restrictions.status.waiting-will-become-active': 'Odottaa, aktivoituu {activeAtDateTime}',
  'app.resource-owner.restrictions.new-restriction': 'UUSI RAJOITUS',
  'app.resource-owner.restrictions.lamp-type': 'Tyyppi',
  'app.resource-owner.restrictions.lamp-placement': 'Sijainti',
  'app.resource-owner.restrictions.choose-affected-lamp-groups': 'VALITSE RYHMÄT',
  'app.resource-owner.restrictions.end-date-before-start-date-error':
    'Loppupäivämäärä ei voi olla ennen alkupäivämäärää!',
  'app.resource-owner.restrictions.end-time-before-start-time-error': 'Päättymisaika ei voi olla ennen alkamisaikaa!',
  'app.resource-owner.restrictions.select-group': 'Valitse ryhmä',
  'app.resource-owner.restrictions.reset-filter': 'NOLLAA SUODATIN',
  'app.resource-owner.restrictions.frequency.does_not_repeat': 'Ei toistu',
  'app.resource-owner.restrictions.frequency.repeat_daily': 'Toista päivittäin',
  'app.resource-owner.restrictions.end-time': 'Päättymisaika',
  'app.resource-owner.restrictions.start-time': 'Aloitusaika',
  'app.resource-owner.restrictions.all-day': 'Koko päivä',
  'app.resource-owner.restrictions.date-and-time': 'Päivämäärä ja aika',

  'app.resource-owner.products-services.new-rule': 'UUSI SÄÄNTÖ',
  'app.resource-owner.products-services.manage-scheduled-shut-offs': 'TULEVAT SAMMUTUKSET',
  'app.resource-owner.products-services.filter-rule': 'Suodata sääntöjä:',
  'app.resource-owner.products-services.modal.show-advanced-options': 'Näytä lisä-asetukset',
  'app.resource-owner.products-services.modal.hide-advanced-options': 'Piilota lisä-asetukset',
  'app.resource-owner.products-services.modal.limit.price': 'Hinta',
  'app.resource-owner.products-services.modal.limit.consumption': 'Kulutus',
  'app.resource-owner.products-services.modal.help.text.euro.sign': '€',
  'app.resource-owner.products-services.modal.help.text.mw': 'MW',
  'app.resource-owner.products-services.modal.off.time.max.length': 'Pisin sammuksissa-oloaika ennen käynnistystä',
  'app.resource-owner.products-services.modal.on.time.min.length': 'Minimi päällä-oloaika ennen sammutusta',
  'app.resource-owner.products-services.modal.max.turn.offs.per.day': 'Enimmäismäärä sammutuksia päivässä',
  'app.resource-owner.products-services.modal.max.turn.offs.per.week': 'Enimmäismäärä sammutuksia viikossa',
  'app.resource-owner.products-services.modal.help.text.hours': 'tuntia',
  'app.resource-owner.products-services.modal.help.text.times': 'kertaa',
  'app.resource-owner.products-services.modal.scheduled-shut-offs': 'AJASTETUT SAMMUTUKSET',
  'app.resource-owner.products-services.modal.delete-restriction-confirmation':
    'Oletko varma että haluat poistaa tämän rajoituksen?',
  'app.resource-owner.products-services.modal.delete-restriction-yes': 'Kyllä, poista rajoitus',
  'app.resource-owner.products-services.limit-price': 'Säännön hintaraja',
  'app.resource-owner.products-services.actual-price': 'Toteutunut hinta',
  'app.resource-owner.products-services.enabled': 'Aktiivinen',
  'app.resource-owner.products-services.cancelled': 'Peruutettu',

  'app.resource-owner.settings.missing-bank-details':
    'Ette ole määrittänyt tilinumeroanne jonka tarvitsemme, jotta voimme maksaa teille. Olkaa hyvä, ja määrittäkää tilinumeronne.',
  'app.resource-owner.settings.e-mail-address': 'Sähköpostiosoite ilmoituksille',
  'app.resource-owner.settings.mobile-number': 'Matkapuhelinnumero ilmoituksille',
  'app.resource-owner.settings.mobile-number.example': 'e.g. +358 45 123 4567',
  'app.resource-owner.settings.preferred-language': 'Kieli',
  'app.resource-owner.settings.user-information': 'ASIAKASTIEDOT JA ASETUKSET',
  'app.resource-owner.settings.iban': 'Pankkitilinnumero (IBAN)',
  'app.resource-owner.settings.iban-holder': 'Tilin haltija (yrityksen nimi)',
  'app.resource-owner.settings.notification-preferences': 'Ilmoitukset',
  'app.resource-owner.settings.notification-preferences.email': 'Sähköposti',
  'app.resource-owner.settings.notification-preferences.sms': 'Tekstiviesti',
  'app.resource-owner.settings.notification-preferences.no-email-address':
    'Syötä sähköpostiosoite jotta sähköpostiilmoitukset voi aktivoida',
  'app.resource-owner.settings.notification-preferences.no-mobile-number':
    'Syötä puhelinnumero jottai SMS-ilmoitukset voi aktivoida',

  'app.resource-owner.revenues.export-as-csv': 'Tallenna CSV-muodossa',
  'app.resource-owner.revenues.column.period': 'Ajanjakso',
  'app.resource-owner.revenues.column.capacity': 'Kulutus (MWh)',
  'app.resource-owner.revenues.column.sold-capacity': 'Myyty kulutus (MWh)',
  'app.resource-owner.revenues.column.contract-price': 'Sopimuksen hinta',
  'app.resource-owner.revenues.column.revenue': 'Tulo',
  'app.resource-owner.revenue.month.1': 'Tammikuu',
  'app.resource-owner.revenue.month.2': 'Helmikuu',
  'app.resource-owner.revenue.month.3': 'Maaliskuu',
  'app.resource-owner.revenue.month.4': 'Huhtikuu',
  'app.resource-owner.revenue.month.5': 'Toukokuu',
  'app.resource-owner.revenue.month.6': 'Kesäkuu',
  'app.resource-owner.revenue.month.7': 'Heinäkuu',
  'app.resource-owner.revenue.month.8': 'Elokuu',
  'app.resource-owner.revenue.month.9': 'Syyskuu',
  'app.resource-owner.revenue.month.10': 'Lokakuu',
  'app.resource-owner.revenue.month.11': 'Marraskuu',
  'app.resource-owner.revenue.month.12': 'Joulukuu',

  'app.notification.error.login': 'Virhe kirjautuessa sisään',
  'app.notification.error.update-token': 'Virhe päivittäessä tunnistetta',
  'app.notification.error.session-error': 'Istunnossa tapahtui virhe',
  'app.notification.error.get-power-consumption': 'Virhe haettaessa sähkökulutustietoja',
  'app.notification.error.get-restrictions': 'Virhe haettaessa rajoituksia',
  'app.notification.error.get-plans': 'Virhe haettaessa ajastettuja sammutuksia',
  'app.notification.success.save-restriction': 'Rajoitus tallennettiin onnistuneesti',
  'app.notification.error.save-restriction': 'Virhe tallentaessa rajoitusta',
  'app.notification.error.get-sections': 'Virhe haettaessa alueita',
  'app.notification.success.user-save': 'Käyttäjä tallennettiin onnistuneesti',
  'app.notification.error.user-save': 'Virhe tallentaessa käyttäjää',
  'app.notification.error.get-resource-owner': 'Virhe haettaessa käyttäjää',
  'app.notification.error.get-lamp-resource-owners': 'Virhe haettaessa ryhmiä',
  'app.notification.error.get-activation-events': 'Virhe haettaessa aktivointitietoja',

  'app.notification.error.activate-restriction': 'Virhe aktivoidessa rajoitusta',
  'app.notification.success.activate-restriction': 'Rajoituksen aktivointi onnistui',
  'app.notification.error.deactivate-restriction': 'Virhe rajoituksen deaktivoinnissa',
  'app.notification.success.deactivate-restriction': 'Rajoituksen deaktivointi onnistui',
  'app.notification.error.save-restriction-relaywires': 'Virhe tallennettaessa rajoituksen relejohtoja',
  'app.notification.error.delete-restriction-relaywires': 'Virhe poistaessa rajoituksen relejohtoja',
  'app.notification.success.delete-restriction': 'Rajoituksen poistaminen onnistui',
  'app.notification.error.delete-restriction': 'Tapahtui Virhe rajoituksen poistamisessa',
  'app.notification.error.get-power-consumption-graph': 'Virhe haettaessa kulutuskuvaajaa',
  'app.notification.error.loading-revenues': 'Virhe haettaessa tuloja',
  'app.notification.error.get-services': 'Palvelun haussa on tapahtunut virhe',
  'app.notification.error.get-rules': 'Virhe haettaessa sääntöjä',
  'app.notification.error.save-rule': 'Virhe tallennettaessa sääntöä',
  'app.notification.success.save-rule': 'Sääntö tallennettiin onnistuneesti',
  'app.notification.error.activate-rule': 'Virhe säännön aktivoinnissa',
  'app.notification.success.activate-rule': 'Sääntö aktivoitiin onnistuneesti',
  'app.notification.error.deactivate-rule': 'Virhe säännön deaktivoinnissa',
  'app.notification.success.deactivate-rule': 'Sääntö deaktivointiin onnistuneesti',
  'app.notification.error.save-rule-relaywires': 'Virhe tallennettaessa säännön relejohtoja',
  'app.notification.error.delete-rule-resource': 'Virhe poistaessa säännön relejohtoja',
  'app.notification.error.rule.empty-price-limit': 'Hinta ei voi olla tyhjä',
  'app.notification.error.rule.empty-consumption-limit': 'Kulutus ei voi olla tyhjä',
  'app.notification.error.enable-plan': 'Virhe aktivoitaessa sääntöä',
  'app.notification.success.enable-plan': 'Sääntö aktivoitiin onnistuneesti',
  'app.notification.error.cancel-plan': 'Virhe peruutettaessa sääntöä',
  'app.notification.success.cancel-plan': 'Sääntö peruutettiin onnistuneesti',
  'app.notification.error.add.resource': 'Releellä on jo sääntö samalle ajanjaksolle: {addedInfo}',
  'app.notification.error.old-password-invalid': 'Vanha salasana on virheellinen',

  'app.information-banner.flexportal-is-here': 'Uusi FlexPortalimme on täällä 🎉',
  'app.information-banner.flexportal-is-here-description':
    ', tarjoten nopeamman ja intuitiivisemman käyttökokemuksen. Syyskuussa sinut ohjataan automaattisesti uudelleen, mutta voit jo nyt alkaa tutustua uusiin ominaisuuksiin.',
  'app.information-banner.try-new-flexportal': 'Kokeile uutta FlexPortalia',

  'app.validation.email.wrong-format': 'Virheellinen sähköpostiosoite',

  'server.error.token-expired': 'Istunto vanhentunut',
  'server.error.invalid-token': 'Virheellinen istuntotunniste',
  'server.error.unexpected-auth-error': 'Odottamaton virhe istunnossa',
  'server.error.incorrect-username-password': 'Väärä käyttäjätunnus tai salasana',
  'server.error.user-deactivated': 'Käyttäjä on deaktivoitu',
  'server.error.fill-all-password-fields': 'Kaikkia salasanakenttiä ei ole täytetty',
  'server.error.passwords-do-not-match': 'Salasanat eivät täsmää',
  'server.error.old-password-invalid': 'Vanha salasana on väärä',
  'server.error.password-min-length': 'Salasanan pitää sisältää ainakin 6 merkkiä',
  'server.error.access-denied': 'Pääsy evätty',
  'server.error.validation-failed': 'Vahvistus epäonnistui',
  'server.error.incorrect-username-or-password': 'Käyttäjätunnus ja/tai salasana on virheellinen',
}

export default messages
