import { Select } from 'antd'
import moment from 'moment'

import { TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'
import { getTimes } from 'util/time-utils'

const HOUR_FORMAT = 'HH:mm'

type TimeEndPickerProps = {
  disabled: boolean
  isInclusive: boolean
  ptuRange: number
  value: string
  onChange: (newValue: string) => void
}

function TimeEndPicker({ disabled, isInclusive, ptuRange, value, onChange }: TimeEndPickerProps) {
  return (
    <Select aria-label="End time select" disabled={disabled} value={value} onChange={onChange}>
      {getTimes({ isStartTime: false, isInclusive, ptuRange }).map((time) => (
        <Select.Option key={time} value={time}>
          {moment(time, HOUR_FORMAT).format(TIME_WITHOUT_SECONDS_FORMAT)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default TimeEndPicker
