import { useEnvironment } from 'contexts/environment-context'

import './EnvironmentRibbon.css'

const environmentsToShow = ['development', 'staging']

function EnvironmentRibbon() {
  const { name } = useEnvironment()

  if (!name) {
    return null
  }

  if (environmentsToShow.includes(name!)) {
    return <div className="ribbon">{name}</div>
  }

  return null
}

export default EnvironmentRibbon
