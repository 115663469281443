import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { buildRecurrence, parseRecurrence } from 'util/recurrence'

import EveryDayTag from './Recurrence/EveryDayTag'
import WeekdayTag from './Recurrence/WeekdayTag'

import styles from './Recurrence.module.css'

function Recurrence({ recurrence, onRecurrenceChange, readOnly }) {
  const selectedDays = parseRecurrence(recurrence)

  function handleChange(day, checked) {
    const nextSelectedDays = checked ? [...selectedDays, day] : selectedDays.filter((t) => t !== day)
    onRecurrenceChange(buildRecurrence(nextSelectedDays))
  }

  function handleEveryDayChange(checked) {
    if (checked) {
      onRecurrenceChange(buildRecurrence([]))
    }
  }

  function orderBasedOnLocalisedFirstDayOfTheWeek(weekdayTags) {
    const firstDayOfWeekLocalised = moment.localeData(moment.locale()).firstDayOfWeek()
    const orderedWeekdayTags = []

    weekdayTags.forEach((_, index) => {
      const newLocation = (index + (weekdayTags.length - firstDayOfWeekLocalised)) % weekdayTags.length
      orderedWeekdayTags[newLocation] = weekdayTags[index]
    })

    return orderedWeekdayTags
  }

  function weekdayTagsOrdered() {
    const weekdayTags = [...Array(7).keys()].map((tag) => (
      <WeekdayTag key={tag} tag={tag} selectedDays={selectedDays} handleDayChange={handleChange} readOnly={readOnly} />
    ))

    return orderBasedOnLocalisedFirstDayOfTheWeek(weekdayTags)
  }

  return (
    <div className={styles.container}>
      <FormattedMessage id="app.resource-owner.recurrence" tagName="strong" />
      <div data-testid="days-container">
        <EveryDayTag selectedDays={selectedDays} handleEveryDayChange={handleEveryDayChange} readOnly={readOnly} />
        {weekdayTagsOrdered()}
      </div>
    </div>
  )
}

export default Recurrence
