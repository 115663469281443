const possibleByDayWeekDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

export function parseRecurrence(recurrence) {
  if (!recurrence) {
    return []
  }

  const parts = recurrence.toUpperCase().split(';')
  const byDayPart = parts.find((part) => part.startsWith('BYDAY='))

  if (!byDayPart) {
    return []
  }

  const [, byDayWeekPart] = byDayPart.split('=')
  const byDayWeekDays = byDayWeekPart.split(',')

  const weekDays = []
  byDayWeekDays.forEach((byDayWeekDay) => {
    const day = possibleByDayWeekDays.findIndex((weekDay) => weekDay.toUpperCase().startsWith(byDayWeekDay))
    if (day > -1) {
      weekDays.push(day)
    }
  })

  return weekDays
}

export function buildRecurrence(weekDays) {
  const byDayWeekDays = weekDays.map((weekDay) => possibleByDayWeekDays[weekDay])
  if (byDayWeekDays.length === 0) {
    return 'FREQ=DAILY'
  }

  return `FREQ=DAILY;BYDAY=${byDayWeekDays.join(',')}`
}
