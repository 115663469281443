import { Button, message, Modal, Switch } from 'antd'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'

import * as apiServiceRules from 'api/service-rules'
import { DATE_TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'
import { logExceptionInSentry } from 'util/error-message'

import FormattedDateFromUTCToTimezone from 'components/FormattedDateFromUTCToTimezone'

const PRICE_PRECISION = 10000

function getPricePrecision(price) {
  return price / PRICE_PRECISION
}

function ModalScheduledTurnOffs({ plans = [], resourceOwnerId, serviceId, timeZone, onHide, onUpdate }) {
  const intl = useIntl()

  const mappedPlans = useMemo(
    () =>
      orderBy(
        plans.map((plan) => ({
          ...plan,
          relayWireName: plan.relayWire?.name,
        })),
        ['relayWireName'],
      ),
    [plans],
  )

  async function cancelAction(plan) {
    try {
      apiServiceRules.cancelPlan(resourceOwnerId, serviceId, plan.id)

      message.success(intl.formatMessage({ id: 'app.notification.success.cancel-plan' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.cancel-plan' }))
      logExceptionInSentry(error)
    }
  }

  async function enableAction(plan) {
    try {
      apiServiceRules.enablePlan(resourceOwnerId, serviceId, plan.id)

      message.success(intl.formatMessage({ id: 'app.notification.success.enable-plan' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.enable-plan' }))
      logExceptionInSentry(error)
    }
  }

  function handleToggle(checked, plan) {
    return checked ? enableAction(plan) : cancelAction(plan)
  }

  return (
    <Modal
      visible
      closable={false}
      width={900}
      title={<FormattedMessage id="app.resource-owner.products-services.modal.scheduled-shut-offs" />}
      footer={[
        <Button key="close-button" onClick={onHide}>
          <FormattedMessage id="app.close" />
        </Button>,
      ]}
    >
      <table className="table table-striped modal-edit-plans">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="app.resource-owner.modal.group-name" />
            </th>
            <th>
              <FormattedMessage id="app.resource-owner.from" />
            </th>
            <th>
              <FormattedMessage id="app.resource-owner.to" />
            </th>
            <th>
              <FormattedMessage id="app.resource-owner.products-services.limit-price" />
            </th>
            <th>
              <FormattedMessage id="app.resource-owner.products-services.actual-price" />
            </th>
            <th>
              <FormattedMessage id="app.resource-owner.status" />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {mappedPlans.map((plan) => (
            <tr key={plan.id}>
              <td>{plan.relayWire.name}</td>
              <td>
                <FormattedDateFromUTCToTimezone
                  date={plan.startAt}
                  format={DATE_TIME_WITHOUT_SECONDS_FORMAT}
                  timezone={timeZone}
                />
              </td>
              <td>
                <FormattedDateFromUTCToTimezone
                  date={plan.endAt}
                  format={DATE_TIME_WITHOUT_SECONDS_FORMAT}
                  timezone={timeZone}
                />
              </td>
              <td>
                <span>
                  <FormattedNumber value={getPricePrecision(plan.limitValue)} maximumFractionDigits={2} />
                  <FormattedMessage id="app.resource-owner.products-services.modal.help.text.euro.sign" />
                </span>
              </td>
              <td>
                <span>
                  <FormattedNumber value={getPricePrecision(plan.actualValue)} maximumFractionDigits={2} />
                  <FormattedMessage id="app.resource-owner.products-services.modal.help.text.euro.sign" />
                </span>
              </td>
              <td>
                <FormattedMessage
                  id={`app.resource-owner.products-services.${plan.isEnabled ? 'enabled' : 'cancelled'}`}
                />
              </td>
              <td>
                <Switch checked={plan.isEnabled} onChange={(checked) => handleToggle(checked, plan)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  )
}

export default ModalScheduledTurnOffs
