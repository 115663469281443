import React from 'react'
import { FormattedNumber } from 'react-intl'

const FormattedConsumption = ({ consumption, isMegawatts }) => {
  if (isMegawatts) {
    const MWConsumption = consumption / 1000000
    return (
      <span>
        <FormattedNumber value={MWConsumption} maximumFractionDigits={2} />
        {' MW'}
      </span>
    )
  }

  const kwConsumption = consumption / 1000
  return (
    <span>
      <FormattedNumber value={kwConsumption} maximumFractionDigits={0} />
      {' kW'}
    </span>
  )
}

export default FormattedConsumption
