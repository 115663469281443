/* eslint-disable global-require */
import { ConfigProvider, Grid, Layout } from 'antd'
import areIntlLocalesSupported from 'intl-locales-supported'
import { useEffect, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
import ReactGA from 'react-ga4'
import { HashRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'moment/locale/fi'
import 'moment/locale/nl'
import 'moment/locale/sv'
import '../util/moment/locale/el'

import EnvironmentRibbon from './EnvironmentRibbon'
import Navbar from './Navbar'
import TrackedRoute from './TrackedRoute'
import FullPageSpinner from './util/FullPageSpinner'
import Login from 'pages/login'
import ActivationLog from 'pages/activation-log'
import Dashboard from 'pages/dashboard'
import ProductsServices from 'pages/products-services'
import Revenues from 'pages/revenues'

import { useAuth } from 'contexts/auth-context'
import { useEnvironment } from 'contexts/environment-context'
import { useResourceOwner } from 'contexts/resource-owner-context'
import useLocalStorage from 'hooks/useLocalStorage'
import useTimeout from 'hooks/useTimeout'
import { isAxiosRequestCanceled, isTokenExpired } from 'util/error-message'

import { initAxiosInterceptors } from 'interceptors'
import * as translation from '../translation'

import styles from './App.module.css'
import Footer from './Footer'
import { currentTheme, setCurrentTheme } from '../theme/theme-loader'

const { useBreakpoint } = Grid
const { Content } = Layout

const GOOGLE_ANALYTICS_TRACK_ID = 'UA-64019034-9'
const DEFAULT_LANGUAGE = 'en'

// Intl polyfill needed for some browser -> https://github.com/andyearnshaw/Intl.js#getting-started
const localesMyAppSupports = ['en', 'fi', 'sv', 'nl']

if (global.Intl == null) {
  if (!areIntlLocalesSupported(localesMyAppSupports)) {
    require('intl')
    require('intl/locale-data/jsonp/en.js')
    require('intl/locale-data/jsonp/fi.js')
    require('intl/locale-data/jsonp/sv.js')
    require('intl/locale-data/jsonp/nl.js')
  }
}

function filterUnknownLocale(locale: string | undefined): string | undefined {
  return localesMyAppSupports.find((value) => value === locale)
}

function App() {
  const [resourceOwnerUuidFromAdminUi] = useLocalStorage('resourceOwnerUuid')
  const { name, theme } = useEnvironment()
  const { isLogged, isStarting, logout, loggedInUser } = useAuth()
  const { loadCurrentResourceOwner, loadCurrentResourceOwnerWithUuid, resourceOwner } = useResourceOwner()
  setCurrentTheme(theme)

  // Google analytics
  useEffect(() => {
    if (name && name === 'production') {
      ReactGA.initialize(GOOGLE_ANALYTICS_TRACK_ID)
    }
  }, [name])

  // Sentry
  useEffect(() => {
    if (name && ['staging', 'production'].includes(name)) {
      Sentry.init({
        beforeSend: (event, hint: any) => {
          const error = hint && hint.originalException ? hint.originalException : null

          if (isTokenExpired(error)) return null
          if (isAxiosRequestCanceled(error)) return null

          return event
        },
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: name,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.2,
      })
    }
  }, [name])

  // Axios Interceptors
  useEffect(() => initAxiosInterceptors(logout), [logout])

  useEffect(() => {
    async function getCurrentResourceOwner() {
      await loadCurrentResourceOwner()
    }

    async function getCurrentResourceOwnerWithUuid(resourceOwnerUuid: string) {
      await loadCurrentResourceOwnerWithUuid(resourceOwnerUuid)
    }

    if (isLogged) {
      if (resourceOwnerUuidFromAdminUi) {
        getCurrentResourceOwnerWithUuid(resourceOwnerUuidFromAdminUi)
      } else {
        getCurrentResourceOwner()
      }
    }
  }, [isLogged, resourceOwnerUuidFromAdminUi]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isStarting || !theme) {
    return <FullPageSpinner />
  }

  const userUiLanguage = filterUnknownLocale(loggedInUser?.uiLanguage) || DEFAULT_LANGUAGE
  const uiLanguage = filterUnknownLocale(resourceOwner?.uiLanguage) || userUiLanguage
  const messages = translation.messages[uiLanguage]

  return (
    <IntlProvider defaultLocale={DEFAULT_LANGUAGE} locale={uiLanguage} messages={messages}>
      <ConfigProvider locale={translation.antdLocales[uiLanguage]}>
        <EnvironmentRibbon />
        <Router hashType="slash">
          <BaseApp />
        </Router>
      </ConfigProvider>
    </IntlProvider>
  )
}

function BaseApp() {
  const { isLogged } = useAuth()
  const location = useLocation()
  const { resourceOwner } = useResourceOwner()

  const [redirectAfterLoginTo, setRedirectAfterLoginTo] = useState<string | null>(null)

  useEffect(() => {
    if (location.pathname !== '/login') {
      setRedirectAfterLoginTo(location.pathname)
    }
  }, [location.pathname])

  if (isLogged && !resourceOwner) {
    return <FullPageSpinner />
  }

  return isLogged ? (
    <AuthenticatedApp redirectAfterLoginTo={redirectAfterLoginTo} setRedirectAfterLoginTo={setRedirectAfterLoginTo} />
  ) : (
    <UnauthenticatedApp />
  )
}

type AuthenticatedAppProps = {
  redirectAfterLoginTo: string | null
  setRedirectAfterLoginTo: (path: string | null) => void
}

function AuthenticatedApp({ redirectAfterLoginTo, setRedirectAfterLoginTo }: AuthenticatedAppProps) {
  const { logout } = useAuth()
  const screens = useBreakpoint()
  const { resourceOwner } = useResourceOwner()
  const { countryCode } = useEnvironment()
  const shouldHaveProductsServicesRoute = useMemo(() => countryCode !== 'es', [countryCode])

  function handleLogout() {
    setRedirectAfterLoginTo(null)
    logout()
  }

  useTimeout(() => setRedirectAfterLoginTo(null), 0)

  return (
    <Layout className={styles.layout}>
      <Navbar onLogoutClick={handleLogout} />
      <Layout className={styles.layout}>
        <Content className={screens.lg ? '' : styles.content}>
          <Switch>
            <TrackedRoute path="/dashboard" component={Dashboard} />
            <TrackedRoute path="/activation-log" component={ActivationLog} />
            {shouldHaveProductsServicesRoute && <TrackedRoute path="/products-services" component={ProductsServices} />}

            {resourceOwner?.isFinancialVisible && <TrackedRoute path="/revenues" component={Revenues} />}
            <Route path="/">
              <Redirect to={redirectAfterLoginTo ? redirectAfterLoginTo : '/dashboard'} />
            </Route>
          </Switch>
        </Content>
        <Footer isAuthenticated={true} isWhitelabeled={currentTheme.uiConfig.hasWhitelabeledFooter}></Footer>
      </Layout>
    </Layout>
  )
}

function UnauthenticatedApp() {
  return (
    <div className="full">
      <Switch>
        <TrackedRoute path="/login" component={Login} />
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
      <Footer isAuthenticated={false} isWhitelabeled={currentTheme.uiConfig.hasWhitelabeledFooter}></Footer>
    </div>
  )
}

export default App
