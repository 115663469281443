import axios from 'axios'

import type { User } from 'types/user'

export const GET_USER_API_ID = 'GET_USER'

export async function getUser(userId: string): Promise<User> {
  const response = await axios.get(`users/${userId}`)

  return response as unknown as User
}
