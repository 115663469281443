import { Button, message } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiServiceRules from 'api/service-rules'
import { logExceptionInSentry } from 'util/error-message'

const StatusButton = ({ resourceOwnerId, serviceRule, onUpdate = () => {} }) => {
  const intl = useIntl()

  async function deactivate(event) {
    event.stopPropagation()
    try {
      await apiServiceRules.deactivate(resourceOwnerId, serviceRule.serviceId, serviceRule.id)
      message.success(intl.formatMessage({ id: 'app.notification.success.deactivate-rule' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.deactivate-rule' }))
      logExceptionInSentry(error)
    }
  }

  async function activate(event) {
    event.stopPropagation()
    try {
      await apiServiceRules.activate(resourceOwnerId, serviceRule.serviceId, serviceRule.id)
      message.success(intl.formatMessage({ id: 'app.notification.success.activate-rule' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.activate-rule' }))
      logExceptionInSentry(error)
    }
  }

  const { status } = serviceRule

  if (status === 'active') {
    return (
      <Button type="primary" onClick={deactivate}>
        <FormattedMessage id="app.resource-owner.restrictions.deactivate" />
      </Button>
    )
  }

  if (status === 'deactivated') {
    return (
      <Button type="primary" onClick={activate}>
        <FormattedMessage id="app.resource-owner.restrictions.activate" />
      </Button>
    )
  }

  return null
}

export default StatusButton
