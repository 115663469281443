import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { FormattedMessage } from 'react-intl'

import styles from './AllDayCheckbox.module.css'

type AllDayCheckboxProps = {
  value: boolean
  onChange: (newValue: boolean) => void
}

function AllDayCheckbox({ value, onChange }: AllDayCheckboxProps) {
  function handleChange(e: CheckboxChangeEvent) {
    onChange(e.target.checked)
  }

  return (
    <div className={styles.container}>
      <Checkbox checked={value} onChange={handleChange}>
        <FormattedMessage id="app.resource-owner.restrictions.all-day" />
      </Checkbox>
    </div>
  )
}

export default AllDayCheckbox
