import { FileTextOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import fileDownload from 'js-file-download'
import { Parser } from 'json2csv'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { FormattedMessage, useIntl } from 'react-intl'

import { convertToDecimalPrice, convertToMegawatts } from 'util/revenues'

function DownloadCSVButton({ resourceOwnerName, resourceOwnerTimeZone, semester, timeSeries }) {
  const intl = useIntl()

  function downloadCSV() {
    const translatedPeriod = intl.formatMessage({ id: 'app.resource-owner.revenues.column.period' })
    const translatedCapacity = intl.formatMessage({ id: 'app.resource-owner.revenues.column.capacity' })
    const translatedSoldCapacity = intl.formatMessage({
      id: 'app.resource-owner.revenues.column.sold-capacity',
    })
    const translatedRevenue = intl.formatMessage({ id: 'app.resource-owner.revenues.column.revenue' })
    const columnLabels = [translatedPeriod, translatedCapacity, translatedSoldCapacity, translatedRevenue]
    const data = []

    if (timeSeries.values != null && timeSeries.values[0] != null) {
      for (let index = 0; index < timeSeries.values[0].length; index += 1) {
        const value = timeSeries.values[0][index]
        let timestamp = moment
          .utc(timeSeries.startAt)
          .add(timeSeries.stepSize * index, 'milliseconds')
          .format()
        timestamp = momentTimezone.tz(timestamp, resourceOwnerTimeZone).format()
        const row = {}
        row[translatedPeriod] = timestamp
        row[translatedCapacity] = convertToMegawatts(value)
        row[translatedSoldCapacity] = convertToMegawatts(timeSeries.values[1][index])
        row[translatedRevenue] = convertToDecimalPrice(timeSeries.values[2][index])
        data.push(row)
      }
    }

    const parser = new Parser({
      fields: columnLabels,
      unwind: columnLabels,
    })
    const csv = parser.parse(data)
    const translatedRevenues = intl.formatMessage({ id: 'app.resource-owner.navbar.revenues' })
    const fileName = `${translatedRevenues} - ${semester} - ${resourceOwnerName}.csv`
    return fileDownload(csv, fileName)
  }

  return (
    <Button icon={<FileTextOutlined />} onClick={downloadCSV}>
      <FormattedMessage id="app.resource-owner.revenues.export-as-csv" />
    </Button>
  )
}

export default DownloadCSVButton
