import { PlusOutlined } from '@ant-design/icons'
import { Button, DatePicker, Space, Tag } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { DATE_FORMAT, DATE_IN_ISO_8601_FORMAT } from 'constants/date'

import styles from './ExceptionDates.module.css'

function ExceptionDates({ exceptionDates = [], readOnly, onExceptionDatesChange }) {
  const [day, setDay] = useState('')

  exceptionDates.sort()

  function handleAddTag() {
    onExceptionDatesChange([...exceptionDates, moment(day).format(DATE_IN_ISO_8601_FORMAT)])
    setDay('')
  }

  function handleRemoveTag(e, tag) {
    e.preventDefault()
    const nextExceptionDates = exceptionDates.filter((exceptionDate) => exceptionDate !== tag)
    onExceptionDatesChange(nextExceptionDates)
  }

  function handleDayChange(date) {
    setDay(date)
  }

  function disabledDate(currentDate) {
    const formattedDate = moment(currentDate).format(DATE_IN_ISO_8601_FORMAT)
    return currentDate && exceptionDates.includes(formattedDate)
  }

  return (
    <div className={styles.container}>
      <FormattedMessage id="app.resource-owner.exception-dates" tagName="strong" />

      {exceptionDates.length > 0 ? (
        <div>
          {exceptionDates.map((tag) => {
            if (readOnly) {
              return (
                <Tag aria-label="Added exception date" key={tag} className={styles.tag}>
                  {moment(tag, DATE_IN_ISO_8601_FORMAT).format(DATE_FORMAT)}
                </Tag>
              )
            }

            return (
              <Tag
                aria-label="Added exception date"
                closable
                key={tag}
                className={styles.tag}
                onClose={(e) => handleRemoveTag(e, tag)}
              >
                {moment(tag, DATE_IN_ISO_8601_FORMAT).format(DATE_FORMAT)}
              </Tag>
            )
          })}
        </div>
      ) : (
        <div aria-label="No exception dates yet" className={styles.noDaysContainer}>
          <FormattedMessage id="app.resource-owner.no-exception-dates-yet" />
        </div>
      )}

      {readOnly ? null : (
        <div>
          <Space direction="horizontal">
            <DatePicker
              disabledDate={disabledDate}
              format={(current) => moment(current).format(DATE_FORMAT)}
              value={day}
              onChange={handleDayChange}
            />
            <Button disabled={!day || day === ''} type="primary" icon={<PlusOutlined />} onClick={handleAddTag}>
              <FormattedMessage id="app.resource-owner.add-exception-date" />
            </Button>
          </Space>
        </div>
      )}
    </div>
  )
}

export default ExceptionDates
