import React from 'react'
import { FormattedNumber } from 'react-intl'

const FormattedPrice = ({ price }) => (
  <span>
    <FormattedNumber value={price ? price / 100 : '--'} maximumFractionDigits={2} />
  </span>
)

export default FormattedPrice
