import { Button, Divider, Form, Input, message, Modal, Space } from 'antd'
import { differenceBy, orderBy, parseInt } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiServiceRules from 'api/service-rules'
import { logExceptionInSentry } from 'util/error-message'
import { getUsedRelayWiresForServices } from 'util/services'
import {
  convertFromUTCToMomentDateUsingTimezone,
  convertToUtcUsingTimezone,
  getMomentDateForStartOfFutureDaysUsingTimezone,
} from 'util/time-utils'

import ModalRelayWires from 'components/ProductsServices/ModalRelayWires'
import CustomDatePicker from 'components/CustomDatePicker'
import TimeOfDaySelect from 'components/TimeOfDaySelect'

const PRICE_PRECISION = 10000
const MEGAWATTS_PRECISION = 1000000

function ModalEditor({ limitValues, regulators, resourceOwnerId, rule, sections, service, timeZone, onHide }) {
  const intl = useIntl()

  const [endAt, setEndAt] = useState(null)
  const [ruleLimit, setRuleLimit] = useState('')
  const [startAt, setStartAt] = useState(null)
  const [timeEndAt, setTimeEndAt] = useState('')
  const [timeStartAt, setTimeStartAt] = useState('')
  const [selectedRelayWires, setSelectedRelayWires] = useState([])
  const allRelayWires = useMemo(
    () => orderBy(getUsedRelayWiresForServices(regulators, service.id), 'name', 'asc'),
    [regulators, service.id],
  )

  function isNew() {
    return rule == null
  }

  useEffect(() => {
    if (isNew()) {
      setStartAt(getMomentDateForStartOfFutureDaysUsingTimezone(1, timeZone))
      setEndAt(getMomentDateForStartOfFutureDaysUsingTimezone(2, timeZone))
      setTimeStartAt('00:00')
      setTimeEndAt('23:59')
    } else {
      setStartAt(convertFromUTCToMomentDateUsingTimezone(rule.startAt, timeZone))
      setEndAt(convertFromUTCToMomentDateUsingTimezone(rule.endAt, timeZone))
      setRuleLimit(intl.formatNumber(getLimitPrecision(rule.limitValue)))
      setTimeStartAt(rule.timeStartAt)
      setTimeEndAt(rule.timeEndAt)
      setSelectedRelayWires([...rule.relayWires])
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function getLimitPrecision(limit) {
    if (limitValues.label === 'app.resource-owner.products-services.modal.limit.price') {
      return limit / PRICE_PRECISION
    }
    return limit / MEGAWATTS_PRECISION
  }

  function setLimitPrecision(limit) {
    if (limitValues.label === 'app.resource-owner.products-services.modal.limit.price') {
      return limit * PRICE_PRECISION
    }
    return limit * MEGAWATTS_PRECISION
  }

  // return array with added relay wires
  function getAddedRelayWires(ruleRelayWires) {
    return differenceBy(selectedRelayWires, ruleRelayWires, 'relayWireId')
  }

  // return array with removed relay wires
  function getRemovedRelayWires(ruleRelayWires) {
    return differenceBy(ruleRelayWires, selectedRelayWires, 'relayWireId')
  }

  async function addWires(wiresToBeAdded, resourceOwnerId, serviceId, serviceRuleId) {
    const promises = wiresToBeAdded.map((serviceRulesRelayWire) => {
      return apiServiceRules.addServiceRuleRelayWire(resourceOwnerId, serviceId, serviceRuleId, serviceRulesRelayWire)
    })
    try {
      await Promise.all(promises)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.add.resource' }))
      logExceptionInSentry(error)
    }
  }

  async function removeWires(wiresToBeRemoved, resourceOwnerId, serviceId, serviceRuleId) {
    const promises = wiresToBeRemoved.map((serviceRulesRelayWire) => {
      return apiServiceRules.deleteServiceRuleRelayWire(
        resourceOwnerId,
        serviceId,
        serviceRuleId,
        serviceRulesRelayWire.relayWireId,
      )
    })
    try {
      await Promise.all(promises)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.delete-rule-resource' }))
      logExceptionInSentry(error)
    }
  }

  // Rule was saved, now check if any relay wires need to be added/removed
  async function handleServiceRuleSaved(savedServiceRule) {
    const { id, relayWires, serviceId } = savedServiceRule

    // Add wires if any were checked from the list
    const wiresToBeAdded = getAddedRelayWires(relayWires)
    if (wiresToBeAdded.length > 0) {
      await addWires(wiresToBeAdded, resourceOwnerId, serviceId, id)
    }

    // Remove wires if any were unchecked from the list
    const wiresToBeRemoved = getRemovedRelayWires(relayWires)
    if (wiresToBeRemoved.length > 0) {
      await removeWires(wiresToBeRemoved, resourceOwnerId, serviceId, id)
    }

    onHide({ needsRefresh: true })
  }

  function validateFields() {
    if (moment(endAt).isBefore(startAt)) {
      message.error(intl.formatMessage({ id: 'app.resource-owner.restrictions.end-date-before-start-date-error' }))
      return false
    }
    if (parseInt(timeStartAt) > parseInt(timeEndAt)) {
      message.error(intl.formatMessage({ id: 'app.resource-owner.restrictions.end-time-before-start-time-error' }))
      return false
    }
    if (ruleLimit === '' && service.type === 'max-price') {
      message.error(intl.formatMessage({ id: 'app.notification.error.rule.empty-price-limit' }))
      return false
    }
    if (ruleLimit === '' && service.type === 'ffr-up') {
      message.error(intl.formatMessage({ id: 'app.notification.error.rule.empty-frequency-limit' }))
      return false
    }
    if (ruleLimit === '') {
      message.error(intl.formatMessage({ id: 'app.notification.error.rule.empty-consumption-limit' }))
      return false
    }
    return true
  }

  async function handleSaveRule() {
    const isValid = validateFields()

    if (isValid) {
      let newRule = {
        startAt: convertToUtcUsingTimezone(startAt, timeZone),
        endAt: convertToUtcUsingTimezone(endAt, timeZone),
        serviceId: service.id,
        timeStartAt,
        timeEndAt,
        limitValue: convertLimitStringToInteger(ruleLimit),
      }
      if (!isNew()) {
        newRule = {
          ...newRule,
          id: rule.id,
        }
      }

      try {
        const savedServiceRule = await apiServiceRules.save(resourceOwnerId, service.id, newRule)
        message.success(intl.formatMessage({ id: 'app.notification.success.save-rule' }))

        // Now that the service rule was saved, call the handler to add/delete wires
        handleServiceRuleSaved(savedServiceRule)
      } catch (error) {
        message.error(intl.formatMessage({ id: 'app.notification.error.save-rule' }))
        logExceptionInSentry(error)
      }
    }
  }

  function onRuleLimitChange(event) {
    const maxNumbersOfLimit = 6
    const newRuleLimit = event.target.value
    // Number function doesn't accept commas.
    const ruleLimitWithDot = newRuleLimit.replace(',', '.')
    if (newRuleLimit.length <= maxNumbersOfLimit && !isNaN(Number(ruleLimitWithDot))) {
      setRuleLimit(newRuleLimit)
    }
  }

  function convertLimitStringToInteger(limitValue) {
    const limitValueWithDot = limitValue.replace(',', '.')
    const floatLimitValue = setLimitPrecision(parseFloat(limitValueWithDot))
    return parseInt(floatLimitValue)
  }

  if (!allRelayWires) {
    return null
  }

  return (
    <Modal
      visible
      closable={false}
      width={900}
      footer={[
        <Button key="save-button" type="primary" onClick={handleSaveRule}>
          <FormattedMessage id="app.save" />
        </Button>,
        <Button key="cancel-button" onClick={onHide}>
          <FormattedMessage id="app.cancel" />
        </Button>,
      ]}
    >
      <div style={{ marginBottom: '10px' }}>
        <strong>
          <FormattedMessage id="app.resource-owner.timezone" />
        </strong>
        : {timeZone}
      </div>
      <Form
        initialValues={{ ruleLimit: rule?.limitValue ? intl.formatNumber(getLimitPrecision(rule.limitValue)) : '' }}
        layout="vertical"
      >
        <Space size="large">
          <CustomDatePicker
            label="app.resource-owner.restrictions.start-date"
            value={startAt}
            timeZone={timeZone}
            onChange={setStartAt}
          />
          <CustomDatePicker
            label="app.resource-owner.restrictions.end-date"
            value={endAt}
            timeZone={timeZone}
            onChange={setEndAt}
          />
          <TimeOfDaySelect
            ptuRange={60}
            timeStartAt={timeStartAt}
            timeEndAt={timeEndAt}
            onTimeStartAtChange={setTimeStartAt}
            onTimeEndAtChange={setTimeEndAt}
          />
          <Form.Item
            label={intl.formatMessage({ id: limitValues.label })}
            name="ruleLimit"
            rules={[{ required: true, message: '' }]}
          >
            <Input addonAfter={intl.formatMessage({ id: limitValues.measurement })} onChange={onRuleLimitChange} />
          </Form.Item>
        </Space>
      </Form>
      <Divider orientation="left">
        <FormattedMessage id="app.resource-owner.restrictions.choose-affected-lamp-groups" />
      </Divider>
      <ModalRelayWires
        isEdit={true}
        selectedRelayWires={selectedRelayWires}
        sections={sections}
        wires={allRelayWires}
        onChange={setSelectedRelayWires}
      />
    </Modal>
  )
}

export default ModalEditor
