import { Space } from 'antd'
import moment from 'moment'

import { TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'

const HOUR_FORMAT = 'HH:mm'

interface TimeOfDayDisplayProps {
  endAt: string
  startAt: string
}

function TimeOfDayDisplay({ endAt, startAt }: TimeOfDayDisplayProps) {
  return (
    <Space split="-">
      <span aria-label="Time start at">{moment(startAt, HOUR_FORMAT).format(TIME_WITHOUT_SECONDS_FORMAT)}</span>
      <span aria-label="Time end at">{moment(endAt, HOUR_FORMAT).format(TIME_WITHOUT_SECONDS_FORMAT)}</span>
    </Space>
  )
}

export default TimeOfDayDisplay
