import { DownOutlined, RightOutlined } from '@ant-design/icons'

import FormattedNumberPS from 'components/util/FormattedNumberPrefixSuffix'

import styles from './PivotColumn.module.css'

function PivotColumn({ columnInfo, level, maxLevel, visibleChildren }) {
  function addNumberCells(cells, firstColumnIndex, lastColumnIndex) {
    for (
      let columnIndex = firstColumnIndex, end = lastColumnIndex, asc = firstColumnIndex <= end;
      asc ? columnIndex <= end : columnIndex >= end;
      asc ? (columnIndex += 1) : (columnIndex -= 1)
    ) {
      cells.push(
        <FormattedNumberPS
          divClassName="revenue-pivot-body-cell"
          key={columnIndex}
          maximumFractionDigits={columnInfo[columnIndex].maximumFractionDigits}
          prefix={columnInfo[columnIndex].prefix}
          suffix={columnInfo[columnIndex].suffix}
          value={columnInfo[columnIndex].value}
        />,
      )
    }
    return cells
  }

  function renderCells() {
    const cells = []

    // First, add period info (special formatting)
    cells.push(
      <div className="revenue-pivot-body-cell" key={0}>
        <span>{columnInfo[0]}</span>
      </div>,
    )

    // Now, add all other cells (with number formatting)
    return addNumberCells(cells, 1, columnInfo.length - 1)
  }

  return (
    <div>
      {level < maxLevel && (
        <span className={styles.iconContainer}>{visibleChildren ? <DownOutlined /> : <RightOutlined />}</span>
      )}
      {renderCells()}
    </div>
  )
}

export default PivotColumn
