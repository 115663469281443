import { Space } from 'antd'
import moment, { Moment } from 'moment'

import { RestrictionType } from 'constants/restrictions'
import {
  areDatesTheSameDay,
  areDatesInTheSameMonth,
  areDatesInTheSameYear,
  convertFromUTCToMomentDateUsingTimezone,
} from 'util/time-utils'

type DisplayDateAndTimeOfRestrictionProps = {
  endAt: string
  startAt: string
  timeEndAt: string
  timeStartAt: string
  timezone: string
  type: string
}

function DisplayDateAndTimeOfRestriction({
  endAt,
  startAt,
  timeEndAt,
  timeStartAt,
  timezone,
  type,
}: DisplayDateAndTimeOfRestrictionProps) {
  const startAtInTimezone = convertFromUTCToMomentDateUsingTimezone(startAt, timezone)
  const endAtInTimezone = convertFromUTCToMomentDateUsingTimezone(endAt, timezone)

  const isTimeRangeAFullDay = timeStartAt === '00:00' && timeEndAt === '23:59'

  if (areDatesTheSameDay(startAtInTimezone, endAtInTimezone)) {
    return isTimeRangeAFullDay ? (
      <span>{dayMonthFormatter(startAtInTimezone)}</span>
    ) : (
      <span>{`${dayMonthFormatter(startAtInTimezone)}, ${timeStartAt} - ${timeEndAt}`}</span>
    )
  }

  if (!areDatesInTheSameYear(startAtInTimezone, endAtInTimezone)) {
    if (type === RestrictionType.REPEATING_DAILY) {
      return (
        <Space>
          <span>{`${dayMonthYearFormatter(startAtInTimezone)} - ${dayMonthYearFormatter(
            endAtInTimezone,
          )}, ${timeStartAt} - ${timeEndAt}`}</span>
          <RepeatingDaily />
        </Space>
      )
    }

    return isTimeRangeAFullDay ? (
      <span>{`${dayMonthYearFormatter(startAtInTimezone)} - ${dayMonthYearFormatter(endAtInTimezone)}`}</span>
    ) : (
      <span>{`${dayMonthYearFormatter(startAtInTimezone)}, ${timeStartAt} - ${dayMonthYearFormatter(
        endAtInTimezone,
      )}, ${timeEndAt}`}</span>
    )
  }

  if (areDatesInTheSameMonth(startAtInTimezone, endAtInTimezone)) {
    if (type === RestrictionType.REPEATING_DAILY) {
      return (
        <Space>
          <span>{`${dayFormatter(startAtInTimezone)} - ${dayMonthFormatter(
            endAtInTimezone,
          )}, ${timeStartAt} - ${timeEndAt}`}</span>
          <RepeatingDaily />
        </Space>
      )
    }

    return isTimeRangeAFullDay ? (
      <span>{`${dayFormatter(startAtInTimezone)} - ${dayMonthFormatter(endAtInTimezone)}`}</span>
    ) : (
      <span>{`${dayMonthFormatter(startAtInTimezone)}, ${timeStartAt} - ${dayMonthFormatter(
        endAtInTimezone,
      )}, ${timeEndAt}`}</span>
    )
  }

  if (type === RestrictionType.REPEATING_DAILY) {
    return (
      <Space>
        <span>{`${dayMonthFormatter(startAtInTimezone)} - ${dayMonthFormatter(
          endAtInTimezone,
        )}, ${timeStartAt} - ${timeEndAt}`}</span>
        <RepeatingDaily />
      </Space>
    )
  }

  return isTimeRangeAFullDay ? (
    <span>{`${dayMonthFormatter(startAtInTimezone)} - ${dayMonthFormatter(endAtInTimezone)}`}</span>
  ) : (
    <span>{`${dayMonthFormatter(startAtInTimezone)}, ${timeStartAt} - ${dayMonthFormatter(
      endAtInTimezone,
    )}, ${timeEndAt}`}</span>
  )
}

function dayFormatter(date: Moment) {
  const DAY_FORMAT = 'D'
  return moment(date).format(DAY_FORMAT)
}

function dayMonthFormatter(date: Moment) {
  const DAY_MONTH_FORMAT = 'D MMMM'
  return moment(date).format(DAY_MONTH_FORMAT)
}

function dayMonthYearFormatter(date: Moment) {
  const DAY_MONTH_YEAR_FORMAT = 'D MMMM YYYY'
  return moment(date).format(DAY_MONTH_YEAR_FORMAT)
}

function RepeatingDaily() {
  return <span style={{ color: '#D32F2F', fontStyle: 'italic' }}>R</span>
}

export default DisplayDateAndTimeOfRestriction
