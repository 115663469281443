import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/fi'
import '@formatjs/intl-pluralrules/locale-data/nl'
import '@formatjs/intl-pluralrules/locale-data/sv'

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/fi'
import '@formatjs/intl-relativetimeformat/locale-data/nl'
import '@formatjs/intl-relativetimeformat/locale-data/sv'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.variable.less'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactDOM from 'react-dom'
import axios from 'axios'

import '@aws-amplify/ui-react/styles.css'
import './css/custom.css'
import './css/revenue-pivot-table.css'
import './css/table.css'
import './css/antd.css'
import './css/amplify.css'

import { getBackendUrl } from './config'

import App from 'components/App'
import { EnvironmentProvider } from 'contexts/environment-context'
import { FeatureToggleProvider } from 'contexts/feature-toggle-context'
import AmplifySetupWrapper from 'components/AmplifySetupWrapper'
import CheckForAdminUiJump from 'components/CheckForAdminUiJump'
import TopLevelProviders from 'components/TopLevelProviders'

// Set the node-backend url
axios.defaults.baseURL = getBackendUrl()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <EnvironmentProvider>
      <CheckForAdminUiJump>
        <AmplifySetupWrapper>
          <TopLevelProviders>
            <FeatureToggleProvider>
              <App />
            </FeatureToggleProvider>
          </TopLevelProviders>
        </AmplifySetupWrapper>
      </CheckForAdminUiJump>
    </EnvironmentProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
)
