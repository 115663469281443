import shortid from 'shortid'

import PivotHeader from 'components/Revenues/PivotHeader'
import TreeNodeComponent from 'components/Revenues/TreeNodeComponent'

function PivotTable({ columns, expandingHalfYears, maxLevel, periodFormattingObjects, tree }) {
  return (
    <div className="revenue-pivot-table">
      <PivotHeader fields={columns} />
      {tree.children.map((node) => (
        <ul key={shortid.generate()} className="list-type-none">
          <li>
            <TreeNodeComponent
              expandingHalfYears={expandingHalfYears}
              maxLevel={maxLevel}
              periodFormattingObjects={periodFormattingObjects}
              treeNode={node}
            />
          </li>
        </ul>
      ))}
    </div>
  )
}

export default PivotTable
