import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { DATE_FORMAT } from 'constants/date'

function ExceptionDate({ exceptionDates = [] }) {
  exceptionDates.sort()

  if (exceptionDates.length === 0) {
    return null
  }

  const firstExceptionDate = exceptionDates[0]

  if (exceptionDates.length === 1) {
    return <span>{firstExceptionDate != null ? moment(firstExceptionDate).format(DATE_FORMAT) : '--'}</span>
  }

  return (
    <>
      <span>{firstExceptionDate != null ? moment(firstExceptionDate).format(DATE_FORMAT) : '--'}</span>
      {', '}
      <FormattedMessage id="app.resource-owner.more-results" values={{ count: exceptionDates.length - 1 }} />
    </>
  )
}

export default ExceptionDate
