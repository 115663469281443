import { FormattedMessage } from 'react-intl'

import styles from './NoData.module.css'

const NoData = () => (
  <div className={styles.container}>
    <FormattedMessage id="app.resource-owner.no-data-to-display" />
  </div>
)

export default NoData
