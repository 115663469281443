import { message, Spin } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiRevenues from 'api/revenues'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { getSemesterStartEndDate } from 'util/date-utils'
import { logExceptionInSentry } from 'util/error-message'

import DownloadCSVButton from 'components/Revenues/DownloadCSVButton'
import Table from 'components/Revenues/Table'
import FormattedNumberPS from 'components/util/FormattedNumberPrefixSuffix'

import styles from './Semester.module.css'

function Semester({ selectedService, semester, semesterIndex }) {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const semesterStartEndDate = getSemesterStartEndDate(semester)
  const { createdAt } = resourceOwner

  const today = moment().utc().toISOString()

  const [info, setInfo] = useState({})
  const [isOpen, setIsOpen] = useState(semesterIndex === 0)
  const [loading, setLoading] = useState(true)
  const [showInfo, setShowInfo] = useState(false)

  const endDate = useMemo(
    () => (semesterStartEndDate.endDate < today ? semesterStartEndDate.endDate : today),
    [semesterStartEndDate.endDate, today],
  )
  const startDate = useMemo(
    () => (semesterStartEndDate.startDate > createdAt ? semesterStartEndDate.startDate : createdAt),
    [createdAt, semesterStartEndDate.startDate],
  )
  const [timeSeries, setTimeSeries] = useState({})

  const loadTimeSeries = useCallback(async () => {
    setLoading(true)

    try {
      const data = await apiRevenues.getRevenueTimeSeries(resourceOwner.id, selectedService, startDate, endDate)
      setTimeSeries(data)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.loading-revenues' }))
      logExceptionInSentry(error)
    } finally {
      setLoading(false)
    }
  }, [endDate, intl, resourceOwner.id, selectedService, startDate])

  useEffect(() => {
    if (isOpen && isEmpty(timeSeries)) {
      loadTimeSeries()
    }
  }, [isOpen, loadTimeSeries, timeSeries])

  function handleChangeOpenStatus() {
    setIsOpen((prev) => !prev)
  }

  function updateGeneralInfo(data) {
    setShowInfo(true)
    setInfo({
      capacity: data.capacity,
      revenue: data.revenue,
      soldCapacity: data.soldCapacity,
    })
  }

  const { capacity, revenue, soldCapacity } = info

  return (
    <div className={styles.container} data-testid="semester-container">
      <div className={styles.header} onClick={handleChangeOpenStatus}>
        <h2 className={styles.title}>{semester}</h2>
        {showInfo && (
          <div className={styles.infoContainer}>
            <div className={styles.itemContainer}>
              <FormattedMessage id="app.resource-owner.revenues.column.revenue" tagName="h3" />:
              <span className={styles.featured}>
                <FormattedNumberPS maximumFractionDigits={2} suffix="€" value={revenue} />
              </span>
            </div>
            {!!capacity && (
              <div className={styles.itemContainer}>
                <FormattedMessage id="app.resource-owner.revenues.column.capacity" tagName="h3" />:
                <span>
                  <FormattedNumberPS maximumFractionDigits={2} value={capacity} />
                </span>
              </div>
            )}
            {!!soldCapacity && (
              <div className={styles.itemContainer}>
                <FormattedMessage id="app.resource-owner.revenues.column.sold-capacity" tagName="h3" />:
                <span>
                  <FormattedNumberPS maximumFractionDigits={2} value={soldCapacity} />
                </span>
              </div>
            )}
          </div>
        )}
        <span
          className={`${styles.arrow} ${isOpen ? 'gray-arrow__up' : 'gray-arrow__down'}`}
          data-testid="arrow-action"
        />
      </div>
      {isOpen && loading && <Spin data-testid="loading" className={styles.loading} />}
      {isOpen && !loading && (
        <div className={styles.content}>
          <DownloadCSVButton
            resourceOwnerName={resourceOwner.name}
            resourceOwnerTimeZone={resourceOwner.timeZone}
            semester={semester}
            timeSeries={timeSeries}
          />

          <div className={styles.tableContainer}>
            <Table timeSeries={timeSeries} timeZone={resourceOwner.timeZone} onLoad={updateGeneralInfo} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Semester
