import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { useResourceOwner } from 'contexts/resource-owner-context'
import { parseRecurrence } from 'util/recurrence'

function getRecurrenceBasedOnLocalisedFirstDayOfTheWeek(recurrences) {
  recurrences.sort()
  const firstDayOfTheWeekLocalised = moment.localeData(moment.locale()).firstDayOfWeek()

  for (const recurrence of recurrences) {
    if (recurrence >= firstDayOfTheWeekLocalised) {
      return recurrence
    }
  }
  return recurrences[0]
}

function RecurrenceField({ recurrence }) {
  const { resourceOwner } = useResourceOwner()

  const weekDays = moment.localeData(resourceOwner.uiLanguage).weekdays()
  const recurrences = parseRecurrence(recurrence)

  if (recurrences.length === 0) {
    return <FormattedMessage id="app.resource-owner.restrictions.recurrence.every-day" />
  }

  if (recurrences.length === 1) {
    return <>{weekDays[recurrences[0]]}</>
  }

  const firstRecurrence = getRecurrenceBasedOnLocalisedFirstDayOfTheWeek(recurrences)

  return (
    <>
      <span aria-label="First recurrence">{weekDays[firstRecurrence]}</span>
      {', '}
      <FormattedMessage id="app.resource-owner.more-results" values={{ count: recurrences.length - 1 }} />
    </>
  )
}

export default RecurrenceField
