import { Footer as AntFooter } from 'antd/lib/layout/layout'

import styles from './Footer.module.css'
import PoweredByLogo from 'assets/logo-blue.png'

type FooterProps = {
  isAuthenticated: boolean
  isWhitelabeled: boolean
}

export default function Footer({ isAuthenticated, isWhitelabeled }: FooterProps) {
  return (
    <AntFooter className={isAuthenticated ? styles.footer : styles.footerNotAuthenticated}>
      {isWhitelabeled ? (
        <>
          <div>Powered by</div>
          <a href="https://sympower.net">
            <img src={PoweredByLogo} alt="Powered by Sympower" className={styles.poweredByLogo} />
          </a>
        </>
      ) : (
        <>
          <a href="mailto:support@sympower.net">support@sympower.net</a>
          <span>{` © 2016-${new Date().getFullYear()} Sympower`}</span>
        </>
      )}
    </AntFooter>
  )
}
