import { Auth } from 'aws-amplify'
import axios, { AxiosInstance, AxiosStatic } from 'axios'

// Refresh user session
// https://github.com/aws-amplify/amplify-js/issues/2560#issuecomment-657251846
const axiosWithAuth = async (config: any) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration()
        const refreshToken = session.getRefreshToken()
        const currentTimeSeconds = Math.round(+new Date() / 1000)

        // As we verify the token on the node-backend, it can happen that we start the request with a valid token,
        // and due to latency and asynchronous execution of verify, we refresh the token before we were able to verify it.
        // Let's force a token refresh 1 min before it's expired.
        const forceRefreshTime = currentTimeSeconds + 60

        if (idTokenExpire < forceRefreshTime) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err: any, data: any) => {
              if (err) {
                Auth.signOut()
              } else {
                config.headers.Authorization = `Bearer ${data.getIdToken().getJwtToken()}`
                resolve(config)
              }
            })
          })
        } else {
          config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`
          resolve(config)
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config)
      })
  })
}

function includeAuthorizationTokenOnRequest(client: AxiosInstance | AxiosStatic = axios) {
  client.interceptors.request.use(axiosWithAuth)
}

const initAxiosInterceptors = (logout: () => void) => {
  axios.interceptors.request.use(axiosWithAuth)

  // Add a 401 response interceptor
  axios.interceptors.response.use(
    (response) => {
      if (response.data) {
        return response.data
      }
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        logout()
      }
      return Promise.reject(error)
    },
  )
}

export { includeAuthorizationTokenOnRequest, initAxiosInterceptors }
