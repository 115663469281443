import { useMemo } from 'react'
import { useIntl } from 'react-intl'

function Resources({ wires = [], wireType = 'relayWire' }) {
  const intl = useIntl()

  const namedWires = useMemo(() => wires.filter((wire) => !isBlank(wire[wireType].name)), [wireType, wires])

  if (wires.length === 0) {
    return (
      <span>{intl.formatMessage({ id: 'app.resource-owner.restrictions.resources-no-name' }, { count: '0' })}</span>
    )
  }

  if (namedWires.length === 0) {
    return intl.formatMessage({ id: 'app.resource-owner.restrictions.resources-no-name' }, { count: wires.length })
  }

  const firstResourceName = namedWires[0][wireType].name

  if (namedWires.length === 1 && wires.length === 1) {
    return firstResourceName
  }

  return `${firstResourceName}, ${intl.formatMessage(
    { id: 'app.resource-owner.more-results' },
    { count: wires.length - 1 },
  )}`
}

function isBlank(string) {
  return !string || string.trim().length === 0
}

export default Resources
