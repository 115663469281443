import { Button, Col, Divider, Modal, Row, Space } from 'antd'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useMemo } from 'react'

import FormattedDateFromUTCToTimezone from 'components/FormattedDateFromUTCToTimezone'
import TimeOfDayDisplay from 'components/TimeOfDayDisplay'
import ModalRelayWires from 'components/ProductsServices/ModalRelayWires'
import FormattedStatus from 'components/util/FormattedRestrictionStatus'

const PRICE_PRECISION = 10000
const MEGAWATTS_PRECISION = 1000000

function ModalDetails({ limitValues, rule, sections, timeZone, onCloseClick, onEditClick }) {
  const canBeEdited = useMemo(() => rule.status !== 'expired', [rule.status])
  const limitValueWithPrecision = useMemo(() => {
    const { limitValue } = rule

    return limitValues.label === 'app.resource-owner.products-services.modal.limit.price'
      ? limitValue / PRICE_PRECISION
      : limitValue / MEGAWATTS_PRECISION
  }, [limitValues.label, rule])

  return (
    <Modal
      visible
      closable={false}
      width={900}
      footer={[
        canBeEdited ? (
          <Button key="edit-button" type="primary" onClick={onEditClick}>
            <FormattedMessage id="app.edit" />
          </Button>
        ) : null,
        <Button key="close-button" onClick={onCloseClick}>
          <FormattedMessage id="app.close" />
        </Button>,
      ]}
    >
      <Row justify="space-between">
        <Col>
          <FormattedMessage id="app.resource-owner.restrictions.start-date" tagName="strong" />
          <br />
          <FormattedDateFromUTCToTimezone date={rule.startAt} timezone={timeZone} />
        </Col>
        <Col>
          <FormattedMessage id="app.resource-owner.restrictions.end-date" tagName="strong" />
          <br />
          <FormattedDateFromUTCToTimezone date={rule.endAt} timezone={timeZone} />
        </Col>
        <Col>
          <FormattedMessage id="app.resource-owner.restrictions.time-of-day" tagName="strong" />
          <br />
          <TimeOfDayDisplay endAt={rule.timeEndAt} startAt={rule.timeStartAt} />
        </Col>
        <Col>
          <FormattedMessage id="app.resource-owner.status" tagName="strong" />
          <br />
          <FormattedStatus status={rule.status} activeAt={rule.activeAt} />
        </Col>
        <Col>
          <FormattedMessage id={limitValues.label} tagName="strong" />
          <br />
          <Space size="small">
            <FormattedNumber value={limitValueWithPrecision} maximumFractionDigits={2} />
            <FormattedMessage id={limitValues.measurement} />
          </Space>
        </Col>
      </Row>
      {rule.relayWires && rule.relayWires.length > 0 && (
        <Divider orientation="left">
          <FormattedMessage id="app.resource-owner.modal.affected-resources" />
        </Divider>
      )}
      {rule.relayWires && rule.relayWires.length > 0 && (
        <ModalRelayWires isEdit={false} sections={sections} wires={rule.relayWires} />
      )}
    </Modal>
  )
}

export default ModalDetails
