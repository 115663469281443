import { DatePicker, Form } from 'antd'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'

import { DATE_FORMAT } from 'constants/date'

// check if given date is not in the past
function isDisabled(value) {
  const now = moment().startOf('day')
  return value < now
}

function CustomDatePicker({ label, value, timeZone, onChange }) {
  const intl = useIntl()

  React.useEffect(() => {
    moment.tz.setDefault(timeZone)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form.Item label={intl.formatMessage({ id: label })}>
      <DatePicker
        allowClear={false}
        disabled={isDisabled(value)}
        disabledDate={isDisabled}
        format={(current) => moment(current).format(DATE_FORMAT)}
        value={value ? moment(value) : null}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default CustomDatePicker
