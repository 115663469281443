import { message, Tabs } from 'antd'
import { orderBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiRevenues from 'api/revenues'
import * as apiResourceOwner from 'api/resource-owner'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { getAllSemestersBetweenDates } from 'util/date-utils'
import { logExceptionInSentry } from 'util/error-message'

import Semester from 'components/Revenues/Semester'
import Loading from 'components/util/Loading'

import styles from './revenues.module.css'

const { TabPane } = Tabs

function Revenues() {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const [calculatedPeriodByService, setCalculatedPeriodByService] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [selectedService, setSelectedService] = useState(null)
  const [services, setServices] = useState([])
  const [semesters, setSemesters] = useState([])
  const [showNoRevenueAvailable, setShowNoRevenueAvailable] = useState(false)

  const loadResourceOwnerServices = useCallback(async () => {
    try {
      const data = await apiResourceOwner.getServices(resourceOwner.id)

      const extractedServices = data
        .filter((item) => !item.isDisabled && item.service.type !== 'notify')
        .map((item) => ({
          ...item.service,
          contractPrice: item.contractPrice,
        }))

      const sortedServicesByType = orderBy(extractedServices, [(service) => service.type])
      setSelectedService(sortedServicesByType.length > 0 ? sortedServicesByType[0].id : null)
      setServices(sortedServicesByType)

      setIsLoading(false)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.get-services' }))
      logExceptionInSentry(error)
    }
  }, [intl, resourceOwner.id])

  const getCalculatedPeriod = useCallback(async () => {
    try {
      const period = await apiRevenues.getRevenueCalculatedPeriod(resourceOwner.id)

      period.forEach((servicePeriod) => {
        setCalculatedPeriodByService((prev) => ({
          ...prev,
          [servicePeriod.serviceId]: {
            calculatedSincePtu: servicePeriod.calculatedSincePtu,
            calculatedUntilPtu: servicePeriod.calculatedUntilPtu,
          },
        }))
      })

      loadResourceOwnerServices()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.loading-revenue-period' }))
      logExceptionInSentry(error)
    }
  }, [intl, loadResourceOwnerServices, resourceOwner.id])

  useEffect(() => {
    getCalculatedPeriod()
  }, [getCalculatedPeriod])

  useEffect(() => {
    if (!isLoading) {
      const foundPeriodForSelectedService = calculatedPeriodByService[selectedService]

      // There is a calculated period for this service, so mount the semesters
      if (foundPeriodForSelectedService) {
        const { calculatedSincePtu, calculatedUntilPtu } = foundPeriodForSelectedService
        setSemesters(getAllSemestersBetweenDates(calculatedSincePtu, calculatedUntilPtu).reverse())
      } else {
        setShowNoRevenueAvailable(true)
      }
    }
  }, [calculatedPeriodByService, isLoading, selectedService])

  function changeSelectedService(newSelectedService) {
    if (newSelectedService !== selectedService) {
      setSelectedService(parseInt(newSelectedService, 10))
      setSemesters([])
      setShowNoRevenueAvailable(false)
    }
  }

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    )
  }

  return (
    <Tabs centered destroyInactiveTabPane defaultActiveKey={services[0].id} onChange={changeSelectedService}>
      {services.map((service) => (
        <TabPane key={service.id} tab={service.name}>
          <div className={styles.container}>
            {showNoRevenueAvailable ? (
              <p className={styles.noValue} data-testid="no-values-calculated-message">
                <FormattedMessage id="app.resource-owner.revenue.no-value" />
              </p>
            ) : (
              semesters.map((semester, index) => (
                <Semester key={semester} selectedService={selectedService} semester={semester} semesterIndex={index} />
              ))
            )}
          </div>
        </TabPane>
      ))}
    </Tabs>
  )
}

export default Revenues
