import { Button, DatePicker, message, Select, Space } from 'antd'
import { orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiAnalogControlPoints from 'api/analog-control-points'
import * as apiServiceRule from 'api/service-rules'
import { DATE_FORMAT } from 'constants/date'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { logExceptionInSentry } from 'util/error-message'
import { convertFromUTCToMomentDateUsingTimezone } from 'util/time-utils'

import ModalDetails from 'components/ProductsServices/AFRRService/ModalDetails'
import ModalEditor from 'components/ProductsServices/AFRRService/ModalEditor'
import Table from 'components/ProductsServices/AFRRService/Table'
import Loading from 'components/util/Loading'

import styles from './AFRRService.module.css'

const { Option } = Select

function getAnalogWires(serviceRules) {
  const analogWires = []

  serviceRules.forEach((serviceRule) => {
    if (serviceRule.analogWires && serviceRule.analogWires !== null) {
      serviceRule.analogWires.forEach((serviceRuleWire) => {
        if (serviceRuleWire.analogWire.name) {
          analogWires.push(serviceRuleWire.analogWire.name)
        }
      })
    }
  })

  return analogWires.sort()
}

function AFRRService({ service }) {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const getDefaultResourceFilterValue = useCallback(() => {
    return String(intl.formatMessage({ id: 'app.resource-owner.restrictions.select-resource' }))
  }, [intl])

  const [isLoading, setIsLoading] = useState(true)
  const [filterDate, setFilterDate] = useState('')
  const [filterResource, setFilterResource] = useState(getDefaultResourceFilterValue())
  const [analogControlPoints, setAnalogControlPoints] = useState([])
  const [serviceRules, setServiceRules] = useState([])
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showEditorModal, setShowEditorModal] = useState(false)
  const [modalServiceRule, setModalServiceRule] = useState(null)

  const loadAnalogControlPoints = useCallback(async () => {
    try {
      const data = await apiAnalogControlPoints.getAnalogControlPoints(resourceOwner.id)
      setAnalogControlPoints(data)
    } catch (error) {
      logExceptionInSentry(error)
    } finally {
      setIsLoading(false)
    }
  }, [resourceOwner.id])

  const loadServiceRules = useCallback(async () => {
    setIsLoading(true)

    try {
      const data = await apiServiceRule.getServiceRules(resourceOwner.id, service.id)
      setServiceRules(orderBy(data, ['endAt', 'startAt'], ['desc', 'desc']))

      loadAnalogControlPoints()
    } catch (error) {
      logExceptionInSentry(error)
    }
  }, [loadAnalogControlPoints, resourceOwner.id, service.id])

  useEffect(() => {
    moment.tz.setDefault(resourceOwner.timeZone)
    loadServiceRules()
  }, [loadServiceRules, resourceOwner.timeZone])

  const filteredServiceRules = useMemo(() => {
    const filteredByDate = filterDate
      ? serviceRules.filter((serviceRule) => {
          let startAt = convertFromUTCToMomentDateUsingTimezone(serviceRule.startAt, resourceOwner.timeZone)
          let endAt = convertFromUTCToMomentDateUsingTimezone(serviceRule.endAt, resourceOwner.timeZone)
          return filterDate.isSame(startAt, 'day') || filterDate.isSame(endAt, 'day')
        })
      : [...serviceRules]

    return filterResource !== getDefaultResourceFilterValue()
      ? filteredByDate.filter((serviceRule) => {
          if (!serviceRule.analogWires) return false

          return serviceRule.analogWires.find((serviceRuleWire) => {
            return serviceRuleWire.analogWire.name && serviceRuleWire.analogWire.name === filterResource
          })
        })
      : [...filteredByDate]
  }, [filterDate, filterResource, getDefaultResourceFilterValue, resourceOwner.timeZone, serviceRules])

  function handleAddNewButtonClick() {
    setModalServiceRule(null)
    setShowEditorModal(true)
  }

  function handleResetFilterClick() {
    setFilterDate('')
    setFilterResource(getDefaultResourceFilterValue())
  }

  function handleEditButtonClick() {
    setShowDetailsModal(false)
    setShowEditorModal(true)
  }

  async function handleDeleteButtonClick(rule) {
    try {
      await apiServiceRule.deleteServiceRule(resourceOwner.id, service.id, rule.id)
      message.success(intl.formatMessage({ id: 'app.notification.success.delete-rule' }))
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.delete-rule' }))
      logExceptionInSentry(error)
    } finally {
      handleHideModals({ needsRefresh: true })
    }
  }

  function handleHideModals({ needsRefresh = false }) {
    setShowEditorModal(false)
    setShowDetailsModal(false)

    if (needsRefresh) {
      loadServiceRules()
    }
  }

  function handleRowClick(id) {
    const foundServiceRule = serviceRules.find((serviceRule) => serviceRule.id === id)
    setModalServiceRule(foundServiceRule)
    setShowDetailsModal(true)
  }

  if (isLoading) {
    return <Loading />
  }

  const resourceOptions = uniqBy([getDefaultResourceFilterValue(), ...getAnalogWires(serviceRules)])

  return (
    <div aria-label="aFRR service">
      <header className={styles.header}>
        <Space>
          <Button type="primary" onClick={handleAddNewButtonClick}>
            <FormattedMessage id="app.resource-owner.products-services.new-rule" />
          </Button>
        </Space>
        <div className={styles.filter}>
          <div>
            <DatePicker
              format={(current) => moment(current).format(DATE_FORMAT)}
              value={filterDate}
              style={{ width: '100%' }}
              onChange={setFilterDate}
            />
          </div>
          <div>
            <Select style={{ width: '100%' }} value={filterResource} onChange={setFilterResource}>
              {resourceOptions.map((resource) => (
                <Option key={resource} value={resource}>
                  {resource}
                </Option>
              ))}
            </Select>
          </div>
          <Button type="default" onClick={handleResetFilterClick}>
            <FormattedMessage id="app.resource-owner.restrictions.reset-filter" />
          </Button>
        </div>
      </header>
      <Table
        serviceRules={filteredServiceRules}
        resourceOwnerId={resourceOwner.id}
        timeZone={resourceOwner.timeZone}
        onRowClick={handleRowClick}
        onUpdate={loadServiceRules}
      />
      {showDetailsModal && (
        <ModalDetails
          rule={modalServiceRule}
          timeZone={resourceOwner.timeZone}
          onCloseClick={handleHideModals}
          onDeleteClick={() => handleDeleteButtonClick(modalServiceRule)}
          onEditClick={handleEditButtonClick}
        />
      )}
      {showEditorModal && (
        <ModalEditor
          analogControlPoints={analogControlPoints}
          resourceOwnerId={resourceOwner.id}
          rule={modalServiceRule}
          service={service}
          timeZone={resourceOwner.timeZone}
          onHide={() => handleHideModals({ needsRefresh: true })}
        />
      )}
    </div>
  )
}

export default AFRRService
