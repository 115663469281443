/* eslint-disable newline-per-chained-call */
import moment from 'moment-timezone'
import { Moment } from 'moment'

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'

export const convertFromUTCToMomentDateUsingTimezone = (date: Moment | string, timezone: string): Moment => {
  const dateInUTC = moment.utc(date, true)
  return moment(dateInUTC).tz(timezone)
}

export const convertToUtcUsingTimezone = (date: Moment | string, timezone: string): string => {
  const dateFormatted = moment(date).format(dateFormat)
  return moment.tz(dateFormatted, timezone).utc().format(dateFormat)
}

export function getMomentDateForStartOfFutureDaysUsingTimezone(daysIntoFuture: number, timezone: string): Moment {
  return moment.tz(timezone).add(daysIntoFuture, 'days').startOf('day')
}

type getTimesProps = {
  isStartTime: boolean
  isInclusive?: boolean
  ptuRange: number
}

export function getTimes({ isStartTime, isInclusive, ptuRange }: getTimesProps) {
  const times = []

  if (isStartTime) {
    let nextTime = moment().startOf('day')

    while (moment(nextTime).isBefore(moment().endOf('day'))) {
      times.push(moment(nextTime).format('HH:mm'))
      nextTime = moment(nextTime).add(ptuRange, 'minutes')
    }

    return times
  }

  let nextTime = moment().startOf('day').add(ptuRange, 'minutes')

  while (moment(nextTime).isBefore(moment().endOf('day').add(ptuRange, 'minutes'))) {
    times.push(isInclusive ? moment(nextTime).format('HH:mm') : moment(nextTime).subtract(1, 'minutes').format('HH:mm'))
    nextTime = moment(nextTime).add(ptuRange, 'minutes')
  }

  if (times[times.length - 1] === '00:00') {
    times[times.length - 1] = '23:59'
  }

  return times
}

export function areDatesTheSameDay(firstDate: Moment, secondDate: Moment) {
  return moment(firstDate).isSame(secondDate, 'day')
}

export function areDatesInTheSameMonth(firstDate: Moment, secondDate: Moment) {
  return moment(firstDate).month() === moment(secondDate).month()
}

export function areDatesInTheSameYear(firstDate: Moment, secondDate: Moment) {
  return moment(firstDate).year() === moment(secondDate).year()
}
