import { Checkbox } from 'antd'
import { FormattedMessage } from 'react-intl'

import SelectAllWiresCheckbox from 'components/ProductsServices/SelectAllWiresCheckbox'
import FormattedConsumption from 'components/util/FormattedConsumption'

function getWireTotalCapacity(wire) {
  if (wire.maxPower === null || wire.minPower === null) {
    return 0
  }

  return wire.maxPower - wire.minPower
}

function ModalAnalogWires({ isEdit, selectedAnalogWires = [], wires, onChange }) {
  function handleSelectAllChanged() {
    const hasAllWiresSelected = selectedAnalogWires.length === wires.length

    onChange(
      hasAllWiresSelected
        ? []
        : wires.map((wire) => ({
            analogWireId: wire.id,
            analogWire: wire,
          })),
    )
  }

  function handleChange(wire, event) {
    const newValues = event.target.checked
      ? // Add the new wire into the selected ones
        [
          ...selectedAnalogWires,
          {
            analogWireId: wire.id,
            analogWire: wire,
          },
        ]
      : // Remove the wire from the selected ones
        selectedAnalogWires.filter((selectedAnalogWire) => selectedAnalogWire.analogWireId !== wire.id)

    onChange(newValues)
  }

  return (
    <table className="table table-striped modal-edit-wires">
      <thead>
        <tr>
          <th>
            <SelectAllWiresCheckbox
              isEdit={isEdit}
              selectedWires={selectedAnalogWires}
              wires={wires}
              onChange={handleSelectAllChanged}
            />
          </th>
          <th>
            <FormattedMessage id="app.resource-owner.modal.group-name" />
          </th>
          <th>
            <FormattedMessage id="app.resource-owner.modal.power" />
          </th>
        </tr>
      </thead>
      <tbody>
        {wires.map((wire) => (
          <tr key={wire.id}>
            <td>
              {isEdit && (
                <Checkbox
                  checked={selectedAnalogWires.find((selectedWire) => selectedWire.analogWireId === wire.id)}
                  onChange={(e) => handleChange(wire, e)}
                />
              )}
            </td>
            <td>{wire.name}</td>
            <td>
              <FormattedConsumption consumption={getWireTotalCapacity(wire)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ModalAnalogWires
