import momentTimezone from 'moment-timezone'
import React from 'react'
import { FormattedMessage } from 'react-intl'

class formatDay extends React.Component {
  constructor(timeZone) {
    super()

    this.timeZone = timeZone
  }

  // Timestamp is epoch milliseconds (UTC)
  convertEpochTimestamp(timestamp) {
    return momentTimezone.tz(timestamp, this.timeZone).startOf('day').valueOf()
  }

  convertToUserDateTime(timestamp) {
    const monthInNumber = momentTimezone.tz(timestamp, this.timeZone).format('M')
    const day = momentTimezone.tz(timestamp, this.timeZone).format('D')
    return (
      <span>
        <FormattedMessage id={`app.resource-owner.revenue.month.${monthInNumber}`} />
        <span style={{ display: 'inline-block', marginLeft: '3px' }}>{day}</span>
      </span>
    )
  }
}

export default formatDay
