import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useEnvironment } from 'contexts/environment-context'
import { convertFromWToMW } from 'util/converters'

import FormatDay from 'components/Revenues/FormatDay'
import FormatHalfYear from 'components/Revenues/FormatHalfYear'
import FormatHour from 'components/Revenues/FormatHour'
import FormatMonth from 'components/Revenues/FormatMonth'
import PivotTable from 'components/Revenues/PivotTable'
import TreeNode from 'components/Revenues/TreeNode'
import TreeNodeNL from 'components/Revenues/TreeNodeNL'

const DECIMAL_PRICE_PRECISION = 100

function convertToDecimalPrice(price) {
  return price / DECIMAL_PRICE_PRECISION
}

function getColumns(countryCode) {
  if (countryCode === 'nl') {
    return [
      'app.resource-owner.revenues.column.period',
      'app.resource-owner.revenues.column.capacity',
      'app.resource-owner.revenues.column.contract-price',
      'app.resource-owner.revenues.column.revenue',
    ]
  }
  return [
    'app.resource-owner.revenues.column.period',
    'app.resource-owner.revenues.column.capacity',
    'app.resource-owner.revenues.column.sold-capacity',
    'app.resource-owner.revenues.column.revenue',
  ]
}

function halfYearHasOneMonth(halfYear) {
  return halfYear.children.length === 1
}

function previousHalfYearIsAvailable(root) {
  return root.children.length > 1
}

function rootHasChildren(root) {
  return root.children.length > 0
}

function getHalfYearsToBeExpanded(root) {
  const expandingHalfYears = []
  if (rootHasChildren(root)) {
    const lastHalfYear = root.children[root.children.length - 1]
    expandingHalfYears.push(lastHalfYear.period)

    if (halfYearHasOneMonth(lastHalfYear) && previousHalfYearIsAvailable(root)) {
      const secondLastHalfYear = root.children[root.children.length - 2]
      expandingHalfYears.push(secondLastHalfYear.period)
    }
  }

  return expandingHalfYears
}

function Table({ timeSeries, timeZone, onLoad }) {
  const { countryCode } = useEnvironment()

  const [expandingHalfYears, setExpandingHalfYears] = useState(null)
  const [maxLevel, setMaxLevel] = useState(null)
  const [periodFormattingObjects, setPeriodFormattingObjects] = useState(null)
  const [tree, setTree] = useState(null)

  const columns = useMemo(() => getColumns(countryCode), [countryCode])

  const parseTimeSeries = useCallback(
    (value, index, timeSeriesValues, startAt, stepSize) => {
      const zeroValue = 0
      const row = {
        timestamp: moment
          .utc(startAt)
          .add(stepSize * index, 'milliseconds')
          .valueOf(),
        capacity: convertFromWToMW(value),
        revenue: convertToDecimalPrice(timeSeriesValues[2][index]),
        contractPrice: timeSeriesValues.length > 3 ? convertToDecimalPrice(timeSeriesValues[3][index]) : zeroValue,
      }
      if (countryCode !== 'nl') {
        row.soldCapacity = convertFromWToMW(timeSeriesValues[1][index])
      }
      return row
    },
    [countryCode],
  )

  const formatData = useCallback(() => {
    let root
    const formatHalfYear = new FormatHalfYear(timeZone)
    const formatMonth = new FormatMonth(timeZone)
    const formatDay = new FormatDay(timeZone)
    const formatHour = new FormatHour(timeZone)
    const periodFormattingObjects = [formatHalfYear, formatMonth, formatDay, formatHour]
    const currentLevel = 0

    if (countryCode === 'nl') {
      root = new TreeNodeNL('root', currentLevel)
    } else {
      const rootContractPrice = 0
      root = new TreeNode('root', currentLevel, rootContractPrice)
    }

    if (timeSeries.values != null && timeSeries.values[0] != null) {
      for (let index = 0; index < timeSeries.values[0].length; index += 1) {
        const value = timeSeries.values[0][index]
        const row = parseTimeSeries(value, index, timeSeries.values, timeSeries.startAt, timeSeries.stepSize)
        if (countryCode === 'nl') {
          root.updateTreeNode(periodFormattingObjects, row.timestamp, row.capacity, row.contractPrice, row.revenue)
        } else {
          root.updateTreeNode(
            periodFormattingObjects,
            row.timestamp,
            row.capacity,
            row.soldCapacity,
            row.contractPrice,
            row.revenue,
          )
        }
      }
    }

    // Update the information on the semester header
    onLoad(root)

    setTree(root)
    setExpandingHalfYears(getHalfYearsToBeExpanded(root))
    setPeriodFormattingObjects(periodFormattingObjects)
    setMaxLevel(periodFormattingObjects.length - 1)
  }, [countryCode, onLoad, parseTimeSeries, timeSeries.startAt, timeSeries.stepSize, timeSeries.values, timeZone])

  useEffect(() => {
    formatData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!columns || !tree) {
    return null
  }

  return (
    <PivotTable
      columns={columns}
      expandingHalfYears={expandingHalfYears}
      maxLevel={maxLevel}
      periodFormattingObjects={periodFormattingObjects}
      tree={tree}
    />
  )
}

export default Table
