import antdNLLocale from 'antd/lib/locale/nl_NL'
import antdFiLocale from 'antd/lib/locale/fi_FI'
import antdSvLocale from 'antd/lib/locale/sv_SE'
import antdEnLocale from 'antd/lib/locale/en_GB'

import enMessages from './translation/en'
import fiMessages from './translation/fi'
import nlMessages from './translation/nl'
import svMessages from './translation/sv'

type MapMessage = {
  [language: string]: {
    [key: string]: string
  }
}

export const messages: MapMessage = {
  en: enMessages,
  fi: fiMessages,
  nl: nlMessages,
  sv: svMessages,
}

type MapAntdLocales = {
  [language: string]: any
}

export const antdLocales: MapAntdLocales = {
  en: antdEnLocale,
  fi: antdFiLocale,
  nl: antdNLLocale,
  sv: antdSvLocale,
}
