import * as Sentry from '@sentry/react'

export function isApiError(error: any) {
  return error && Boolean(error.response)
}

function hasPayload(error: any) {
  return error && error.response && error.response.config && Boolean(error.response.config.data)
}

export function isTokenExpired(error: any) {
  return error && error?.response?.data?.errorCode === 'token-expired'
}

export function isAxiosRequestCanceled(error: any) {
  return error && error?.message === 'config is undefined'
}

function isNetworkError(error: any): boolean {
  const regex = /network error/i
  return regex.test(error)
}

export function logExceptionInSentry(error: any) {
  if (isNetworkError(error)) return

  if (!isApiError(error)) {
    Sentry.captureException(error, {
      extra: {},
    })

    return
  }

  if (isTokenExpired(error)) return

  Sentry.captureException(error, {
    extra: hasPayload(error)
      ? {
          apiResponse: error.response.data,
          payloadSent: error.response.config.data,
        }
      : {
          apiResponse: error.response.data,
        },
  })
}

export function logCognitoExceptionInSentry(error: any) {
  Sentry.captureException(error, {
    extra: {
      apiResponse: error,
    },
  })
}

export function logMessageInSentry(message: string, context: any = {}) {
  Sentry.captureMessage(message, context)
}
