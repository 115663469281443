import { Button, message, Space } from 'antd'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'

import * as apiServiceRules from 'api/service-rules'
import { logExceptionInSentry } from 'util/error-message'

import FormattedDateFromUTCToTimezone from 'components/FormattedDateFromUTCToTimezone'
import Resources from 'components/ProductsServices/Resources'
import TimeOfDayDisplay from 'components/TimeOfDayDisplay'
import FormattedStatus from 'components/util/FormattedRestrictionStatus'
import NoData from 'components/util/NoData'

const PRICE_PRECISION = 10000
const MEGAWATTS_PRECISION = 1000000

function ServiceRulesTable({ limitValues, resourceOwnerId, serviceRules, timeZone, onRowClick, onUpdate }) {
  const intl = useIntl()

  async function deactivate(event, serviceId, serviceRuleId) {
    event.stopPropagation()
    try {
      await apiServiceRules.deactivate(resourceOwnerId, serviceId, serviceRuleId)

      message.success(intl.formatMessage({ id: 'app.notification.success.deactivate-rule' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.deactivate-rule' }))
      logExceptionInSentry(error)
    }
  }

  async function activate(event, serviceId, serviceRuleId) {
    event.stopPropagation()
    try {
      await apiServiceRules.activate(resourceOwnerId, serviceId, serviceRuleId)

      message.success(intl.formatMessage({ id: 'app.notification.success.activate-rule' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.activate-rule' }))
      logExceptionInSentry(error)
    }
  }

  function getLimitPrecision(limit) {
    if (limitValues.label === 'app.resource-owner.products-services.modal.limit.price') {
      return limit / PRICE_PRECISION
    }
    return limit / MEGAWATTS_PRECISION
  }

  return (
    <div className="table-container-fluid" style={{ marginTop: '20px' }}>
      {serviceRules.length === 0 ? (
        <NoData />
      ) : (
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.start-date" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.end-date" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.time-of-day" />
              </th>
              <th>
                <FormattedMessage id={limitValues.label} />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.resources" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.status" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {serviceRules.map((serviceRule) => (
              <tr key={serviceRule.id} onClick={() => onRowClick(serviceRule.id)}>
                <td>
                  <FormattedDateFromUTCToTimezone date={serviceRule.startAt} timezone={timeZone} />
                </td>
                <td>
                  <FormattedDateFromUTCToTimezone date={serviceRule.endAt} timezone={timeZone} />
                </td>
                <td>
                  <TimeOfDayDisplay endAt={serviceRule.timeEndAt} startAt={serviceRule.timeStartAt} />
                </td>
                <td>
                  <Space size="small">
                    <FormattedNumber value={getLimitPrecision(serviceRule.limitValue)} maximumFractionDigits={2} />
                    <FormattedMessage id={limitValues.measurement} />
                  </Space>
                </td>
                <td>
                  <Resources wires={serviceRule.relayWires} wireType="relayWire" />
                </td>
                <td>
                  <FormattedStatus activeAt={serviceRule.activeAt} status={serviceRule.status} />
                </td>
                <td>
                  {/* TODO: After the aFRR is converted, change to ant design popconfirm  */}
                  {serviceRule.status === 'active' && (
                    <Button
                      type="primary"
                      style={{ width: '100%' }}
                      onClick={(e) => deactivate(e, serviceRule.serviceId, serviceRule.id)}
                    >
                      <FormattedMessage id="app.resource-owner.restrictions.deactivate" />
                    </Button>
                  )}
                  {serviceRule.status === 'deactivated' && (
                    <Button
                      type="primary"
                      style={{ width: '100%' }}
                      onClick={(e) => activate(e, serviceRule.serviceId, serviceRule.id)}
                    >
                      <FormattedMessage id="app.resource-owner.restrictions.activate" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default ServiceRulesTable
