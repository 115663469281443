import { Button, message } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiRestrictions from 'api/restrictions'
import { DATE_IN_ISO_8601_FORMAT, DATE_TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'
import { useFeatureToggle } from 'contexts/feature-toggle-context'
import { logExceptionInSentry } from 'util/error-message'
import { convertFromUTCToMomentDateUsingTimezone } from 'util/time-utils'

import FormattedDateFromUTCToTimezone from 'components/FormattedDateFromUTCToTimezone'
import DisplayDateAndTimeOfRestriction from 'components/ProductsServices/Restrictions/DisplayDateAndTimeOfRestriction'
import Resources from 'components/ProductsServices/Resources'
import TimeOfDayDisplay from 'components/TimeOfDayDisplay'
import FormattedRestrictionStatus from 'components/util/FormattedRestrictionStatus'
import NoData from 'components/util/NoData'

const RestrictionsTable = ({ resourceOwnerId, restrictions, timeZone, onRowClick, onUpdate }) => {
  const intl = useIntl()
  const { isEnabled } = useFeatureToggle()

  async function deactivate(event, restrictionId) {
    event.stopPropagation()
    try {
      await apiRestrictions.deactivate(resourceOwnerId, restrictionId)

      message.success(intl.formatMessage({ id: 'app.notification.success.deactivate-restriction' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.deactivate-restriction' }))
      logExceptionInSentry(error)
    }
  }

  async function activate(event, restrictionId) {
    event.stopPropagation()
    try {
      await apiRestrictions.activate(resourceOwnerId, restrictionId)

      message.success(intl.formatMessage({ id: 'app.notification.success.activate-restriction' }))
      onUpdate()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.activate-restriction' }))
      logExceptionInSentry(error)
    }
  }

  return (
    <div className="table-container-fluid" style={{ marginTop: '20px' }}>
      {restrictions.length === 0 ? (
        <NoData />
      ) : (
        <table>
          <thead>
            <tr>
              {isEnabled('PARTNER_UI_RESTRICTIONS_CHANGE_FREQUENCY_ENABLED') ? (
                <th>
                  <FormattedMessage id="app.resource-owner.restrictions.date-and-time" />
                </th>
              ) : (
                <>
                  <th>
                    <FormattedMessage id="app.resource-owner.restrictions.start-date" />
                  </th>
                  <th>
                    <FormattedMessage id="app.resource-owner.restrictions.end-date" />
                  </th>
                  <th>
                    <FormattedMessage id="app.resource-owner.restrictions.time-of-day" />
                  </th>
                </>
              )}
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.resources" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.restrictions.updated-on" />
              </th>
              <th>
                <FormattedMessage id="app.resource-owner.status" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {restrictions.map((restriction) => (
              <tr key={restriction.id} onClick={() => onRowClick(restriction.id)}>
                {isEnabled('PARTNER_UI_RESTRICTIONS_CHANGE_FREQUENCY_ENABLED') ? (
                  <td>
                    <DisplayDateAndTimeOfRestriction
                      endAt={restriction.endAt}
                      startAt={restriction.startAt}
                      timeEndAt={restriction.timeEndAt}
                      timeStartAt={restriction.timeStartAt}
                      timezone={timeZone}
                      type={restriction.type}
                    />
                  </td>
                ) : (
                  <>
                    <td>
                      <FormattedDateFromUTCToTimezone date={restriction.startAt} timezone={timeZone} />
                    </td>
                    <td>
                      <FormattedDateFromUTCToTimezone date={restriction.endAt} timezone={timeZone} />
                    </td>
                    <td>
                      <TimeOfDayDisplay endAt={restriction.timeEndAt} startAt={restriction.timeStartAt} />
                    </td>
                  </>
                )}
                <td>
                  <Resources wires={restriction.relayWires} wireType="relayWire" />
                </td>
                <td aria-label="Updated on">
                  <FormattedDateFromUTCToTimezone
                    date={restriction.updatedAt}
                    format={DATE_TIME_WITHOUT_SECONDS_FORMAT}
                    timezone={timeZone}
                  />
                </td>
                <td>
                  <FormattedRestrictionStatus
                    status={restriction.status}
                    activeAt={
                      convertFromUTCToMomentDateUsingTimezone(restriction.startAt, timeZone).format(
                        DATE_IN_ISO_8601_FORMAT,
                      ) +
                      ' ' +
                      restriction.timeStartAt
                    }
                  />
                </td>
                <td>
                  {/* TODO: After the service rules and aFRR are converted, change to ant design popconfirm  */}
                  {restriction.status === 'active' && (
                    <Button type="primary" style={{ width: '100%' }} onClick={(e) => deactivate(e, restriction.id)}>
                      <FormattedMessage id="app.resource-owner.restrictions.deactivate" />
                    </Button>
                  )}
                  {restriction.status === 'deactivated' && (
                    <Button type="primary" style={{ width: '100%' }} onClick={(e) => activate(e, restriction.id)}>
                      <FormattedMessage id="app.resource-owner.restrictions.activate" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default RestrictionsTable
