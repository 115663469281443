import { useEffect } from 'react'

export default function usePooling(
  fetchFn: () => void,
  poolingFetchFn?: () => void,
  poolInterval: number = 1000,
  dependencies: any[] = [],
) {
  useEffect(() => {
    fetchFn()
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const intervalPID = setInterval(() => {
      if (poolingFetchFn) {
        poolingFetchFn()
      } else {
        fetchFn()
      }
    }, poolInterval)

    return () => {
      clearInterval(intervalPID)
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps
}
