import { DownOutlined } from '@ant-design/icons'
import { Grid, Menu } from 'antd'
import { FormattedMessage } from 'react-intl'

import { useResourceOwner } from 'contexts/resource-owner-context'

import styles from './Account.module.css'

const { useBreakpoint } = Grid
const { SubMenu } = Menu

function getUsername(resourceOwner: any) {
  if (!resourceOwner.username) return <FormattedMessage id="app.resource-owner.navbar.account" />

  return resourceOwner.username
}

type AccountProps = {
  handleShowSettingsPopup: (visible: any) => void
  onLogoutClick: () => void
}

function Account({ handleShowSettingsPopup, onLogoutClick }: AccountProps) {
  const screens = useBreakpoint()
  const { resourceOwner } = useResourceOwner()

  return (
    <Menu className={styles.container} mode={screens.lg ? 'horizontal' : 'inline'} theme="dark">
      <SubMenu
        key="subMenu"
        icon={screens.lg ? <DownOutlined /> : ''}
        popupClassName={styles.submenu}
        popupOffset={[-20, 0]}
        title={<span className={styles.account}>{getUsername(resourceOwner)}</span>}
      >
        <Menu.Item key="account:settings" onClick={handleShowSettingsPopup}>
          <FormattedMessage id="app.resource-owner.navbar.settings" />
        </Menu.Item>
        <Menu.Item key="account:logout" onClick={onLogoutClick}>
          <FormattedMessage id="app.login.logout" />
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}

export default Account
