import { message, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { orderBy } from 'lodash'

import * as apiResourceOwner from 'api/resource-owner'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { logExceptionInSentry } from 'util/error-message'

import Loading from 'components/util/Loading'
import AFRRService from 'components/ProductsServices/AFRRService'
import Restrictions from 'components/ProductsServices/Restrictions'
import ServiceRules from 'components/ProductsServices/ServiceRules'

import styles from './products-services.module.css'

const { TabPane } = Tabs

function ProductsServices() {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const { id: resourceOwnerId } = resourceOwner

  const [loadedServices, setLoadedServices] = useState(false)
  const [services, setServices] = useState([])

  useEffect(() => {
    async function loadServices() {
      try {
        const data = await apiResourceOwner.getServices(resourceOwnerId)

        const extractedServices = data
          .filter((item) => !item.isDisabled && item.service.type !== 'notify')
          .map((item) => ({
            ...item.service,
            contractPrice: item.contractPrice,
          }))

        const sortedServicesByType = orderBy(extractedServices, [(service) => service.type])
        setServices(sortedServicesByType)
        setLoadedServices(true)
      } catch (error) {
        message.error(intl.formatMessage({ id: 'app.notification.error.get-services' }))
        logExceptionInSentry(error)
      }
    }

    loadServices()
  }, [intl, resourceOwnerId])

  if (!loadedServices) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    )
  }

  return (
    <Tabs centered defaultActiveKey="1">
      {services.map((_, index) => {
        const service = services[index]
        const key = index + 1
        const tab = <FormattedMessage id={`app.resource-owner.products-services.navbar.${service.type}`} />

        if (service.reserve === 'afrr') {
          return (
            <TabPane key={key} tab={tab}>
              <AFRRService service={service} />
            </TabPane>
          )
        }

        if (service.isReserve) {
          return (
            <TabPane key={key} tab={tab}>
              <Restrictions service={service} />
            </TabPane>
          )
        }

        return (
          <TabPane key={key} tab={tab}>
            <ServiceRules service={service} />
          </TabPane>
        )
      })}
    </Tabs>
  )
}

export default ProductsServices
