import axios, { AxiosResponse } from 'axios'

import { includeAuthorizationTokenOnRequest } from '../interceptors'
import type { FeatureToggles } from 'types/feature-toggle'

export const GET_FEATURE_TOGGLES_API_ID = 'GET_FEATURE_TOGGLES'

export async function getFeatureToggles(environmentName: string | null, apiBaseUrl: string): Promise<FeatureToggles> {
  if (environmentName === 'development') {
    const featureToggles: FeatureToggles = {
      toggles: [
        {
          name: 'TEST_TOGGLE_FOR_FRONTEND',
          enabled: true,
          impressionData: false,
        },
        {
          name: 'PARTNER_UI_RESTRICTIONS_CHANGE_FREQUENCY_ENABLED',
          enabled: true,
          impressionData: false,
        },
      ],
    }

    return Promise.resolve(featureToggles)
  }

  const client = axios.create({
    baseURL: apiBaseUrl,
    timeout: 5000,
  })

  includeAuthorizationTokenOnRequest(client)

  const response: AxiosResponse<FeatureToggles> = await client.get('/api/v1/features/proxy', {
    headers: {
      'Extra-authorization': 'sympower-unleash-proxy',
    },
  })

  return response.data
}
