import { CheckCircleOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import moment from 'moment'

import { useResourceOwner } from 'contexts/resource-owner-context'

import styles from './Tag.module.css'

const { CheckableTag } = Tag

function WeekdayTag({ tag, selectedDays, handleDayChange, readOnly }) {
  const { resourceOwner } = useResourceOwner()

  const weekDays = moment.localeData(resourceOwner.uiLanguage).weekdays()

  if (!readOnly) {
    return (
      <CheckableTag
        aria-label="Day tag"
        checked={selectedDays.includes(tag)}
        className={styles.tag}
        onChange={(checked) => handleDayChange(tag, checked)}
      >
        {weekDays[tag]}
      </CheckableTag>
    )
  }

  if (selectedDays.includes(tag)) {
    return (
      <Tag className={styles.tag} icon={<CheckCircleOutlined />} aria-label="Day tag">
        {weekDays[tag]}
      </Tag>
    )
  }

  return (
    <Tag className={styles.unselectedTag} aria-label="Day tag">
      {weekDays[tag]}
    </Tag>
  )
}

export default WeekdayTag
