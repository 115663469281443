/* eslint-disable max-len */
const messages = {
  'app.login.logout': 'Logout',

  'app.edit': 'EDIT',
  'app.save': 'SAVE',
  'app.cancel': 'CANCEL',
  'app.close': 'CLOSE',
  'app.ok': 'OK',
  'app.delete': 'DELETE',

  'app.resource-owner.no-data-to-display': 'There is no data to display.',
  'app.resource-owner.timezone': 'Timezone',

  'app.resource-owner.navbar.account': 'Account',
  'app.resource-owner.navbar.dashboard': 'Home',
  'app.resource-owner.navbar.activationlog': 'Activation log',
  'app.resource-owner.navbar.restrictions': 'Restrictions',
  'app.resource-owner.navbar.products-services': 'Products & Services',
  'app.resource-owner.navbar.revenues': 'Revenues',
  'app.resource-owner.navbar.settings': 'Settings',
  'app.resource-owner.products-services.navbar.fcrd-up': 'FCR-D up',
  'app.resource-owner.products-services.navbar.max-price': 'Spot Price',
  'app.resource-owner.products-services.navbar.fcrd-down': 'FCR-D down',
  'app.resource-owner.products-services.navbar.nordics-fcrd-down-static': 'Nordics FCR-D down static',
  'app.resource-owner.products-services.navbar.nordics-fcrn': 'Nordics FCR-N',
  'app.resource-owner.products-services.navbar.fcrn': 'FCR-N',
  'app.resource-owner.products-services.navbar.ffr-up': 'FFR',
  'app.resource-owner.products-services.navbar.mfrrda-up': 'mFRRda up',
  'app.resource-owner.products-services.navbar.mfrrda-down': 'mFRRda down',
  'app.resource-owner.products-services.navbar.afrr-up': 'aFRR up',
  'app.resource-owner.products-services.navbar.afrr-down': 'aFRR down',
  'app.resource-owner.products-services.navbar.mfrr-up': 'MFRR up',
  'app.resource-owner.products-services.navbar.mfrr-down': 'MFRR down',
  'app.resource-owner.products-services.navbar.load-cur': 'Load Curtailment',
  'app.resource-owner.products-services.contract-date': 'Contract date',
  'app.resource-owner.products-services.shut-offs': 'Shut offs',

  'app.resource-owner.dashboard.current-power-consumption': 'Current power consumption',
  'app.resource-owner.dashboard.graph.power-consumption': 'Power consumption',
  'app.resource-owner.dashboard.graph.time-period': 'Time period',
  'app.resource-owner.dashboard.graph.max-consumption': 'Max consumption',
  'app.resource-owner.dashboard.graph.average-consumption': 'Average consumption',
  'app.resource-owner.dashboard.graph.min-consumption': 'Min consumption',
  'app.resource-owner.dashboard.phase': 'Phase',
  'app.resource-owner.dashboard.welcome-to': 'Welcome to',

  'app.resource-owner.modal.affected-resources': 'AFFECTED RESOURCES',
  'app.resource-owner.modal.group-name': 'Name',
  'app.resource-owner.modal.section': 'Section',
  'app.resource-owner.modal.lamp-position.top': 'Top',
  'app.resource-owner.modal.lamp-position.between': 'Between',
  'app.resource-owner.modal.lamp-position.all': 'All',
  'app.resource-owner.modal.lamp-position.unknown': 'Unknown',
  'app.resource-owner.modal.power': 'Power',
  'app.resource-owner.modal.device-type.led0': 'LED (0 min)',
  'app.resource-owner.modal.device-type.hps2': 'HPS (2 min)',
  'app.resource-owner.modal.device-type.hps3': 'HPS (3 min)',
  'app.resource-owner.modal.device-type.hps5': 'HPS (5 min)',
  'app.resource-owner.modal.device-type.hps10': 'HPS (10 min)',
  'app.resource-owner.modal.device-type.hps15': 'HPS (15 min)',
  'app.resource-owner.modal.device-type.hps20': 'HPS (20 min)',
  'app.resource-owner.modal.device-type.hps30': 'HPS (30 min)',
  'app.resource-owner.modal.device-type.hps60': 'HPS (60 min)',
  'app.resource-owner.modal.device-type.chamber0': 'Chamber (0 min)',
  'app.resource-owner.modal.device-type.chamber3': 'Chamber (3 min)',
  'app.resource-owner.modal.device-type.channel0': 'Channel (0 min)',
  'app.resource-owner.modal.device-type.channel3': 'Channel (3 min)',
  'app.resource-owner.modal.device-type.other0': 'Other (0 min)',
  'app.resource-owner.modal.device-type.other3': 'Other (3 min)',

  'app.resource-owner.more-results': '{count} more',

  'app.resource-owner.activation-event-log.event-started': 'Event started',
  'app.resource-owner.activation-event-log.event-ended': 'Event ended',
  'app.resource-owner.activation-event-log.event-duration': 'Duration',
  'app.resource-owner.activation-event-log.service': 'Service',
  'app.resource-owner.activation-event-log.modal.activated-at': 'Activated at',
  'app.resource-owner.activation-event-log.modal.deactivated-at': 'Deactivated at',
  'app.resource-owner.activation-event-log.modal.activation-duration': 'Activation duration',

  'app.resource-owner.restrictions.activate': 'ENABLE',
  'app.resource-owner.restrictions.deactivate': 'DISABLE',
  'app.resource-owner.restrictions.start-date': 'Start date',
  'app.resource-owner.restrictions.end-date': 'End date',
  'app.resource-owner.restrictions.time-of-day': 'Time of day',
  'app.resource-owner.restrictions.recurrence': 'Recurrence',
  'app.resource-owner.restrictions.recurrence.every-day': 'Every day',
  'app.resource-owner.restrictions.exception-dates': 'Exception dates',
  'app.resource-owner.restrictions.resources': 'Resources',
  'app.resource-owner.restrictions.resources-no-name': '{count, plural, one{# resource} other{# resources}}',
  'app.resource-owner.restrictions.updated-on': 'Updated on',
  'app.resource-owner.from': 'From',
  'app.resource-owner.to': 'To',
  'app.resource-owner.status': 'Status',
  'app.resource-owner.recurrence': 'Recurrence',
  'app.resource-owner.exception-dates': 'Exception dates',
  'app.resource-owner.add-exception-date': 'Add exception date',
  'app.resource-owner.no-exception-dates-yet':
    'No exception dates yet. All dates will be considered in the selected period.',
  'app.resource-owner.restrictions.status.active': 'Enabled',
  'app.resource-owner.restrictions.status.deactivated': 'Disabled',
  'app.resource-owner.restrictions.status.expired': 'Expired',
  'app.resource-owner.restrictions.status.waiting': 'Waiting',
  'app.resource-owner.restrictions.status.waiting-will-become-active':
    'Waiting, will become active at {activeAtDateTime}',
  'app.resource-owner.restrictions.new-restriction': 'NEW RESTRICTION',
  'app.resource-owner.restrictions.lamp-type': 'Type',
  'app.resource-owner.restrictions.lamp-placement': 'Placement',
  'app.resource-owner.restrictions.choose-affected-lamp-groups': 'CHOOSE AFFECTED GROUPS',
  'app.resource-owner.restrictions.end-date-before-start-date-error': 'End date should not be before start date',
  'app.resource-owner.restrictions.end-time-before-start-time-error': 'End time should not be before start time',
  'app.resource-owner.restrictions.end-time-equals-start-time-error': 'End time should not be the same as start time',
  'app.resource-owner.restrictions.availability-at-least-one-hour-error':
    'Resources should be available for at least 1 hour',
  'app.resource-owner.restrictions.select-resource': 'Select resource',
  'app.resource-owner.restrictions.reset-filter': 'RESET FILTER',
  'app.resource-owner.restrictions.frequency.does_not_repeat': 'Does not repeat',
  'app.resource-owner.restrictions.frequency.repeat_daily': 'Repeat daily',
  'app.resource-owner.restrictions.end-time': 'End time',
  'app.resource-owner.restrictions.start-time': 'Start time',
  'app.resource-owner.restrictions.all-day': 'All day',
  'app.resource-owner.restrictions.date-and-time': 'Date and time',

  'app.resource-owner.products-services.afrr.assets': 'Resources',
  'app.resource-owner.products-services.afrr.rules.select-resources': 'SELECT RESOURCES',
  'app.resource-owner.products-services.afrr.rules.selected-resources': 'SELECTED RESOURCES',
  'app.resource-owner.products-services.afrr.modal.limit.price': 'Price (€/MW)',

  'app.resource-owner.products-services.new-rule': 'NEW RULE',
  'app.resource-owner.products-services.manage-scheduled-shut-offs': 'MANAGE SCHEDULED SHUT-OFFS',
  'app.resource-owner.products-services.filter-rule': 'Filter rules:',
  'app.resource-owner.products-services.modal.show-advanced-options': 'Show advanced options',
  'app.resource-owner.products-services.modal.hide-advanced-options': 'Hide advanced options',
  'app.resource-owner.products-services.modal.limit.price': 'Price',
  'app.resource-owner.products-services.modal.limit.consumption': 'Consumption',
  'app.resource-owner.products-services.modal.limit.frequency': 'Frequency',
  'app.resource-owner.products-services.modal.help.text.euro.sign': '€',
  'app.resource-owner.products-services.modal.help.text.mw': 'MW',
  'app.resource-owner.products-services.modal.help.text.hz': 'Hz',
  'app.resource-owner.products-services.modal.off.time.max.length': 'Off-time max length',
  'app.resource-owner.products-services.modal.on.time.min.length': 'On-time min length',
  'app.resource-owner.products-services.modal.max.turn.offs.per.day': 'Max turn offs per day',
  'app.resource-owner.products-services.modal.max.turn.offs.per.week': 'Max turn offs per week',
  'app.resource-owner.products-services.modal.help.text.hours': 'hours',
  'app.resource-owner.products-services.modal.help.text.times': 'times',
  'app.resource-owner.products-services.modal.scheduled-shut-offs': 'SCHEDULED SHUT-OFFs',
  'app.resource-owner.products-services.modal.delete-rule-confirmation': 'Are you sure you want to delete this rule?',
  'app.resource-owner.products-services.modal.delete-rule-yes': 'YES, DELETE RULE',
  'app.resource-owner.products-services.modal.delete-restriction-confirmation':
    'Are you sure you want to delete this restriction?',
  'app.resource-owner.products-services.modal.delete-restriction-yes': 'Yes, delete restriction',
  'app.resource-owner.products-services.limit-price': 'Limit Price',
  'app.resource-owner.products-services.actual-price': 'Actual Price',
  'app.resource-owner.products-services.limit-consumption': 'Limit Consumption',
  'app.resource-owner.products-services.actual-consumption': 'Actual Consumption',
  'app.resource-owner.products-services.enabled': 'Enabled',
  'app.resource-owner.products-services.cancelled': 'Cancelled',

  'app.resource-owner.settings.missing-bank-details':
    'You have not defined your bank account number but we need it in order to pay you. Please fill it in.',
  'app.resource-owner.settings.e-mail-address': 'E-mail address for notifications',
  'app.resource-owner.settings.mobile-number': 'Mobile number for notifications',
  'app.resource-owner.settings.mobile-number.example': 'e.g. +358 45 123 4567',
  'app.resource-owner.settings.preferred-language': 'Language',
  'app.resource-owner.settings.user-information': 'CUSTOMER INFORMATION AND SETTINGS',
  'app.resource-owner.settings.iban': 'Bank account number (IBAN)',
  'app.resource-owner.settings.iban-holder': 'Bank account holder name (company name)',
  'app.resource-owner.settings.notification-preferences': 'Notifications',
  'app.resource-owner.settings.notification-preferences.email': 'E-mail',
  'app.resource-owner.settings.notification-preferences.sms': 'SMS',
  'app.resource-owner.settings.notification-preferences.no-email-address':
    'An e-mail address has to be set to activate e-mail notifications',
  'app.resource-owner.settings.notification-preferences.no-mobile-number':
    'A mobile number has to be set to activate SMS notifications',
  'app.resource-owner.revenues.export-as-csv': 'Export as CSV',
  'app.resource-owner.revenues.column.period': 'Period',
  'app.resource-owner.revenues.column.capacity': 'Capacity (MWh)',
  'app.resource-owner.revenues.column.sold-capacity': 'Sold Capacity (MWh)',
  'app.resource-owner.revenues.column.contract-price': 'Contract Price',
  'app.resource-owner.revenues.column.revenue': 'Revenue',
  'app.resource-owner.revenue.month.1': 'January',
  'app.resource-owner.revenue.month.2': 'February',
  'app.resource-owner.revenue.month.3': 'March',
  'app.resource-owner.revenue.month.4': 'April',
  'app.resource-owner.revenue.month.5': 'May',
  'app.resource-owner.revenue.month.6': 'June',
  'app.resource-owner.revenue.month.7': 'July',
  'app.resource-owner.revenue.month.8': 'August',
  'app.resource-owner.revenue.month.9': 'September',
  'app.resource-owner.revenue.month.10': 'October',
  'app.resource-owner.revenue.month.11': 'November',
  'app.resource-owner.revenue.month.12': 'December',
  // eslint-disable-next-line quotes
  'app.resource-owner.revenues.tooltip': `This amount is an estimate of the revenues and exclude the revenues corresponding to the \
disturbances. At the end of the month, you will get an e-mail of the actual revenues including the revenues corresponding \
to the disturbance during that period.`,
  'app.resource-owner.revenue.no-value': 'There are no values calculated yet for this service.',

  'app.notification.error.login': 'Error logging in',
  'app.notification.error.update-token': 'Error updating token',
  'app.notification.error.session-error': 'Session error',
  'app.notification.error.get-power-consumption': 'Error getting power consumption',
  'app.notification.error.get-restrictions': 'Error getting restrictions',
  'app.notification.error.get-plans': 'Error getting scheduled turn-offs',
  'app.notification.success.save-restriction': 'Successfully saved restriction',
  'app.notification.error.save-restriction': 'Error saving restriction',
  'app.notification.error.get-sections': 'Error getting sections',
  'app.notification.success.user-save': 'Successfully saved user',
  'app.notification.error.user-save': 'Error saving user',
  'app.notification.error.get-resource-owner': 'Error getting resource owner',
  'app.notification.error.get-lamp-resource-owners': 'Error getting resource-owners',
  'app.notification.error.get-activation-events': 'Error getting activation events',

  'app.notification.error.activate-restriction': 'Error activating restriction',
  'app.notification.success.activate-restriction': 'Successfully activated restriction',
  'app.notification.error.deactivate-restriction': 'Error deactivating restriction',
  'app.notification.success.deactivate-restriction': 'Successfully deactivated restriction',
  'app.notification.error.save-restriction-relaywires': 'Error saving restriction relay wires',
  'app.notification.error.delete-restriction-relaywires': 'Error deleting restriction relay wires',
  'app.notification.success.delete-restriction': 'Successfully deleted restriction',
  'app.notification.error.delete-restriction': 'Error deleting restriction',
  'app.notification.error.get-power-consumption-graph': 'Error getting power consumption graph',
  'app.notification.error.loading-revenues': 'Error loading revenues',
  'app.notification.error.loading-revenue-period': 'Error loading revenue calculated period',
  'app.notification.error.get-services': 'Error getting services',
  'app.notification.error.get-rules': 'Error getting rules',
  'app.notification.error.save-rule': 'Error saving rule',
  'app.notification.success.save-rule': 'Successfully saved rule',
  'app.notification.error.activate-rule': 'Error activating rule',
  'app.notification.success.activate-rule': 'Successfully activated rule',
  'app.notification.error.deactivate-rule': 'Error deactivating rule',
  'app.notification.success.deactivate-rule': 'Successfully deactivated rule',
  'app.notification.success.delete-rule': 'Successfully deleted rule',
  'app.notification.error.delete-rule': 'Error deleting rule',
  'app.notification.error.save-rule-relaywires': 'Error saving rule relay wires',
  'app.notification.error.delete-rule-resource': 'Error removing a resource from rule',
  'app.notification.error.rule.empty-price-limit': 'Price should not be empty',
  'app.notification.error.rule.empty-consumption-limit': 'Consumption should not be empty',
  'app.notification.error.rule.empty-frequency-limit': 'Frequency should not be empty',
  'app.notification.error.enable-plan': 'Error enabling plan',
  'app.notification.success.enable-plan': 'Successfully enabled plan',
  'app.notification.error.cancel-plan': 'Error canceling plan',
  'app.notification.success.cancel-plan': 'Successfully canceled plan',
  'app.notification.error.add.resource': 'A resource already has a rule for the same time period: {addedInfo}',
  'app.notification.error.old-password-invalid': 'The old password you have entered is incorrect',

  'app.validation.email.wrong-format': 'Invalid format for e-mail',

  'server.error.token-expired': 'Session expired',
  'server.error.invalid-token': 'Invalid session token',
  'server.error.unexpected-auth-error': 'Unexpected session error',
  'server.error.incorrect-username-password': 'Incorrect username or password',
  'server.error.user-deactivated': 'User is deactivated',
  'server.error.fill-all-password-fields': 'All password fields not filled',
  'server.error.passwords-do-not-match': 'Passwords do not match',
  'server.error.old-password-invalid': 'Old password invalid',
  'server.error.password-min-length': 'Password should be at least 6 characters',
  'server.error.access-denied': 'Access denied',
  'server.error.validation-failed': 'Validation failed',
  'server.error.incorrect-username-or-password': 'Username e/or password incorrect',
}

export default messages
