import { message, Row, Space } from 'antd'
import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiPowerConsumption from 'api/power-consumption'
import { useEnvironment } from 'contexts/environment-context'
import { useResourceOwner } from 'contexts/resource-owner-context'
import usePooling from 'hooks/usePooling'
import { currentTheme } from 'theme/theme-loader'
import { logExceptionInSentry } from 'util/error-message'

import FormattedConsumption from 'components/util/FormattedConsumption'
import Loading from 'components/util/Loading'

import styles from './dashboard.module.css'

const POWER_CONSUMPTION_POOLING_TIME = 1000 * 60

type PowerConsumption = {
  consumption: number
  consumption1: number
  consumption2: number
  consumption3: number
}

function Dashboard() {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const { id: resourceOwnerId } = resourceOwner!
  const { countryCode } = useEnvironment()

  const [hasLoaded, setHasLoaded] = useState(false)
  const [powerConsumption, setPowerConsumption] = useState<PowerConsumption | null>(null)

  const getPowerConsumption = useCallback(async () => {
    try {
      const data = (await apiPowerConsumption.getPowerConsumption(resourceOwnerId)) as unknown as PowerConsumption
      setPowerConsumption(data)
    } catch (error: any) {
      message.error(intl.formatMessage({ id: 'app.notification.error.get-power-consumption' }))
      logExceptionInSentry(error)
    } finally {
      setHasLoaded(true)
    }
  }, [intl, resourceOwnerId])

  usePooling(getPowerConsumption, getPowerConsumption, POWER_CONSUMPTION_POOLING_TIME, [])

  if (!hasLoaded) {
    return <Loading />
  }

  if (countryCode === 'nl') {
    return (
      <div className={styles.container}>
        <Row align="middle" justify="center">
          <div>
            <h3 className={styles.title}>
              <FormattedMessage id="app.resource-owner.dashboard.welcome-to" />
            </h3>
            <img
              className={styles.dashboardLogo}
              src={currentTheme.dashboardConfig.dashboardLogoPath}
              alt={currentTheme.dashboardConfig.productName}
            />
          </div>
        </Row>
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        {powerConsumption && (
          <Row align="middle" justify="center">
            <div>
              <h3 className={styles.title}>
                <FormattedMessage id="app.resource-owner.dashboard.current-power-consumption" />
              </h3>
              <div className={styles.heroTitle}>
                <FormattedConsumption consumption={powerConsumption.consumption} isMegawatts />
              </div>
              <Space size="large">
                <span className={styles.phase}>
                  <FormattedMessage id="app.resource-owner.dashboard.phase" />
                  {' 1: '}
                  <strong>
                    <FormattedConsumption consumption={powerConsumption.consumption1} isMegawatts />
                  </strong>
                </span>
                <span className={styles.phase}>
                  <FormattedMessage id="app.resource-owner.dashboard.phase" />
                  {' 2: '}
                  <strong>
                    <FormattedConsumption consumption={powerConsumption.consumption2} isMegawatts />
                  </strong>
                </span>
                <span className={styles.phase}>
                  <FormattedMessage id="app.resource-owner.dashboard.phase" />
                  {' 3: '}
                  <strong>
                    <FormattedConsumption consumption={powerConsumption.consumption3} isMegawatts />
                  </strong>
                </span>
              </Space>
            </div>
          </Row>
        )}
      </div>
    )
  }
}

export default Dashboard
