import { Button, DatePicker, message, Select } from 'antd'
import { orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiRegulators from 'api/regulators'
import * as apiRestrictions from 'api/restrictions'
import * as apiSections from 'api/sections'
import { DATE_FORMAT } from 'constants/date'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { logExceptionInSentry } from 'util/error-message'

import ModalDetails from 'components/ProductsServices/Restrictions/ModalDetails'
import ModalEditor from 'components/ProductsServices/Restrictions/ModalEditor'
import Table from 'components/ProductsServices/Restrictions/Table'
import Loading from 'components/util/Loading'

import styles from './Restrictions.module.css'

// reverse the order to move the unmatched statuses to the end during sorting
const STATUS_ORDER = ['active', 'waiting', 'deactivated', 'expired'].reverse()

const { Option } = Select

function getResources(restrictions) {
  const resources = []

  restrictions.forEach((restriction) => {
    if (restriction.relayWires && restriction.relayWires !== null) {
      restriction.relayWires.forEach((restrictionWire) => {
        if (restrictionWire.relayWire.name) {
          resources.push(restrictionWire.relayWire.name)
        }
      })
    }
  })

  return resources.sort()
}

function filterRestrictionsForService(restrictionData, service) {
  return restrictionData.filter((item) => item.serviceId === service.id)
}

export function sortRestrictions(restrictionData) {
  return orderBy(
    restrictionData,
    [(item) => STATUS_ORDER.indexOf(item.status.toLowerCase()), 'startAt'],
    ['desc', 'desc'],
  )
}

function Restrictions({ service }) {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const getDefaultResourceFilterValue = useCallback(() => {
    return String(intl.formatMessage({ id: 'app.resource-owner.restrictions.select-resource' }))
  }, [intl])

  const [isLoading, setIsLoading] = useState(true)
  const [filterDate, setFilterDate] = useState('')
  const [filterResource, setFilterResource] = useState(getDefaultResourceFilterValue())
  const [regulators, setRegulators] = useState([])
  const [restrictions, setRestrictions] = useState([])
  const [sections, setSections] = useState([])
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showEditorModal, setShowEditorModal] = useState(false)
  const [modalRestriction, setModalRestriction] = useState(null)

  const loadSections = useCallback(async () => {
    try {
      const data = await apiSections.getSections(resourceOwner.id)

      setSections(data)
      setIsLoading(false)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.get-sections' }))
      logExceptionInSentry(error)
    }
  }, [intl, resourceOwner.id])

  const loadRegulators = useCallback(async () => {
    try {
      const data = await apiRegulators.getRegulators(resourceOwner.id)

      setRegulators(data)
      loadSections()
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.get-regulators' }))
      logExceptionInSentry(error)
    }
  }, [intl, loadSections, resourceOwner.id])

  const loadRestrictions = useCallback(
    async (isInitialLoad) => {
      setIsLoading(true)
      try {
        const data = await apiRestrictions.getRestrictions(resourceOwner.id)

        setRestrictions(sortRestrictions(filterRestrictionsForService(data, service)))

        // The initial load needs to fetch also regulators and sections.
        if (isInitialLoad) {
          loadRegulators()
        } else {
          setIsLoading(false)
        }
      } catch (error) {
        message.error(intl.formatMessage({ id: 'app.notification.error.get-restrictions' }))
        logExceptionInSentry(error)
      }
    },
    [intl, service, loadRegulators, resourceOwner.id],
  )

  useEffect(() => {
    moment.tz.setDefault(resourceOwner.timeZone)
    loadRestrictions(true)
  }, [loadRestrictions, resourceOwner.timeZone])

  const filteredRestrictions = useMemo(() => {
    const filteredByDate = filterDate
      ? restrictions.filter((restriction) => {
          let startAtLocal = moment.utc(restriction.startAt).toDate()
          startAtLocal = moment(startAtLocal).format()
          return moment(filterDate).isSame(startAtLocal, 'day') || moment(filterDate).isSame(restriction.endAt, 'day')
        })
      : [...restrictions]

    return filterResource !== getDefaultResourceFilterValue()
      ? filteredByDate.filter((restriction) => {
          if (!restriction.relayWires) return false

          return restriction.relayWires.find((restrictionWire) => {
            return restrictionWire.relayWire.name && restrictionWire.relayWire.name === filterResource
          })
        })
      : [...filteredByDate]
  }, [filterDate, filterResource, getDefaultResourceFilterValue, restrictions])

  function handleAddNewButtonClick() {
    setModalRestriction(null)
    setShowEditorModal(true)
  }

  function handleResetFilterClick() {
    setFilterDate('')
    setFilterResource(getDefaultResourceFilterValue())
  }

  function handleEditButtonClick() {
    setShowDetailsModal(false)
    setShowEditorModal(true)
  }

  async function handleDeleteButtonClick() {
    try {
      await apiRestrictions.deleteRestriction(modalRestriction.resourceOwnerId, modalRestriction.id)
      message.success(intl.formatMessage({ id: 'app.notification.success.delete-restriction' }))
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.delete-restriction' }))
      logExceptionInSentry(error)
    } finally {
      handleHideModals({ needsRefresh: true })
    }
  }

  function handleHideModals({ needsRefresh = false }) {
    setShowEditorModal(false)
    setShowDetailsModal(false)

    if (needsRefresh) {
      loadRestrictions(false)
    }
  }

  function handleRowClick(id) {
    const foundRestriction = restrictions.find((restriction) => restriction.id === id)
    setModalRestriction(foundRestriction)
    setShowDetailsModal(true)
  }

  if (isLoading) {
    return <Loading />
  }

  const resourceOptions = uniqBy([getDefaultResourceFilterValue(), ...getResources(restrictions)])

  return (
    <div>
      <header className={styles.header}>
        <Button type="primary" onClick={handleAddNewButtonClick}>
          <FormattedMessage id="app.resource-owner.restrictions.new-restriction" />
        </Button>
        <div className={styles.filter}>
          <div>
            <DatePicker
              format={(current) => moment(current).format(DATE_FORMAT)}
              style={{ width: '100%' }}
              value={filterDate}
              onChange={setFilterDate}
            />
          </div>
          <div>
            <Select style={{ width: '100%' }} value={filterResource} onChange={setFilterResource}>
              {resourceOptions.map((resource) => (
                <Option key={resource} value={resource}>
                  {resource}
                </Option>
              ))}
            </Select>
          </div>
          <Button block type="default" onClick={handleResetFilterClick}>
            <FormattedMessage id="app.resource-owner.restrictions.reset-filter" />
          </Button>
        </div>
      </header>
      <Table
        resourceOwnerId={resourceOwner.id}
        restrictions={filteredRestrictions}
        timeZone={resourceOwner.timeZone}
        onRowClick={handleRowClick}
        onUpdate={() => loadRestrictions(false)}
      />
      {showDetailsModal && (
        <ModalDetails
          restriction={modalRestriction}
          sections={sections}
          timeZone={resourceOwner.timeZone}
          onCloseClick={handleHideModals}
          onEditClick={handleEditButtonClick}
          onDeleteClick={handleDeleteButtonClick}
        />
      )}
      {showEditorModal && (
        <ModalEditor
          regulators={regulators}
          resourceOwnerId={resourceOwner.id}
          restriction={modalRestriction}
          sections={sections}
          service={service}
          timeZone={resourceOwner.timeZone}
          onHide={() => handleHideModals({ needsRefresh: true })}
        />
      )}
    </div>
  )
}

export default Restrictions
