import momentTimezone from 'moment-timezone'
import React from 'react'
import { FormattedMessage } from 'react-intl'

class formatMonth extends React.Component {
  constructor(timeZone) {
    super()

    this.timeZone = timeZone
  }

  // Timestamp is epoch milliseconds (UTC)
  convertEpochTimestamp(timestamp) {
    return momentTimezone.tz(timestamp, this.timeZone).startOf('month').valueOf()
  }

  convertToUserDateTime(timestamp) {
    const monthInNumber = momentTimezone.tz(timestamp, this.timeZone).format('M')
    const year = momentTimezone.tz(timestamp, this.timeZone).format('YYYY')
    return (
      <span>
        <FormattedMessage id={`app.resource-owner.revenue.month.${monthInNumber}`} />
        {', '}
        {year}
      </span>
    )
  }
}

export default formatMonth
