import { Button, Col, Divider, Modal, Popconfirm, Row } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import ExceptionDates from './ExceptionDates'
import Recurrence from './Recurrence'
import FormattedDateFromUTCToTimezone from 'components/FormattedDateFromUTCToTimezone'
import ModalAnalogWires from 'components/ProductsServices/ModalAnalogWires'
import TimeOfDayDisplay from 'components/TimeOfDayDisplay'
import FormattedPrice from 'components/util/FormattedPrice'
import FormattedStatus from 'components/util/FormattedRestrictionStatus'

import styles from './ModalDetails.module.css'

function ModalDetails({ rule, timeZone, onCloseClick, onEditClick, onDeleteClick }) {
  const {
    activeAt,
    analogWires,
    endAt,
    exceptionDates,
    limitValue,
    recurrence,
    startAt,
    status,
    timeEndAt,
    timeStartAt,
  } = rule

  const canBeEdited = status !== 'expired'
  const canBeDeleted = status !== 'active'

  const restrictionAnalogWires = useMemo(
    () =>
      orderBy(
        analogWires.map(({ analogWire }) => analogWire),
        'name',
        'asc',
      ),
    [analogWires],
  )

  return (
    <Modal
      visible
      closable={false}
      width={900}
      footer={[
        canBeDeleted ? (
          <div key="delete-button" className={styles.deleteContainer}>
            <Popconfirm
              title={<FormattedMessage id="app.resource-owner.products-services.modal.delete-rule-confirmation" />}
              okText={<FormattedMessage id="app.resource-owner.products-services.modal.delete-rule-yes" />}
              cancelText={<FormattedMessage id="app.cancel" />}
              okButtonProps={{ danger: true }}
              onConfirm={onDeleteClick}
            >
              <Button danger icon={<DeleteOutlined />}>
                <FormattedMessage id="app.delete" />
              </Button>
            </Popconfirm>
          </div>
        ) : null,
        canBeEdited ? (
          <Button key="edit-button" type="primary" icon={<EditOutlined />} onClick={onEditClick}>
            <FormattedMessage id="app.edit" />
          </Button>
        ) : null,
        <Button key="close-button" onClick={onCloseClick}>
          <FormattedMessage id="app.close" />
        </Button>,
      ]}
    >
      <div className={styles.timezoneContainer}>
        <strong>
          <FormattedMessage id="app.resource-owner.timezone" />
          {': '}
        </strong>
        {timeZone}
      </div>

      <Row gutter={40}>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.restrictions.start-date" />
          </strong>
          <br />
          <FormattedDateFromUTCToTimezone date={startAt} timezone={timeZone} />
        </Col>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.restrictions.end-date" />
          </strong>
          <br />
          <FormattedDateFromUTCToTimezone date={endAt} timezone={timeZone} />
        </Col>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.restrictions.time-of-day" />
          </strong>
          <br />
          <TimeOfDayDisplay endAt={timeEndAt} startAt={timeStartAt} />
        </Col>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.status" />
          </strong>
          <br />
          <FormattedStatus status={status} activeAt={activeAt} />
        </Col>
        <Col>
          <strong>
            <FormattedMessage id="app.resource-owner.products-services.afrr.modal.limit.price" />
          </strong>
          <br />
          <div>
            <FormattedMessage id="app.resource-owner.products-services.modal.help.text.euro.sign" />
            <FormattedPrice price={limitValue} />
          </div>
        </Col>
      </Row>
      <div className={styles.content}>
        <Recurrence recurrence={recurrence} readOnly />
        <ExceptionDates exceptionDates={exceptionDates} readOnly />
      </div>

      {restrictionAnalogWires && restrictionAnalogWires.length > 0 && (
        <>
          <Divider orientation="left">
            <FormattedMessage id="app.resource-owner.products-services.afrr.rules.selected-resources" />
          </Divider>
          <ModalAnalogWires isEdit={false} wires={restrictionAnalogWires} />
        </>
      )}
    </Modal>
  )
}

export default ModalDetails
