/* eslint-disable class-methods-use-this */
class TreeNode {
  // Period is truncated according to the node's level
  constructor(period, level) {
    this.period = period
    this.capacity = 0
    this.revenue = 0
    this.contractPrice = 0
    this.level = level
    this.children = []
  }

  // Each object in the periodFormattingObjects is for a different level.
  updateTreeNode(periodFormattingObjects, timestamp, capacity, contractPrice, revenue) {
    const childLevel = this.level + 1
    this.addToNodeProperties(capacity, revenue, contractPrice)
    if (this.isNotLeafNode(periodFormattingObjects)) {
      const childTruncatedPeriod = this.truncatePeriod(timestamp, periodFormattingObjects, childLevel)
      if (
        this.hasNoChildren() ||
        this.isNotEqualToLastChildPeriod(childTruncatedPeriod) ||
        this.isLastAncestor(periodFormattingObjects)
      ) {
        this.appendChild(childTruncatedPeriod)
      }
      this.children[this.children.length - 1].updateTreeNode(
        periodFormattingObjects,
        timestamp,
        capacity,
        contractPrice,
        revenue,
      )
    }
  }

  addToNodeProperties(capacity, revenue, contractPrice) {
    this.capacity += this.isValueMissing(capacity) ? 0 : capacity
    this.revenue += this.isValueMissing(revenue) ? 0 : revenue
    this.contractPrice += this.isValueMissing(contractPrice) ? 0 : contractPrice
  }

  isValueMissing(value) {
    return value === -1
  }

  appendChild(period) {
    const childLevel = this.level + 1
    return this.children.push(new TreeNode(period, childLevel))
  }

  truncatePeriod(timestamp, periodFormattingObjects, childrenLevel) {
    return periodFormattingObjects[childrenLevel].convertEpochTimestamp(timestamp)
  }

  isNotLeafNode(periodFormattingObjects) {
    return this.level < periodFormattingObjects.length - 1
  }

  hasNoChildren() {
    return this.children.length - 1 < 0
  }

  isNotEqualToLastChildPeriod(childTruncatedPeriod) {
    return this.children[this.children.length - 1].period !== childTruncatedPeriod
  }

  // Last ancestors are the tree nodes that have leaves as children
  isLastAncestor(periodFormattingObjects) {
    return this.level === periodFormattingObjects.length - 2
  }
}

export default TreeNode
