import { Amplify } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useEnvironment } from 'contexts/environment-context'

import FullPageSpinner from './util/FullPageSpinner'

interface AmplifySetupWrapperProps {
  children: React.ReactNode
}

function AmplifySetupWrapper({ children }: AmplifySetupWrapperProps) {
  const { cognitoWebClientId, cognitoUserPoolId } = useEnvironment()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (cognitoUserPoolId && cognitoWebClientId) {
      const awsConfig = {
        aws_user_pools_id: cognitoUserPoolId,
        aws_user_pools_web_client_id: cognitoWebClientId,
      }
      Amplify.configure(awsConfig)

      setLoading(false)
    }
  }, [cognitoWebClientId, cognitoUserPoolId, setLoading])

  return loading ? <FullPageSpinner /> : <>{children}</>
}

export default AmplifySetupWrapper
