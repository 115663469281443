import { Button, Col, Divider, Form, message, Modal, Row } from 'antd'
import { differenceBy, orderBy } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiServiceRules from 'api/service-rules'
import { getUsedAnalogWiresForService } from 'util/services'
import { logExceptionInSentry } from 'util/error-message'
import {
  convertFromUTCToMomentDateUsingTimezone,
  convertToUtcUsingTimezone,
  getMomentDateForStartOfFutureDaysUsingTimezone,
} from 'util/time-utils'

import ExceptionDates from './ExceptionDates'
import Recurrence from './Recurrence'
import ModalAnalogWires from 'components/ProductsServices/ModalAnalogWires'
import CustomDatePicker from 'components/CustomDatePicker'
import TimeOfDaySelect from 'components/TimeOfDaySelect'
import FormattedPrice from 'components/util/FormattedPrice'

import styles from './ModalEditor.module.css'

function ModalEditor({ analogControlPoints, resourceOwnerId, rule, service, timeZone, onHide }) {
  const intl = useIntl()

  const [endAt, setEndAt] = useState(null)
  const [startAt, setStartAt] = useState(null)
  const [timeEndAt, setTimeEndAt] = useState(null)
  const [timeStartAt, setTimeStartAt] = useState(null)
  const [limitValue, setLimitValue] = useState(null)
  const [recurrence, setRecurrence] = useState(null)
  const [exceptionDates, setExceptionDates] = useState([])
  const [selectedAnalogWires, setSelectedAnalogWires] = useState([])
  const allAnalogWires = useMemo(
    () => orderBy(getUsedAnalogWiresForService(analogControlPoints, service.id), 'name', 'asc'),
    [analogControlPoints, service.id],
  )

  function isNew() {
    return rule == null
  }

  useEffect(() => {
    if (isNew()) {
      setStartAt(getMomentDateForStartOfFutureDaysUsingTimezone(1, timeZone))
      setEndAt(getMomentDateForStartOfFutureDaysUsingTimezone(2, timeZone))
      setTimeStartAt('00:00')
      setTimeEndAt('23:59')
      setLimitValue(service.contractPrice)
      setRecurrence('FREQ=DAILY')
    } else {
      setStartAt(convertFromUTCToMomentDateUsingTimezone(rule.startAt, timeZone))
      setEndAt(convertFromUTCToMomentDateUsingTimezone(rule.endAt, timeZone))
      setTimeStartAt(rule.timeStartAt)
      setTimeEndAt(rule.timeEndAt)
      setLimitValue(rule.limitValue)
      setRecurrence(rule.recurrence)
      setExceptionDates(rule.exceptionDates != null ? [...rule.exceptionDates] : [])
      setSelectedAnalogWires([...rule.analogWires])
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function addWires(wiresToBeAdded, resourceOwnerId, serviceId, serviceRuleId) {
    const promises = wiresToBeAdded.map((serviceRuleAnalogWire) => {
      return apiServiceRules.addServiceRuleAnalogWire(resourceOwnerId, serviceId, serviceRuleId, serviceRuleAnalogWire)
    })
    try {
      await Promise.all(promises)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.add.resource' }))
      logExceptionInSentry(error)
    }
  }

  async function removeWires(wiresToBeRemoved, resourceOwnerId, serviceId, serviceRuleId) {
    const promises = wiresToBeRemoved.map((serviceRuleAnalogWire) => {
      return apiServiceRules.deleteServiceRuleAnalogWire(
        resourceOwnerId,
        serviceId,
        serviceRuleId,
        serviceRuleAnalogWire.analogWireId,
      )
    })
    try {
      await Promise.all(promises)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.delete-rule-resource' }))
      logExceptionInSentry(error)
    }
  }

  function handleEndDateChange(date) {
    setEndAt(date)
  }

  function handleStartDateChange(date) {
    setStartAt(date)
  }

  function handleTimeEndAtChange(date) {
    setTimeEndAt(date)
  }

  function handleTimeStartAtChange(date) {
    setTimeStartAt(date)
  }

  function handleRecurrenceChange(value) {
    setRecurrence(value)
  }

  function handleExceptionDatesChange(value) {
    setExceptionDates(value)
  }

  function validateFields() {
    if (moment(endAt).isBefore(startAt)) {
      message.error(intl.formatMessage({ id: 'app.resource-owner.restrictions.end-date-before-start-date-error' }))
      return false
    }
    if (timeEndAt === timeStartAt) {
      message.error(intl.formatMessage({ id: 'app.resource-owner.restrictions.end-time-equals-start-time-error' }))
      return false
    }
    if (moment(timeEndAt, 'HH:mm').isBefore(moment(timeStartAt, 'HH:mm'))) {
      message.error(intl.formatMessage({ id: 'app.resource-owner.restrictions.end-time-before-start-time-error' }))
      return false
    }
    const minTimeBlockEnd = moment(timeStartAt, 'HH:mm').add(59, 'minutes')
    if (moment(timeEndAt, 'HH:mm').isBefore(minTimeBlockEnd)) {
      message.error(
        intl.formatMessage({
          id: 'app.resource-owner.restrictions.availability-at-least-one-hour-error',
        }),
      )
      return false
    }
    return true
  }

  // return array with added analog wires
  function getAddedAnalogWires(ruleAnalogWires) {
    return differenceBy(selectedAnalogWires, ruleAnalogWires, 'analogWireId')
  }

  // return array with removed analog wires
  function getRemovedAnalogWires(ruleAnalogWires) {
    return differenceBy(ruleAnalogWires, selectedAnalogWires, 'analogWireId')
  }

  // Rule was saved, now check if any analog wires need to be added/removed
  async function handleServiceRuleSaved(savedServiceRule) {
    const { id, analogWires, serviceId } = savedServiceRule

    // Add wires if any were checked from the list
    const wiresToBeAdded = getAddedAnalogWires(analogWires)
    if (wiresToBeAdded.length > 0) {
      await addWires(wiresToBeAdded, resourceOwnerId, serviceId, id)
    }

    // Remove wires if any were unchecked from the list
    const wiresToBeRemoved = getRemovedAnalogWires(analogWires)
    if (wiresToBeRemoved.length > 0) {
      await removeWires(wiresToBeRemoved, resourceOwnerId, serviceId, id)
    }

    onHide({ needsRefresh: true })
  }

  async function handleSaveRule() {
    const isValid = validateFields()

    if (isValid) {
      let newRule = {
        startAt: convertToUtcUsingTimezone(startAt, timeZone),
        endAt: convertToUtcUsingTimezone(endAt, timeZone),
        timeStartAt,
        timeEndAt,
        serviceId: service.id,
        recurrence,
        exceptionDates,
        limitValue,
      }
      if (!isNew()) {
        newRule = {
          ...newRule,
          id: rule.id,
        }
      }
      try {
        const savedServiceRule = await apiServiceRules.save(resourceOwnerId, service.id, newRule)
        message.success(intl.formatMessage({ id: 'app.notification.success.save-rule' }))

        // Now that the service rule was saved, call the handler to add/delete wires
        handleServiceRuleSaved(savedServiceRule)
      } catch (error) {
        message.error(intl.formatMessage({ id: 'app.notification.error.save-rule' }))
        logExceptionInSentry(error)
      }
    }
  }

  return (
    <Modal
      visible
      closable={false}
      width={900}
      footer={[
        <Button key="save-button" type="primary" onClick={handleSaveRule}>
          <FormattedMessage id="app.save" />
        </Button>,
        <Button key="cancel-button" onClick={onHide}>
          <FormattedMessage id="app.cancel" />
        </Button>,
      ]}
    >
      <div className={styles.timezoneContainer}>
        <strong>
          <FormattedMessage id="app.resource-owner.timezone" />
        </strong>
        : {timeZone}
      </div>

      <Form layout="vertical">
        <Row gutter={40}>
          <Col>
            <CustomDatePicker
              label="app.resource-owner.restrictions.start-date"
              value={startAt}
              timeZone={timeZone}
              onChange={handleStartDateChange}
            />
          </Col>
          <Col>
            <CustomDatePicker
              label="app.resource-owner.restrictions.end-date"
              value={endAt}
              timeZone={timeZone}
              onChange={handleEndDateChange}
            />
          </Col>
          <Col>
            <TimeOfDaySelect
              isInclusive={true}
              ptuRange={15}
              timeStartAt={timeStartAt}
              timeEndAt={timeEndAt}
              onTimeStartAtChange={handleTimeStartAtChange}
              onTimeEndAtChange={handleTimeEndAtChange}
            />
          </Col>
          <Col>
            <strong>
              <FormattedMessage id="app.resource-owner.products-services.afrr.modal.limit.price" />
            </strong>
            <div className={styles.price}>
              <FormattedMessage id="app.resource-owner.products-services.modal.help.text.euro.sign" />
              <FormattedPrice price={limitValue} />
            </div>
          </Col>
        </Row>
      </Form>

      <Recurrence recurrence={recurrence} onRecurrenceChange={handleRecurrenceChange} />
      <ExceptionDates exceptionDates={exceptionDates} onExceptionDatesChange={handleExceptionDatesChange} />

      <Divider orientation="left">
        <FormattedMessage id="app.resource-owner.products-services.afrr.rules.select-resources" />
      </Divider>
      <ModalAnalogWires
        isEdit={true}
        selectedAnalogWires={selectedAnalogWires}
        wires={allAnalogWires}
        onChange={setSelectedAnalogWires}
      />
    </Modal>
  )
}

export default ModalEditor
