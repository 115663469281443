import momentTimezone from 'moment-timezone'
import React from 'react'

class formatHalfYear extends React.Component {
  constructor(timeZone) {
    super()

    this.timeZone = timeZone
  }

  // Timestamp is epoch milliseconds (UTC)
  convertEpochTimestamp(timestamp) {
    const startOfSecondHalfYear = this.getStartOfSecondHalfYear(timestamp)
    if (timestamp < startOfSecondHalfYear) {
      return momentTimezone.tz(timestamp, this.timeZone).startOf('year').valueOf()
    }
    return startOfSecondHalfYear
  }

  convertToUserDateTime(timestamp) {
    const yearFormat = momentTimezone.tz(timestamp, this.timeZone).format('YYYY')
    const halfYear = momentTimezone.tz(timestamp, this.timeZone).format('M') < 7 ? `${yearFormat}-1` : `${yearFormat}-2`
    return <span>{halfYear}</span>
  }

  /* Internal, private methods */
  getStartOfSecondHalfYear(timestamp) {
    return momentTimezone.tz(timestamp, this.timeZone).month(6).startOf('month').valueOf()
  }
}

export default formatHalfYear
