import { FormattedNumber } from 'react-intl'

interface FormattedPowerProps {
  power: number
}

export default function FormattedPower({ power }: FormattedPowerProps) {
  const kwConsumption = power / 1000

  return (
    <span>
      <FormattedNumber value={kwConsumption} maximumFractionDigits={0} />
      {' kW'}
    </span>
  )
}
