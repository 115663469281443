import { Col, Grid, Layout, Menu, Row } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink, useLocation } from 'react-router-dom'

import { useEnvironment } from 'contexts/environment-context'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { currentTheme } from 'theme/theme-loader'

import Account from 'components/Navbar/Account'
import Settings from 'components/Settings'

import styles from './Navbar.module.css'

const { useBreakpoint } = Grid
const { Header, Sider } = Layout

type NavbarProps = {
  onLogoutClick: () => void
}

function Navbar({ onLogoutClick }: NavbarProps) {
  const screens = useBreakpoint()
  const { resourceOwner } = useResourceOwner()

  const [showSettings, setShowSettings] = useState(false)

  const handleShowSettingsPopup = useCallback((visible) => {
    setShowSettings(visible)
  }, [])

  useEffect(() => {
    if (resourceOwner && resourceOwner.isIbanNeeded && (!resourceOwner.iban || !resourceOwner.ibanHolder)) {
      setShowSettings(true)
    }
  }, [resourceOwner])

  return screens.lg ? (
    <Header className={styles.header}>
      <Row>
        <Col flex="190px">
          <Logo />
        </Col>
        <Col flex="auto">
          <MainNavigation />
        </Col>
        <Col className={styles.accountContainer} flex="190px">
          <Account handleShowSettingsPopup={handleShowSettingsPopup} onLogoutClick={onLogoutClick} />
        </Col>
        {showSettings && <Settings onHide={() => handleShowSettingsPopup(false)} />}
      </Row>
    </Header>
  ) : (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      className={styles.header}
      defaultCollapsed={false}
      zeroWidthTriggerStyle={{
        backgroundColor: 'var(--secondary-color)',
        color: 'white',
        height: '36px',
        lineHeight: '36px',
        top: 0,
      }}
    >
      <Logo />
      <MainNavigation />
      <Account handleShowSettingsPopup={handleShowSettingsPopup} onLogoutClick={onLogoutClick} />
      {showSettings && <Settings onHide={() => handleShowSettingsPopup(false)} />}
    </Sider>
  )
}

function Logo() {
  return (
    <NavLink className={styles.logo} to="/dashboard">
      <img src={currentTheme.logo.whiteLogoPath} alt={currentTheme.logo.logoAltText} />
    </NavLink>
  )
}

function MainNavigation() {
  const screens = useBreakpoint()
  const location = useLocation()
  const { resourceOwner } = useResourceOwner()
  const { countryCode } = useEnvironment()
  const shouldHaveProductsServicesNavbar = useMemo(() => countryCode !== 'es', [countryCode])

  return (
    <Menu
      className={styles.menu}
      mode={screens.lg ? 'horizontal' : 'inline'}
      selectedKeys={[location.pathname]}
      theme="dark"
    >
      <Menu.Item key="/dashboard">
        <NavLink to="/dashboard">
          <FormattedMessage id={'app.resource-owner.navbar.dashboard'} />
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/activation-log">
        <NavLink to="/activation-log">
          <FormattedMessage id={'app.resource-owner.navbar.activationlog'} />
        </NavLink>
      </Menu.Item>
      {shouldHaveProductsServicesNavbar && (
        <Menu.Item key="/products-services">
          <NavLink to="/products-services">
            <FormattedMessage id={'app.resource-owner.navbar.products-services'} />
          </NavLink>
        </Menu.Item>
      )}
      {resourceOwner?.isFinancialVisible && (
        <Menu.Item key="/revenues">
          <NavLink to="/revenues">
            <FormattedMessage id={'app.resource-owner.navbar.revenues'} />
          </NavLink>
        </Menu.Item>
      )}
    </Menu>
  )
}

export default Navbar
