import { Button, Divider, Form, message, Modal, Radio } from 'antd'
import { differenceBy, orderBy, parseInt } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as apiRestrictions from 'api/restrictions'
import { RestrictionType } from 'constants/restrictions'
import { useEnvironment } from 'contexts/environment-context'
import { useFeatureToggle } from 'contexts/feature-toggle-context'
import { logExceptionInSentry } from 'util/error-message'
import { getUsedRelayWiresForServices } from 'util/services'
import {
  convertFromUTCToMomentDateUsingTimezone,
  convertToUtcUsingTimezone,
  getMomentDateForStartOfFutureDaysUsingTimezone,
} from 'util/time-utils'

import ModalRelayWires from 'components/ProductsServices/ModalRelayWires'
import DoesNotRepeatForm from 'components/ProductsServices/Restrictions/DoesNotRepeatForm'
import RepeatDailyForm from 'components/ProductsServices/Restrictions/RepeatDailyForm'

function ModalEditor({ regulators, resourceOwnerId, restriction, sections, service, timeZone, onHide }) {
  const intl = useIntl()
  const { countryCode } = useEnvironment()
  const { isEnabled } = useFeatureToggle()

  const [repeatingType, setRepeatingType] = useState(initRepeatingType())
  const [endAt, setEndAt] = useState('')
  const [isAllDayChecked, setIsAllDayChecked] = useState(false)
  const [startAt, setStartAt] = useState('')
  const [timeEndAt, setTimeEndAt] = useState(isNew() ? '23:59' : restriction.timeEndAt)
  const [timeStartAt, setTimeStartAt] = useState(isNew() ? '00:00' : restriction.timeStartAt)
  const [selectedRelayWires, setSelectedRelayWires] = useState([])
  const allRelayWires = useMemo(
    () => orderBy(getUsedRelayWiresForServices(regulators, service.id), 'name', 'asc'),
    [regulators, service.id],
  )

  function initRepeatingType() {
    if (!isNew()) {
      return restriction.type
    }

    // The repeating-daily option is the default one, but in Greece we don't offer this option. Then, starting the value as "non-repeating"
    if (countryCode === 'gr' && isEnabled('PARTNER_UI_RESTRICTIONS_CHANGE_FREQUENCY_ENABLED')) {
      return RestrictionType.NON_REPEATING
    }

    return RestrictionType.REPEATING_DAILY
  }

  function isNew() {
    return restriction == null
  }

  useEffect(() => {
    if (isNew() && countryCode === 'gr') {
      setStartAt(getMomentDateForStartOfFutureDaysUsingTimezone(0, timeZone))
      setEndAt(getMomentDateForStartOfFutureDaysUsingTimezone(0, timeZone))
    } else if (isNew()) {
      setStartAt(getMomentDateForStartOfFutureDaysUsingTimezone(1, timeZone))
      setEndAt(getMomentDateForStartOfFutureDaysUsingTimezone(2, timeZone))
    } else {
      setStartAt(convertFromUTCToMomentDateUsingTimezone(restriction.startAt, timeZone))
      setEndAt(convertFromUTCToMomentDateUsingTimezone(restriction.endAt, timeZone))
      setSelectedRelayWires([...restriction.relayWires])
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // return array with added relay wires
  function getAddedRelayWires(restrictionRelayWires) {
    return differenceBy(selectedRelayWires, restrictionRelayWires, 'relayWire.id')
  }

  // return array with removed relay wires
  function getRemovedRelayWires(restrictionRelayWires) {
    return differenceBy(restrictionRelayWires, selectedRelayWires, 'relayWire.id')
  }

  async function addWires(wiresToBeAdded, resourceOwnerId, restrictionId) {
    const promises = wiresToBeAdded.map((restrictionRelayWire) => {
      return apiRestrictions.saveRestrictionRelayWire(resourceOwnerId, restrictionId, restrictionRelayWire.relayWire.id)
    })
    try {
      await Promise.all(promises)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.save-restriction-relaywires' }))
      logExceptionInSentry(error)
    }
  }

  async function removeWires(wiresToBeRemoved, resourceOwnerId, restrictionId) {
    const promises = wiresToBeRemoved.map((restrictionRelayWire) => {
      return apiRestrictions.deleteRestrictionRelayWire(
        resourceOwnerId,
        restrictionId,
        restrictionRelayWire.relayWire.id,
      )
    })
    try {
      await Promise.all(promises)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'app.notification.error.delete-restriction-relaywires' }))
      logExceptionInSentry(error)
    }
  }

  // Restriction was saved, now check if any relay wires need to be added/removed
  async function handleRestrictionSaved(savedRestriction) {
    const { id, relayWires } = savedRestriction

    // Add wires if any were checked from the list
    const wiresToBeAdded = getAddedRelayWires(relayWires)
    if (wiresToBeAdded.length > 0) {
      await addWires(wiresToBeAdded, resourceOwnerId, id)
    }

    // Remove wires if any were unchecked from the list
    const wiresToBeRemoved = getRemovedRelayWires(relayWires)
    if (wiresToBeRemoved.length > 0) {
      await removeWires(wiresToBeRemoved, resourceOwnerId, id)
    }

    onHide({ needsRefresh: true })
  }

  function validateFields() {
    if (moment(endAt).isBefore(startAt)) {
      message.error(intl.formatMessage({ id: 'app.resource-owner.restrictions.end-date-before-start-date-error' }))
      return false
    }
    if (parseInt(timeStartAt) > parseInt(timeEndAt)) {
      message.error(intl.formatMessage({ id: 'app.resource-owner.restrictions.end-time-before-start-time-error' }))
      return false
    }
    return true
  }

  async function handleSaveRestriction() {
    const isValid = validateFields()

    if (isValid) {
      let newRestriction = {
        serviceId: service.id,
        startAt: convertToUtcUsingTimezone(startAt, timeZone),
        endAt: convertToUtcUsingTimezone(endAt, timeZone),
        timeStartAt,
        timeEndAt,
        type: repeatingType,
      }
      if (!isNew()) {
        newRestriction = {
          ...newRestriction,
          id: restriction.id,
        }
      }
      try {
        const savedRestriction = await apiRestrictions.save(resourceOwnerId, newRestriction)
        message.success(intl.formatMessage({ id: 'app.notification.success.save-restriction' }))

        // Now that the restriction was saved, call the handler to add/delete wires
        handleRestrictionSaved(savedRestriction)
      } catch (error) {
        message.error(intl.formatMessage({ id: 'app.notification.error.save-restriction' }))
        logExceptionInSentry(error)
      }
    }
  }

  function handleRepeatingTypeChange(event) {
    setRepeatingType(event.target.value)

    // As the radio button value changed, reset the "all day" checkbox to not be checked
    setIsAllDayChecked(false)
  }

  function handleChangeIsAllDayChecked(isChecked) {
    setIsAllDayChecked(isChecked)

    // In case the "all day" checkbox is now checked, reset the values previously selected for "time start" and "time end"
    if (isChecked) {
      setTimeStartAt('00:00')
      setTimeEndAt('23:59')
    }
  }

  return (
    <Modal
      visible
      closable={false}
      width={900}
      footer={[
        <Button key="save-button" type="primary" onClick={handleSaveRestriction}>
          <FormattedMessage id="app.save" />
        </Button>,
        <Button key="cancel-button" onClick={onHide}>
          <FormattedMessage id="app.cancel" />
        </Button>,
      ]}
    >
      <div style={{ marginBottom: '20px' }}>
        <strong>
          <FormattedMessage id="app.resource-owner.timezone" />
        </strong>
        : {timeZone}
      </div>
      <Form layout="vertical">
        {/* In Greece, we don't want to allow users to choose the repeating daily option, so hide the radio group entirely */}
        {countryCode !== 'gr' && isEnabled('PARTNER_UI_RESTRICTIONS_CHANGE_FREQUENCY_ENABLED') && (
          <Radio.Group
            disabled={!isNew()}
            style={{ marginBottom: '20px' }}
            value={repeatingType}
            onChange={handleRepeatingTypeChange}
          >
            <Radio value={RestrictionType.NON_REPEATING}>
              <FormattedMessage id="app.resource-owner.restrictions.frequency.does_not_repeat" />
            </Radio>
            <Radio value={RestrictionType.REPEATING_DAILY}>
              <FormattedMessage id="app.resource-owner.restrictions.frequency.repeat_daily" />
            </Radio>
          </Radio.Group>
        )}
        {repeatingType === RestrictionType.NON_REPEATING ? (
          <DoesNotRepeatForm
            endAt={endAt}
            isAllDayChecked={isAllDayChecked}
            startAt={startAt}
            timeEndAt={timeEndAt}
            timeStartAt={timeStartAt}
            timeZone={timeZone}
            onChangeEndAt={setEndAt}
            onChangeIsAllDayChecked={handleChangeIsAllDayChecked}
            onChangeStartAt={setStartAt}
            onChangeTimeEndAt={setTimeEndAt}
            onChangeTimeStartAt={setTimeStartAt}
          />
        ) : (
          <RepeatDailyForm
            endAt={endAt}
            isAllDayChecked={isAllDayChecked}
            startAt={startAt}
            timeEndAt={timeEndAt}
            timeStartAt={timeStartAt}
            timeZone={timeZone}
            onChangeEndAt={setEndAt}
            onChangeIsAllDayChecked={handleChangeIsAllDayChecked}
            onChangeStartAt={setStartAt}
            onChangeTimeEndAt={setTimeEndAt}
            onChangeTimeStartAt={setTimeStartAt}
          />
        )}
      </Form>
      <Divider orientation="left">
        <FormattedMessage id="app.resource-owner.restrictions.choose-affected-lamp-groups" />
      </Divider>
      <ModalRelayWires
        isEdit={true}
        selectedRelayWires={selectedRelayWires}
        sections={sections}
        wires={allRelayWires}
        onChange={setSelectedRelayWires}
      />
    </Modal>
  )
}

export default ModalEditor
