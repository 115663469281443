import { Checkbox } from 'antd'

type Wire = {
  id: number
}

type SelectedWire =
  | {
      analogWireId: number
      analogWire: Wire
    }
  | {
      relayWireId: number
      relayWire: Wire
    }

type SelectAllWiresCheckboxProps = {
  isEdit: boolean
  selectedWires: SelectedWire[]
  wires: Wire[]
  onChange: () => void
}

function SelectAllWiresCheckbox({ isEdit, selectedWires = [], wires = [], onChange }: SelectAllWiresCheckboxProps) {
  if (!isEdit) return null

  const hasAllWiresSelected = selectedWires.length === wires.length
  const hasWiresPartiallySelected = selectedWires.length > 0 && selectedWires.length < wires.length

  return <Checkbox checked={hasAllWiresSelected} indeterminate={hasWiresPartiallySelected} onChange={onChange} />
}

export default SelectAllWiresCheckbox
