import { Theme } from '../theme'
import WhiteLogoImage from 'assets/logo-white.png'
import ColoredLogoImage from 'assets/logo-blue.png'
import SemiCircle1Image from 'assets/semi-circle-1.png'
import SemiCircle2Image from 'assets/semi-circle-2.png'

const BLUE_MUNSELL = '#1484a0'
const CYBER_YELLOW = '#ffd522'
const PERSIA_GREEN = '#1b998b'
const TERRA_COTTA = '#e1746a'
const INDIGO_DYE = '#204a64'
const WHITE = '#ffffff'

const SympowerTheme: Theme = {
  name: 'Sympower',
  colorPalette: {
    primaryColor: BLUE_MUNSELL,
    secondaryColor: PERSIA_GREEN,
    successColor: PERSIA_GREEN,
    warningColor: CYBER_YELLOW,
    errorColor: TERRA_COTTA,
    backgroundColor: WHITE,
  },
  fontFamily: {
    primaryFontFamilyName: "'Roboto', sans-serif",
    primaryFontFamilyUrl: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
    accentFontFamilyName: "'Quicksand', sans-serif",
    accentFontFamilyUrl: 'https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap',
  },
  logo: {
    logoAltText: 'Sympower Logo',
    whiteLogoPath: WhiteLogoImage,
    coloredLogoPath: ColoredLogoImage,
    faviconPath: process.env.PUBLIC_URL + '/favicon.ico',
  },
  uiConfig: {
    hasWhitelabeledFooter: false,
  },
  dashboardConfig: {
    productName: 'Sympower',
    dashboardLogoPath: WhiteLogoImage,
    textColor: WHITE,
    background: `url(${SemiCircle1Image}) 10% 100% no-repeat,
     url('${SemiCircle2Image}') 90% 0% no-repeat`,
    backgroundOverlayColor: INDIGO_DYE,
  },
}

export { SympowerTheme }
