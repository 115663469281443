import { message, Button, Divider, Modal, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table/Table'
import { orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import * as apiActivations from 'api/activations'
import * as apiResourceOwner from 'api/resource-owner'
import * as apiSections from 'api/sections'
import { DATE_TIME_WITHOUT_SECONDS_FORMAT, TIME_WITHOUT_SECONDS_FORMAT } from 'constants/date'
import { useResourceOwner } from 'contexts/resource-owner-context'
import { logExceptionInSentry } from 'util/error-message'
import { extractServices } from 'util/services'

import Loading from 'components/util/Loading'
import PlanValue from 'components/PlanValue'
import FormattedPower from 'components/FormattedPower'

import styles from './activation-log.module.css'

function getFormattedDate(date: string) {
  const localTime = moment.utc(date).toDate()
  return moment(localTime).format()
}

function getDuration(startedAt: string, endedAt?: string | null) {
  if (!endedAt) {
    return null
  }

  return moment.utc(moment(endedAt)).diff(moment(startedAt))
}

function getWireTotalPowerConsumption(wire: { powerMeters: { verifiedCapacity: number }[] | null }) {
  if (wire.powerMeters === null) return 0

  return wire.powerMeters.reduce((sum, powerMeter) => sum + powerMeter.verifiedCapacity, 0)
}

function genericSorter(data: any) {
  return (a: any, b: any) => {
    if (a[data] === b[data]) {
      return 0
    } else {
      return a[data] > b[data] ? 1 : -1
    }
  }
}

export default function ActivationLog() {
  const intl = useIntl()
  const { resourceOwner } = useResourceOwner()

  const { id: resourceOwnerId } = resourceOwner!

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activations, setActivations] = useState<any>(null)
  const [selectedActivationId, setSelectedActivationId] = useState<number | null>(null)
  const [activationRelayWires, setActivationRelayWires] = useState<any>(null)
  const [services, setServices] = useState<any>(null)
  const [sections, setSections] = useState<any>(null)
  const [loadedActivations, setLoadedActivations] = useState(false)
  const [loadedServices, setLoadedServices] = useState(false)
  const [loadedSections, setLoadedSections] = useState(false)

  const handleHotkey = useCallback((event: KeyboardEvent) => {
    event.stopPropagation()

    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsModalVisible(false)
    }
  }, [])

  const isMfrrda = useCallback(
    (serviceId: number) => {
      const service = services.find((s: any) => s.id === serviceId)
      return service.isReserve && service.reserve === 'mfrrda'
    },
    [services],
  )

  const handleRowClick = useCallback(
    async (id: number) => {
      setSelectedActivationId(id)

      const { serviceId } = activations.find((activation: any) => activation.id === id)

      // disable activation details modal for mFRRda
      if (isMfrrda(serviceId)) {
        return
      }

      try {
        const data = await apiActivations.getServiceActivationRelayWires(resourceOwnerId, serviceId, id)

        setActivationRelayWires(data)
        setIsModalVisible(true)
      } catch (error: any) {
        message.error(intl.formatMessage({ id: 'app.notification.error.get-activation-events' }))
        logExceptionInSentry(error)
      }
    },
    [activations, isMfrrda, resourceOwnerId, intl],
  )

  const tableData = useMemo(
    () => ({
      className: 'cursor-pointer',
      dataSource:
        !activations || !services
          ? []
          : activations.map((activation: any) => {
              const startedAt = getFormattedDate(activation.startedAt)
              const endedAt = activation.endedAt != null ? getFormattedDate(activation.endedAt) : null
              const duration = moment
                .duration(getDuration(startedAt, endedAt))
                .locale(resourceOwner!.uiLanguage)
                .humanize()
              const service = services.find((service: any) => service.id === activation.serviceId)?.type
              return { key: `${activation.id}`, id: activation.id, startedAt, endedAt, duration, service }
            }),
      columns: [
        {
          title: intl.formatMessage({ id: 'app.resource-owner.activation-event-log.event-started' }),
          dataIndex: 'startedAt',
          render: (text: any) => moment(text).format(DATE_TIME_WITHOUT_SECONDS_FORMAT),
        },
        {
          title: intl.formatMessage({ id: 'app.resource-owner.activation-event-log.event-ended' }),
          dataIndex: 'endedAt',
          render: (text: any) => (!text ? null : moment(text).format(DATE_TIME_WITHOUT_SECONDS_FORMAT)),
        },
        {
          title: intl.formatMessage({ id: 'app.resource-owner.activation-event-log.event-duration' }),
          dataIndex: 'duration',
        },
        {
          title: intl.formatMessage({ id: 'app.resource-owner.activation-event-log.service' }),
          dataIndex: 'service',
          filters:
            !services || !activations
              ? []
              : uniqBy(
                  services.filter(
                    (service: any) => !!activations.find((activation: any) => activation.serviceId === service.id),
                  ),
                  'type',
                ).map((service: any) => ({
                  text: intl.formatMessage({ id: `app.resource-owner.products-services.navbar.${service.type}` }),
                  value: service.type,
                })),
          onFilter: (value: any, record: any) => record.service === value,
          render: (text: any) => intl.formatMessage({ id: `app.resource-owner.products-services.navbar.${text}` }),
        },
      ],
      onRow: (record: any) => ({
        onClick: () => handleRowClick(record.id),
      }),
    }),
    [activations, handleRowClick, intl, resourceOwner, services],
  )

  const detailsData = useMemo(() => {
    if (!selectedActivationId || !activations || !services) {
      return {}
    }

    const activation = activations.find((a: any) => a.id === selectedActivationId)
    const service = services.find((s: any) => s.id === activation.serviceId)
    const startedAt = getFormattedDate(activation.startedAt)
    const endedAt = activation.endedAt != null ? getFormattedDate(activation.endedAt) : null
    const duration = moment.duration(getDuration(startedAt, endedAt)).locale(resourceOwner!.uiLanguage).humanize()
    const serviceType = service.type
    const isReserve = service.isReserve
    const limitValue = activation.limitValue
    const actualValue = activation.actualValue

    const datasource: [] = !activationRelayWires
      ? []
      : activationRelayWires
          .filter((wire: any) => !!wire.relayWire)
          .map((wire: any) => {
            const section = sections.find((s: any) => s.id === wire.relayWire.sectionId)
            const shutDownAt = getFormattedDate(wire.turnedOffAt)
            const turnedOnAt = getFormattedDate(wire.turnedOnAt)
            const shutDownDuration = moment
              .duration(getDuration(shutDownAt, turnedOnAt))
              .locale(resourceOwner!.uiLanguage)
              .humanize()

            return {
              id: wire.relayWireId,
              name: wire.relayWire.name || '',
              section: section?.name || '',
              power: getWireTotalPowerConsumption(wire.relayWire),
              shutDownAt,
              turnedOnAt,
              shutDownDuration,
            }
          })

    const columns: ColumnsType<any> = [
      {
        title: intl.formatMessage({ id: 'app.resource-owner.modal.group-name' }),
        dataIndex: 'name',
        sorter: genericSorter('name'),
      },
      {
        title: intl.formatMessage({ id: 'app.resource-owner.modal.section' }),
        dataIndex: 'section',
      },
      {
        title: intl.formatMessage({ id: 'app.resource-owner.modal.power' }),
        dataIndex: 'power',
        align: 'right',
        render: (value: any) => <FormattedPower power={value} />,
        sorter: genericSorter('power'),
      },
      {
        title: intl.formatMessage({ id: 'app.resource-owner.activation-event-log.modal.activated-at' }),
        dataIndex: 'shutDownAt',
        render: (value: any) => (value ? moment(value).format(TIME_WITHOUT_SECONDS_FORMAT) : null),
        sorter: genericSorter('shutDownAt'),
      },
      {
        title: intl.formatMessage({ id: 'app.resource-owner.activation-event-log.modal.deactivated-at' }),
        dataIndex: 'turnedOnAt',
        render: (value: any) => (value ? moment(value).format(TIME_WITHOUT_SECONDS_FORMAT) : null),
        sorter: genericSorter('turnedOnAt'),
      },
      {
        title: intl.formatMessage({ id: 'app.resource-owner.activation-event-log.modal.activation-duration' }),
        dataIndex: 'shutDownDuration',
        sorter: (a: any, b: any) => {
          const durationA = moment(a.shutDownAt).diff(a.turnedOnAt)
          const durationB = moment(b.shutDownAt).diff(b.turnedOnAt)
          return durationA > durationB ? 1 : -1
        },
      },
    ]

    return {
      startedAt,
      endedAt,
      duration,
      serviceType,
      isReserve,
      limitValue,
      actualValue,
      datasource,
      columns,
    }
  }, [activations, activationRelayWires, intl, resourceOwner, sections, selectedActivationId, services])

  useEffect(() => {
    window.addEventListener('keyup', handleHotkey)

    return () => {
      window.removeEventListener('keyup', handleHotkey)
    }
  }, [handleHotkey])

  useEffect(() => {
    async function loadActivations() {
      try {
        const data = await apiActivations.getAllActivations(resourceOwnerId)

        setActivations(orderBy(data, ['startedAt'], ['desc']))
        setLoadedActivations(true)
      } catch (error: any) {
        message.error(intl.formatMessage({ id: 'app.notification.error.get-activation-events' }))
        logExceptionInSentry(error)
      }
    }

    async function loadServices() {
      try {
        const data = await apiResourceOwner.getServices(resourceOwnerId)

        setServices(extractServices(data))
        setLoadedServices(true)
      } catch (error: any) {
        message.error(intl.formatMessage({ id: 'app.notification.error.get-services' }))
        logExceptionInSentry(error)
      }
    }

    async function loadSections() {
      try {
        const data = await apiSections.getSections(resourceOwnerId)

        setSections(data)
        setLoadedSections(true)
      } catch (error: any) {
        message.error(intl.formatMessage({ id: 'app.notification.error.get-sections' }))
        logExceptionInSentry(error)
      }
    }

    loadActivations()
    loadServices()
    loadSections()
  }, [resourceOwnerId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!loadedActivations || !loadedServices || !loadedSections) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    )
  }

  return (
    <div style={{ padding: '20px' }}>
      <Table {...tableData} />

      <Modal
        visible={isModalVisible}
        closable={false}
        width={900}
        footer={[
          <Button key="ok-button" type="primary" onClick={() => setIsModalVisible(false)}>
            <FormattedMessage id="app.ok" />
          </Button>,
        ]}
      >
        <Space size="large">
          <div>
            <strong>
              <FormattedMessage id="app.resource-owner.activation-event-log.event-started" />
            </strong>
            <p>{moment(detailsData.startedAt).format(DATE_TIME_WITHOUT_SECONDS_FORMAT)}</p>
          </div>
          <div>
            <strong>
              <FormattedMessage id="app.resource-owner.activation-event-log.event-ended" />
            </strong>
            {detailsData.endedAt && <p>{moment(detailsData.endedAt).format(DATE_TIME_WITHOUT_SECONDS_FORMAT)}</p>}
          </div>
          <div>
            <strong>
              <FormattedMessage id="app.resource-owner.activation-event-log.event-duration" />
            </strong>
            <p>{detailsData.duration}</p>
          </div>
          <div>
            <strong>
              <FormattedMessage id="app.resource-owner.activation-event-log.service" />
            </strong>
            <p>
              <FormattedMessage id={`app.resource-owner.products-services.navbar.${detailsData.serviceType}`} />
            </p>
          </div>
          {!detailsData.isReserve && (
            <>
              <div>
                <strong>
                  <FormattedMessage
                    id={
                      detailsData.serviceType === 'max-price'
                        ? 'app.resource-owner.products-services.limit-price'
                        : 'app.resource-owner.products-services.limit-consumption'
                    }
                  />
                </strong>
                <PlanValue value={detailsData.limitValue} serviceType={detailsData.serviceType} />
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id={
                      detailsData.serviceType === 'max-price'
                        ? 'app.resource-owner.products-services.actual-price'
                        : 'app.resource-owner.products-services.actual-consumption'
                    }
                  />
                </strong>
                <PlanValue value={detailsData.actualValue} serviceType={detailsData.serviceType} />
              </div>
            </>
          )}
        </Space>

        <Divider orientation="left">
          <FormattedMessage id="app.resource-owner.modal.affected-resources" />
        </Divider>

        <Table rowKey="id" dataSource={detailsData.datasource} columns={detailsData.columns} />
      </Modal>
    </div>
  )
}
