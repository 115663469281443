import { useCallback, useMemo, useState } from 'react'

import { useEnvironment } from 'contexts/environment-context'

import PivotColumn from 'components/Revenues/PivotColumn'

// When creating the tree, capacity is summed up.
// So, in order to find average we divide with the number of node's children.
function findAverageCapacity(treeNode) {
  const { capacity, children } = treeNode

  return children.length === 0 ? capacity : capacity / children.length
}

function TreeNodeComponent({ expandingHalfYears, maxLevel, periodFormattingObjects, treeNode }) {
  const { countryCode } = useEnvironment()

  const [visibleChildren, setVisibleChildren] = useState(isExpandingHalfYear())

  function isExpandingHalfYear() {
    const secondLevel = 1

    return expandingHalfYears.indexOf(treeNode.period) >= 0 && treeNode.level < secondLevel
  }

  function toggle() {
    setVisibleChildren((prev) => !prev)
  }

  const getPeriodInfo = useCallback(() => {
    return periodFormattingObjects[treeNode.level].convertToUserDateTime(treeNode.period)
  }, [periodFormattingObjects, treeNode.level, treeNode.period])

  const getCapacityInfo = useCallback(() => {
    const minimumMWatts = 0.01
    const capacity = {
      prefix: null,
      value: null,
      maximumFractionDigits: 2,
    }

    if (countryCode === 'nl') {
      const averageCapacity = findAverageCapacity(treeNode)
      if (Math.abs(averageCapacity) < minimumMWatts && averageCapacity !== 0) {
        capacity.prefix = '< '
        capacity.value = minimumMWatts
      } else {
        capacity.value = averageCapacity
        capacity.suffix = 'MW'
      }
    }

    if (Math.abs(treeNode.capacity) < minimumMWatts && treeNode.capacity !== 0) {
      capacity.prefix = '< '
      capacity.value = minimumMWatts
    } else {
      capacity.value = treeNode.capacity
    }

    return capacity
  }, [countryCode, treeNode])

  const getSoldCapacityInfo = useCallback(() => {
    return {
      value: treeNode.soldCapacity,
      maximumFractionDigits: 2,
    }
  }, [treeNode.soldCapacity])

  const getContractPriceInfo = useCallback(() => {
    if (countryCode === 'nl') {
      return {
        value: treeNode.contractPrice,
        maximumFractionDigits: 2,
        suffix: '€/MW',
      }
    }

    return {
      value: treeNode.contractPrice,
      maximumFractionDigits: 2,
    }
  }, [countryCode, treeNode.contractPrice])

  const getRevenueInfo = useCallback(() => {
    if (countryCode === 'nl') {
      return {
        value: treeNode.revenue,
        maximumFractionDigits: 2,
        prefix: '€',
      }
    }

    return {
      value: treeNode.revenue,
      maximumFractionDigits: 2,
      suffix: '€',
    }
  }, [countryCode, treeNode.revenue])

  const getColumns = useCallback(
    (countryCode) => {
      if (countryCode === 'nl') {
        return [getPeriodInfo(), getCapacityInfo(), getContractPriceInfo(), getRevenueInfo()]
      }

      return [getPeriodInfo(), getCapacityInfo(), getSoldCapacityInfo(), getRevenueInfo()]
    },
    [getCapacityInfo, getContractPriceInfo, getPeriodInfo, getRevenueInfo, getSoldCapacityInfo],
  )

  const columnInfo = useMemo(() => getColumns(countryCode), [countryCode, getColumns])

  function makeChildNodes() {
    if (treeNode.children != null && treeNode.children.length > 0) {
      const childNodes = []
      for (let index = 0; index < treeNode.children.length; index += 1) {
        const node = treeNode.children[index]

        childNodes.push(
          <li key={index}>
            <TreeNodeComponent
              treeNode={node}
              expandingHalfYears={expandingHalfYears}
              periodFormattingObjects={periodFormattingObjects}
              maxLevel={maxLevel}
            />
          </li>,
        )
      }
      return childNodes
    }

    return null
  }

  const containerClassName = `revenue-pivot-body-row-container-level-${treeNode.level}`
  const childWrapperClassName = `revenue-pivot-child-wrapper-level-${treeNode.level}`

  if (!columnInfo) {
    return null
  }

  return (
    <div>
      <div className={containerClassName} onClick={toggle}>
        <PivotColumn
          columnInfo={columnInfo}
          level={treeNode.level}
          maxLevel={maxLevel}
          visibleChildren={visibleChildren}
        />
      </div>
      {visibleChildren && <ul className={childWrapperClassName}>{makeChildNodes()}</ul>}
    </div>
  )
}

export default TreeNodeComponent
